import React from 'react';
import Modal from 'react-modal';

export default class UpdateCategoryAttributeRequiredModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isCompleted: true,

      tableId: this.props.tableId,
      listed: this.props.categoryAttributeListed,
      required: this.props.categoryAttributeRequired
    };
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps) {
    if (prevProps.tableId !== this.props.tableId) {
     this.setState({tableId: this.props.tableId})
    }
    if (prevProps.categoryAttributeListed !== this.props.categoryAttributeListed || prevProps.categoryAttributeRequired !== this.props.categoryAttributeRequired) {
      this.setState({listed: this.props.categoryAttributeListed, required: this.props.categoryAttributeRequired})
     }
  }

  listedOnChange = (event) => {
    this.setState({listed: event.target.checked ? 1 : 0});
  }

  requiredOnChange = (event) => {
    this.setState({required: event.target.checked ? 1 : 0});
  }

  saveOnClick = () => {
    this.props.saveOnClick(this.state.listed, this.state.required);
  }

  handleKeyPress = (event) => {
    if(event.key === 'Enter') {
      this.saveOnClick();
    }
  }

  render() {
    return (
      <div className="modal inmodal">
        <div className="modal-dialog">
          <div className="modal-content animated fadeIn">
          <Modal
            isOpen={this.props.isOpen}
            onRequestClose={this.props.onRequestClose}
            style={{
              content : {
                position : 'null',
                top : 'null',
                left : 'null',
                right : 'null',
                bottom : 'null',
                border : 'none',
                background : '#fff',
                padding : 'null',
                maxWidth : '50%',
                minWidth : '350px'
              },
              overlay : {
                position : 'fixed',
                top : 0,
                left : 0,
                right : 0,
                bottom : 0,
                display : 'flex',
                alignItems : 'center',
                justifyContent : 'center',
                backgroundColor : 'rgba(0,0,0,00.1)',
                zIndex:2500
              }
            }}
          >
            <div className={this.state.isCompleted ? "ibox-content no-padding" : "ibox-content sk-loading no-padding"}>
              <div className="sk-spinner sk-spinner-fading-circle">
                <div className="sk-circle1 sk-circle"></div>
                <div className="sk-circle2 sk-circle"></div>
                <div className="sk-circle3 sk-circle"></div>
                <div className="sk-circle4 sk-circle"></div>
                <div className="sk-circle5 sk-circle"></div>
                <div className="sk-circle6 sk-circle"></div>
                <div className="sk-circle7 sk-circle"></div>
                <div className="sk-circle8 sk-circle"></div>
                <div className="sk-circle9 sk-circle"></div>
                <div className="sk-circle10 sk-circle"></div>
                <div className="sk-circle11 sk-circle"></div>
                <div className="sk-circle12 sk-circle"></div>
              </div>
              <div className="modal-header d-block text-center text-darkblue">
                <button className="close" onClick={this.props.onRequestClose}><span>×</span></button>
                <h4 className="modal-title">{this.props.modalTitle}</h4>
                <h5 className="font-normal">{this.props.modalDescription}</h5>
              </div>
              <div className="modal-body text-darkblue">
                <div className="d-flex">
                  <strong>Liste öğesi mi?</strong>
                  <div className="d-flex ml-2">
                    <input checked={this.state.listed} onChange={this.listedOnChange} onKeyDown={this.handleKeyPress} type="checkbox" />
                  </div>
                </div>
                <div className="d-flex">
                  <strong>Zorunluluk Durumu</strong>
                  <div className="d-flex ml-2"><input checked={this.state.required} onChange={this.requiredOnChange} onKeyDown={this.handleKeyPress} type="checkbox" /></div>
                </div>
              </div>
              <div className="modal-footer">
                <button className="btn btn-orange" onClick={this.saveOnClick}>Kaydet</button>
              </div>
            </div>
          </Modal>
          </div>
        </div>
      </div>
    )
  }
}