import React from 'react';
import {Link, useNavigate} from 'react-router-dom';
import swal from 'sweetalert';

import CrmMemberAppointmentStatusAddModal from '../../../component/siteManagementModals/crmMemberAppointmentStatuses/CrmMemberAppointmentStatusAddModal';

import {getCookie} from '../../../inc/cookies';
import config from '../../../inc/config';
import {isoToDateTime} from '../../../inc/helper';

class CrmMemberAppointmentStatusModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isOpenCrmMemberAppointmentStatusAddModal: false,

      crmMemberAppointmentStatuses: []
    };
    
    this.onCompleted = this.onCompleted.bind(this);
  }

  componentDidMount() {
    this.getCrmMemberAppointmentStatuses();
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
  }

  getCrmMemberAppointmentStatuses() {
    let sessionHash = getCookie('sessionHash');

    this.setState({isLoading: true});

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'crmMemberAppointmentStatuses', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({isLoading: false, crmMemberAppointmentStatuses: responseData.crmMemberAppointmentStatuses});
        }
        break;
        default: {
          this.setState({isLoading: false});

          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          }).then(() => this.props.navigate('/home'));
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  onCompleted() {
    this.getCrmMemberAppointmentStatuses();
    this.setState({isOpenCrmMemberAppointmentStatusAddModal: false});
  }

  render() {
    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <>
        <div className="row">
          <div className="col">
            <div className="ibox">
              <div className="ibox-title text-darkblue d-flex justify-content-between pr-2 pl-0">
                <div className="col-7 col-xl-8"><h5>Potansiyel Üye Durum Listesi</h5></div>
                <button className="btn btn-orange mb-2" onClick={() => this.setState({isOpenCrmMemberAppointmentStatusAddModal: true})} title="Ekle"><i className="fa fa-plus"></i> Potansiyel Üye Durumu Ekle</button>
              </div>
              <div className="table-responsive overflow-x-auto">
                <table className="table table-striped table-bordered">
                  <thead>
                    <tr className="text-center text-darkblue">
                      <th>Potansiyel Üye Durumu</th>
                      <th>Düzenlenme Tarihi</th>
                      <th>Sıralama</th>
                      <th>Durum</th>
                      <th>İşlem</th>
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {this.state.crmMemberAppointmentStatuses.map((crmMemberAppointmentStatusInfo) =>
                      <tr key={crmMemberAppointmentStatusInfo.id}>
                        <td className="align-middle">{crmMemberAppointmentStatusInfo.name}</td>
                        <td className="align-middle">{isoToDateTime(crmMemberAppointmentStatusInfo.modifiedDate)}</td>
                        <td className="align-middle">{crmMemberAppointmentStatusInfo.sort}</td>
                        <td className="align-middle">{crmMemberAppointmentStatusInfo.active == 1 ? 'Açık' : 'Kapalı'}</td>
                        <td className="align-middle">
                          <Link to={"/crmMemberAppointmentStatusEdit?id=" + crmMemberAppointmentStatusInfo.id}>
                            <button className="btn btn-orange text-center"><i className="fa fa-pencil text-white action-icon"></i></button>
                          </Link>
                        </td>
                      </tr>
                    )}
                    {this.state.crmMemberAppointmentStatuses.length == 0 ?
                      <tr>
                        <td className="align-middle p-3" colSpan="5">Potansiyel Üye Durumu Bulunamadı !</td>
                      </tr>
                    : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <CrmMemberAppointmentStatusAddModal isOpen={this.state.isOpenCrmMemberAppointmentStatusAddModal} modalTitle="Durum Ekle" modalDescription="Potansiyel üye ile ilgili durum bilgisi eklemek için aşağıdaki alanları doldurunuz !" onCompleted={this.onCompleted} onRequestClose={() => this.setState({isOpenCrmMemberAppointmentStatusAddModal: false})} />
      </>
  }
}

export default function CrmMemberAppointmentStatuses() {
  return (
    <CrmMemberAppointmentStatusModule navigate={useNavigate()} />
  )
}