import React from 'react';
import {Link,useNavigate, useSearchParams} from 'react-router-dom';

import swal from 'sweetalert';

import config from '../../inc/config'
import { getCookie } from '../../inc/cookies';
import {getCreditStatusName, isoToDateTime, slugify} from '../../inc/helper';


class CreditRequestDetailModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      files: '',

      creditRequestStatuses: [],
      creditRequestStatusId: 0,
      creditRequestInfo: {}
    };
  }

  componentDidMount() {
    this.getMyCreditDetail();
    window.scrollTo(0, 0);
  }

/*   getMyCreditDetail() {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'creditRequest/tr/' + this.props.searchParams.get('id'), requestOptions)
    .then(response => response.json())
    .then(responseData => responseData.status === 200 ? this.setState({isLoading: false, creditRequestInfo: responseData.creditRequestInfo}) : swal({dangerMode: true, icon: 'warning', title: 'Hay Aksi', text: responseData.message, buttons: 'Tamam'}).then(() => this.props.navigate('/home')), () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Yeniden Dene'}).then(() => this.getMyCreditDetail()));
  } */

  getMyCreditDetail() {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    let errorMessages = [];
    let remaining = 2;
    let complete = () => {
      remaining--;
      if (remaining == 0) {
        this.setState({isLoading: false});
        if (errorMessages.length > 0) {
          swal({dangerMode: true, icon: 'warning', title: 'Hay Aksi', text: errorMessages[0], buttons: 'Tamam'}).then(this.props.navigate('/home'));
        }else{
          this.setState({isLoading: false});
        }
      }
    }

    fetch(config.restApi + 'creditRequest/tr/' + this.props.searchParams.get('id'), requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200:
          this.setState({isLoading: false, creditRequestInfo: responseData.creditRequestInfo, creditRequestStatusId: responseData.creditRequestInfo.creditRequestStatus})
        break;
        case 401:
          errorMessages.push(responseData.message).then(this.props.navigate('/creditRequests'))
        break;
        default:
          errorMessages.push(responseData.message).then(() => this.props.navigate('/creditRequests'))
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));

    fetch(config.restApi + 'creditRequestStatuses/tr', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200:
          this.setState({creditRequestStatuses: responseData.creditRequestStatuses})
        break;
        case 401:
          errorMessages.push(responseData.message).then(this.props.navigate('/creditRequests'))
        break;
        default:
          errorMessages.push(responseData.message).then(() => this.props.navigate('/creditRequests'))
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));
  }

  phoneFormat(phone) {
    let parsedValue = /^(\d{3})(\d{3})(\d{2})(\d{2})$/.exec(phone);
    return parsedValue ? '(' + parsedValue[1] + ') ' + parsedValue[2] + ' ' + parsedValue[3] + ' ' + parsedValue[4] : '-';
  }


  confirmCreditRequestOnClick = () => {
    swal({
      dangerMode: true,
      icon: 'warning',
      title: 'Onay Durumu',
      text: 'Krediyi durumunu değiştirmek istediğinize emin misiniz?',
      buttons: {confirm: 'Tamam', cancel: 'Vazgeç'}
    }).then(value => {
      if (value) {
        let sessionHash = getCookie('sessionHash');

        const requestOptions = {
          method: 'PUT',
          headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
          body: JSON.stringify({creditStatus: this.state.creditRequestStatusId})
        };

        fetch(config.restApi + 'creditStatus/tr/' + this.props.searchParams.get('id'), requestOptions)
        .then(response => response.json())
        .then(responseData => {
          this.setState({isLoading: false});

          switch (responseData.status) {
            case 200: {
              swal({
                dangerMode: true,
                icon: 'success',
                title: 'Tamamlandı',
                text: 'Kredi durumu başarıyla değiştirildi.',
                buttons: 'Tamam'
              }).then(() => this.getMyCreditDetail())
            }
            break;
            default: {
              swal({
                dangerMode: true,
                icon: 'warning',
                title: 'Hay Aksi',
                text: responseData.message,
                buttons: 'Tamam'
              });
            }
            break;
          }
        }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
      }
    });
  }

  saveFileUpload = (index = 0) => {
    let sessionHash = getCookie('sessionHash');
    const formData = new FormData();
  
    for (let i = 0; i < this.state.files.length; i++) {
      formData.append('files', this.state.files[i]);
    }
  
    const fileCount = formData.getAll('files').length;
    const singleFileData = new FormData();
    singleFileData.append('files', formData.getAll('files')[index]);
  
    const requestOptions = {
      method: 'POST',
      headers: { 'Authorization': 'Bearer ' + sessionHash },
      body: singleFileData,
    };
  
    this.setState({ uploadLoading: true });
  
    fetch(config.restApi + 'creditRequestFileUpload/tr/' + this.props.searchParams.get('id'), requestOptions)
    .then((response) => response.json())
    .then(responseData => {
      const progressPercentage = ((index + 1) / fileCount) * 100;
      document.querySelector('.progress-bar').style.width = `${progressPercentage}%`;
      document.querySelector('.progress-bar').innerText = `%${Math.round(progressPercentage)} Tamamlandı`;

      switch (responseData.status) {
        case 200:
          if (index === fileCount - 1) {
            swal({dangerMode: true, icon: 'success', title: 'Başarılı', text: 'Tüm fotoğraflar ilana başarıyla eklendi.', buttons: 'Tamam'}).then(() => {this.setState({ isLoading: true }, () => { this.getMyCreditDetail(); this.setState({ files: '' }); this.setState({ isLoading: false, uploadLoading: false });});});
          } else {
            this.saveFileUpload(index + 1);
          }
          break;
        case 203:
            swal({dangerMode: true, icon: 'warning', title: 'Hay Aksi', text: responseData.message, buttons: 'Tamam'}).then(() => this.setState({isLoading: true}, () => {this.getMyCreditDetail(); this.setState({files: ''});this.setState({isLoading: false, uploadLoading:false});}));
            break;
        case 401:
          swal({dangerMode: true, icon: 'warning', title: 'Hay Aksi', text: responseData.message, buttons: 'Tamam'}).then(this.getMyCreditDetail(), this.setState({uploadLoading: false}));
        break;
        default:
          swal({dangerMode: true, icon: 'warning', title: 'Hay Aksi', text: responseData.message, buttons: 'Tamam'}).then(() => {this.getMyCreditDetail(); this.setState({ uploadLoading: false });});
        break;
      }
    })
    .catch(() =>swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam',}).then(() => this.setState({ uploadLoading: false })));
  };

  deleteFileOnClick(uploadId) {
    swal({
      dangerMode: true,
      icon: 'warning',
      title: 'Onayla',
      text: 'İlan dosyasını silmek istediğinize emin misiniz?',
      buttons: {confirm: 'Tamam', cancel: 'Vazgeç'}
    }).then(value => {
      if (value) {
        let sessionHash = getCookie('sessionHash');

        const requestOptions = {
          method: 'DELETE',
          headers: {'Authorization': 'Bearer ' + sessionHash},
        };

        fetch(config.restApi + 'creditRequestFileUpload/tr/' + uploadId, requestOptions)
        .then(response => response.json())
        .then(responseData => {
          switch (responseData.status) {
            case 200: {
              swal({
                dangerMode: true,
                icon: 'success',
                title: 'Tamamlandı',
                text: 'İlan dosyası başarıyla silindi.',
                buttons: 'Tamam'
              }).then(() => this.getMyCreditDetail());
            }
            break;
            default: {
              swal({
                dangerMode: true,
                icon: 'warning',
                title: 'Hay Aksi',
                text: responseData.message,
                buttons: 'Tamam'
              });
            }
            break;
          }
        }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
      }
    });
  }

  phoneFormat(phone) {
    let parsedValue = /^(\d{3})(\d{3})(\d{2})(\d{2})$/.exec(phone);
    return parsedValue ? '(' + parsedValue[1] + ') ' + parsedValue[2] + ' ' + parsedValue[3] + ' ' + parsedValue[4] : '-';
  }

  render() {
    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      this.state.uploadLoading ?
      <div>
        <div className="d-flex justify-content-center"><strong>Dosyalar yüklenene kadar lütfen sayfada bekleyiniz!</strong></div>
        <div className="progress">
          <div className="progress-bar" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
      </div>
      :
      <div className="col-lg-12">
        <div className="row">
          <div className="col">
            <div className="ibox">
              <div className="ibox-title text-darkblue d-flex justify-content-between pr-2">
                <h4>Başvuru Bilgilerim</h4>
                <div className="d-block d-sm-flex">
                    <div>
                      <select className="form-control-sm mx-2 mb-2 mb-sm-0" onChange={(event) => this.setState({creditRequestStatusId: parseInt(event.target.value) || ''})} value={this.state.creditRequestStatusId}>
                        <option value="">Seçiniz</option>
                        {this.state.creditRequestStatuses.map(creditRequestStatuseInfo => <option key={creditRequestStatuseInfo.id} value={creditRequestStatuseInfo.id}>{creditRequestStatuseInfo.name}</option>)}
                      </select>
                    </div>
                  {
                    this.state.creditRequestInfo.creditRequestStatus !== 10 ? <button className="btn btn-height btn-orange ml-2 mr-2" title="Krediyi Onayla" onClick={this.confirmCreditRequestOnClick}><i className="fa fa-check"></i><span className="d-none d-sm-inline"> Kaydet</span></button> : <div className="ml-auto mr-auto mr-5 " title="Onaylanmış Kredi" >{this.state.creditRequestInfo.creditRequestStatus === 10 && <strong className="btn text-green"><i className="fa fa-credit-card mr-1"></i><span className="d-none d-sm-inline"> Kullandırılmış Kredi</span></strong>}</div>
                  }
                  <Link className="btn border btn-height bg-green text-white mr-2" title="Yazışmaları Göster" to={'/creditRequestHistories?id=' + this.state.creditRequestInfo.id}><i className="fa fa-envelope"></i><span className="d-none d-sm-inline"> Yazışmaları Göster</span></Link>
                  <Link className="btn border btn-height bg-darkblue text-white" title="Geri" to={'/creditRequests'}><i className="fa fa-reply"></i><span className="d-none d-sm-inline"></span></Link>
                </div>
              </div>
              <div className="ibox-content">
                <div className="row mt-4">
                  <div className="col">
                    <h4 className="text-orange">BAŞVURU DETAYLARI</h4>
                    <div className="hr-line-dashed mt-0"></div>
                    <div className="row">
                      <div className="col-md-6 mb-2">
                        <div className="d-flex flex-column">
                          <h4 className="font-bold text-darkblue mb-0">Başvuru Tipi</h4>
                          {this.state.creditRequestInfo.creditUsageType ? <span>{this.state.creditRequestInfo.creditUsageType}</span> : '-'}
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="d-flex flex-column">
                          <h4 className="font-bold text-darkblue mb-0">Araç ağır hasar kayıtlı mı?</h4>
                          {this.state.creditRequestInfo.heavyDamageStatus ? <span>{this.state.creditRequestInfo.heavyDamageStatus}</span> : '-'}
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="d-flex flex-column">
                          <h4 className="font-bold text-darkblue mb-0">Araç ticari taksi çıkması mı?</h4>
                          {this.state.creditRequestInfo.commercialTaxiStatus ? <span>{this.state.creditRequestInfo.commercialTaxiStatus}</span> : '-'}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col">
                    <h4 className="text-orange">KREDİ BİLGİLERİ</h4>
                    <div className="hr-line-dashed mt-0"></div>
                    <div className="row">
                      <div className="col-md-6 mb-2">
                        <div className="d-flex flex-column">
                          <h4 className="font-bold text-darkblue mb-0">İstenilen Kredi Tutarı</h4>
                          {this.state.creditRequestInfo.loanAmount ? <span>{this.state.creditRequestInfo.loanAmount}</span> : '-'}
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="d-flex flex-column">
                          <h4 className="font-bold text-darkblue mb-0">Vade</h4>
                          {this.state.creditRequestInfo.loanTerms ? <span>{this.state.creditRequestInfo.loanTerms} Ay</span> : '-'}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col">
                    <h4 className="text-orange">ARAÇ BİLGİLERİ</h4>
                    <div className="hr-line-dashed mt-0"></div>
                    <div className="row">
                      <div className="col-md-6 mb-2">
                        <div className="d-flex flex-column">
                          <h4 className="font-bold text-darkblue mb-0">TSB Kodu</h4>
                          {this.state.creditRequestInfo.tsbCode ? <span>{this.state.creditRequestInfo.tsbCode}</span> : '-'}
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="d-flex flex-column">
                          <h4 className="font-bold text-darkblue mb-0">Plaka</h4>
                          {this.state.creditRequestInfo.plateCode ? <span>{this.state.creditRequestInfo.plateCode}</span> : '-'}
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="d-flex flex-column">
                          <h4 className="font-bold text-darkblue mb-0">Araç Kullanım Amacı</h4>
                          {this.state.creditRequestInfo.vehicleUsageType ? <span>{this.state.creditRequestInfo.vehicleUsageType}</span> : '-'}
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="d-flex flex-column">
                          <h4 className="font-bold text-darkblue mb-0">Ruhsat TC Kimlik No veya Vergi No</h4>
                          {this.state.creditRequestInfo.identityOfLicenceOwner ? <span>{this.state.creditRequestInfo.identityOfLicenceOwner}</span> : '-'}
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="d-flex flex-column">
                          <h4 className="font-bold text-darkblue mb-0">Belge Seri No</h4>
                          {this.state.creditRequestInfo.licenceSerialNo ? <span>{this.state.creditRequestInfo.licenceSerialNo}</span> : '-'}
                        </div>
                      </div>
                    </div>  
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col">
                    <h4 className="text-orange">KİMLİK BİLGİLERİ</h4>
                    <div className="hr-line-dashed mt-0"></div>
                    <div className="row">
                      <div className="col-md-6 mb-2">
                        <div className="d-flex flex-column">
                          <h4 className="font-bold text-darkblue mb-0">TC Kimlik No</h4>
                          {this.state.creditRequestInfo.identity ? <span>{this.state.creditRequestInfo.identity}</span> : '-'}
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="d-flex flex-column">
                          <h4 className="font-bold text-darkblue mb-0">Ad Soyad</h4>
                          <span>{this.state.creditRequestInfo.firstname ? <span>{this.state.creditRequestInfo.firstname}</span> : '-'} {this.state.creditRequestInfo.lastname ? <span>{this.state.creditRequestInfo.lastname}</span> : ''}</span>
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="d-flex flex-column">
                          <h4 className="font-bold text-darkblue mb-0">Doğum Tarihi</h4>
                          {this.state.creditRequestInfo.birthDate ? <span>{this.state.creditRequestInfo.birthDate}</span> : '-'}
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="d-flex flex-column">
                          <h4 className="font-bold text-darkblue mb-0">Doğum Yeri</h4>
                          {this.state.creditRequestInfo.birthPlace ? <span>{this.state.creditRequestInfo.birthPlace}</span> : '-'}
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="d-flex flex-column">
                          <h4 className="font-bold text-darkblue mb-0">Cinsiyet</h4>
                          {this.state.creditRequestInfo.gender ? <span>{this.state.creditRequestInfo.gender}</span> : '-'}
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="d-flex flex-column">
                          <h4 className="font-bold text-darkblue mb-0">Medeni Hali</h4>
                          {this.state.creditRequestInfo.maritalStatus ? <span>{this.state.creditRequestInfo.maritalStatus}</span> : '-'}
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="d-flex flex-column">
                          <h4 className="font-bold text-darkblue mb-0">Baba Adı</h4>
                          <span>{this.state.creditRequestInfo.fatherName ? this.state.creditRequestInfo.fatherName : '-'}</span>
                        </div>
                      </div> 
                      <div className="col-md-6 mb-2">
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="d-flex flex-column">
                          <h4 className="font-bold text-darkblue mb-0">Kimlik Seri No</h4>
                          {this.state.creditRequestInfo.identitySerial ? <span>{this.state.creditRequestInfo.identitySerial}</span> : '-'}
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="d-flex flex-column">
                          <h4 className="font-bold text-darkblue mb-0">Kimlik Geçerlilik Tarihi</h4>
                          {this.state.creditRequestInfo.idendityValidityDate ? <span>{this.state.creditRequestInfo.idendityValidityDate}</span> : '-'}
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="d-flex flex-column">
                          <h4 className="font-bold text-darkblue mb-0">Nüfusa Kayıtlı Olduğu İl</h4>
                          {this.state.creditRequestInfo.addressOfRegistrationRegion ? <span>{this.state.creditRequestInfo.addressOfRegistrationRegion.cityName}</span> : '-'}
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="d-flex flex-column">
                          <h4 className="font-bold text-darkblue mb-0">Nüfusa Kayıtlı Olduğu İlçe</h4>
                          {this.state.creditRequestInfo.addressOfRegistrationRegion ? <span>{this.state.creditRequestInfo.addressOfRegistrationRegion.townName}</span> : '-'}
                        </div>
                      </div>
                    </div>  
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col">
                    <h4 className="text-orange">İLETİŞİM BİLGİLERİ</h4>
                    <div className="hr-line-dashed mt-0" />
                    <div className="row">
                      <div className="col-md-6 mb-2">
                        <div className="d-flex flex-column">
                          <h4 className="font-bold text-darkblue mb-0">E-posta</h4>
                          {this.state.creditRequestInfo.email ? <span>{this.state.creditRequestInfo.email}</span> : '-'}
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="d-flex flex-column">
                          <h4 className="font-bold text-darkblue mb-0">Cep Telefonu</h4>
                          {this.state.creditRequestInfo.phoneNumber ? <span>{this.phoneFormat(this.state.creditRequestInfo.phoneNumber)}</span> : '-'}
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="d-flex flex-column">
                          <h4 className="font-bold text-darkblue mb-0">Yakınının Cep Telefonu</h4>
                          {this.state.creditRequestInfo.phoneOfRelationship ? <span>{this.phoneFormat(this.state.creditRequestInfo.phoneOfRelationship)}</span> : '-'}
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="d-flex flex-column">
                          <h4 className="font-bold text-darkblue mb-0">Yakınlık Derecesi</h4>
                          {this.state.creditRequestInfo.degreeOfRelationship ? <span>{this.state.creditRequestInfo.degreeOfRelationship}</span> : '-'}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col">
                    <h4 className="text-orange">İKAMETGAH BİLGİLERİ</h4>
                    <div className="hr-line-dashed mt-0" />
                    <div className="row">
                      <div className="col-md-6 mb-2">
                        <div className="d-flex flex-column">
                          <h4 className="font-bold text-darkblue mb-0">İl</h4>
                          {this.state.creditRequestInfo.homePlaceRegion ? <span>{this.state.creditRequestInfo.homePlaceRegion.cityName}</span> : '-'}
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="d-flex flex-column">
                          <h4 className="font-bold text-darkblue mb-0">İlçe</h4>
                          {this.state.creditRequestInfo.homePlaceRegion ? <span>{this.state.creditRequestInfo.homePlaceRegion.townName}</span> : '-'}
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="d-flex flex-column">
                          <h4 className="font-bold text-darkblue mb-0">Semt</h4>
                          {this.state.creditRequestInfo.homePlaceRegion ? <span>{this.state.creditRequestInfo.homePlaceRegion.districtName}</span> : '-'}
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="d-flex flex-column">
                          <h4 className="font-bold text-darkblue mb-0">Mahalle</h4>
                          {this.state.creditRequestInfo.homePlaceRegion? <span>{this.state.creditRequestInfo.homePlaceRegion.quarterName}</span> : '-'}
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="d-flex flex-column">
                          <h4 className="font-bold text-darkblue mb-0">Adres</h4>
                          {this.state.creditRequestInfo.homePlaceAddress ? <span>{this.state.creditRequestInfo.homePlaceAddress}</span> : '-'}
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="d-flex flex-column">
                          <h4 className="font-bold text-darkblue mb-0">Ev Durumu</h4>
                          {this.state.creditRequestInfo.homeStatus ? <span>{this.state.creditRequestInfo.homeStatus}</span> : '-'}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col">
                    <h4 className="text-orange">EĞİTİM BİLGİLERİ</h4>
                    <div className="hr-line-dashed mt-0" />
                    <div className="row">
                      <div className="col-md-6 mb-2">
                        <div className="d-flex flex-column">
                          <h4 className="font-bold text-darkblue mb-0">Eğitim Durumu</h4>
                          {this.state.creditRequestInfo.educationStatus ? <span>{this.state.creditRequestInfo.educationStatus}</span> : '-'}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col">
                    <h4 className="text-orange">ÇALIŞMA BİLGİLERİ</h4>
                    <div className="hr-line-dashed mt-0" />
                    <div className="row">
                      <div className="col-md-6 mb-2">
                        <div className="d-flex flex-column">
                          <h4 className="font-bold text-darkblue mb-0">Çalışma Şekli</h4>
                          {this.state.creditRequestInfo.workStatus ? <span>{this.state.creditRequestInfo.workStatus}</span> : '-'}
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="d-flex flex-column">
                          <h4 className="font-bold text-darkblue mb-0">Sektör</h4>
                          {this.state.creditRequestInfo.workSector ? <span>{this.state.creditRequestInfo.workSector}</span> : '-'}
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="d-flex flex-column">
                          <h4 className="font-bold text-darkblue mb-0">Meslek Ünvanı</h4>
                          {this.state.creditRequestInfo.workTitle ? <span>{this.state.creditRequestInfo.workTitle}</span> : '-'}
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="d-flex flex-column">
                          <h4 className="font-bold text-darkblue mb-0">Sigorta Tipi</h4>
                          {this.state.creditRequestInfo.insuranceType ? <span>{this.state.creditRequestInfo.insuranceType}</span> : '-'}
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="d-flex flex-column">
                          <h4 className="font-bold text-darkblue mb-0">İş Yeri Ticari Ünvanı</h4>
                          {this.state.creditRequestInfo.workPlaceName ? <span>{this.state.creditRequestInfo.workPlaceName}</span> : '-'}
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="d-flex flex-column">
                          <h4 className="font-bold text-darkblue mb-0">Çalışma Süresi (Yıl)</h4>
                          {this.state.creditRequestInfo.workPeriodYears ? <span>{this.state.creditRequestInfo.workPeriodYears}</span> : '-'}
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="d-flex flex-column">
                          <h4 className="font-bold text-darkblue mb-0">Çalışma Süresi (Ay)</h4>
                          {this.state.creditRequestInfo.workPeriodMonths ? <span>{this.state.creditRequestInfo.workPeriodMonths}</span> : '-'}
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="d-flex flex-column">
                          <h4 className="font-bold text-darkblue mb-0">İl</h4>
                          {this.state.creditRequestInfo.workPlaceRegion ? <span>{this.state.creditRequestInfo.workPlaceRegion.cityName}</span> : '-'}
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="d-flex flex-column">
                          <h4 className="font-bold text-darkblue mb-0">İlçe</h4>
                          {this.state.creditRequestInfo.workPlaceRegion ? <span>{this.state.creditRequestInfo.workPlaceRegion.countryName}</span> : '-'}
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="d-flex flex-column">
                          <h4 className="font-bold text-darkblue mb-0">Semt</h4>
                          {this.state.creditRequestInfo.workPlaceRegion? <span>{this.state.creditRequestInfo.workPlaceRegion.districtName}</span> : '-'}
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="d-flex flex-column">
                          <h4 className="font-bold text-darkblue mb-0">Mahalle</h4>
                          {this.state.creditRequestInfo.workPlaceRegion ? <span>{this.state.creditRequestInfo.workPlaceRegion.quarterName}</span> : '-'}
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="d-flex flex-column">
                          <h4 className="font-bold text-darkblue mb-0">Adres</h4>
                          {this.state.creditRequestInfo.workPlaceAddress ? <span>{this.state.creditRequestInfo.workPlaceAddress}</span> : '-'}
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="d-flex flex-column">
                          <h4 className="font-bold text-darkblue mb-0">İş Telefonu</h4>
                          {this.state.creditRequestInfo.workPhoneNumber ? <span>{this.phoneFormat(this.state.creditRequestInfo.workPhoneNumber)}</span> : '-'}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col">
                    <h4 className="text-orange">GELİR BİLGİLERİ</h4>
                    <div className="hr-line-dashed mt-0" />
                    <div className="row">
                      <div className="col-md-6 mb-2">
                        <div className="d-flex flex-column">
                          <h4 className="font-bold text-darkblue mb-0">Aylık Gelir</h4>
                          {this.state.creditRequestInfo.monthlyEarnings ? <span>{this.state.creditRequestInfo.monthlyEarnings}</span> : '-'}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col">
                    <h4 className="text-orange">DİĞER</h4>
                    <div className="hr-line-dashed mt-0" />
                    <div className="row">
                      <div className="col-md-6 mb-2">
                        <div className="d-flex flex-column">
                          <h4 className="font-bold text-darkblue mb-0">İlan Başlığı</h4>
                          <Link className="text-orange text-underline-orange" target='_blank' to={`https://${config.host}/ilan/${slugify(this.state.creditRequestInfo.advertTitle)}-${this.state.creditRequestInfo.advertId}/detay`}><span>{this.state.creditRequestInfo.advertTitle}</span></Link>
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="d-flex flex-column">
                          <h4 className="font-bold text-darkblue mb-0">Finans Kurumu</h4>
                          <span>{this.state.creditRequestInfo.providerName}</span>
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="d-flex flex-column">
                          <h4 className="font-bold text-darkblue mb-0">Firma Sahibi</h4>
                          <Link className="text-orange text-underline-orange" target='_blank' to={`/memberEdit?id=${this.state.creditRequestInfo.memberId}&tab=3`}><span>{this.state.creditRequestInfo.memberFullName}</span></Link>
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="d-flex flex-column">
                          <h4 className="font-bold text-darkblue mb-0">Başvuru Durumu</h4>
                          {this.state.creditRequestInfo.creditRequestStatus ? <span>{getCreditStatusName(this.state.creditRequestInfo.creditRequestStatus)}</span> : '-'}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col">
                    <h4 className="text-orange">EVRAKLAR</h4>
                    <div className="hr-line-dashed mt-0" />
                      <div className="col-12 px-0 mb-2">
                        <div className="d-flex flex-column">
                          <h4 className="font-bold text-darkblue mb-0">Evrak Yükle</h4>
                            <form className="d-flex align-items-center justify-content-center dropzone mb-2 mt-2">
                              <div className="fallback">
                                <input accept=".jpg, .jpeg, .png, .pdf" multiple name="file" onChange={(e) => {this.setState({ files: e.target.files}, () => {this.saveFileUpload();});}} type="file"/>
                              </div>
                            </form>
                        </div>
                      </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col">
                    <h4 className="text-orange">BAŞVURU EVRAKLARI</h4>
                    <div className="hr-line-dashed mt-0" />
                      {this.state.creditRequestInfo.files.length !== 0 ?
                        this.state.creditRequestInfo.files.map((file) => (
                          <div className="file-box" key={file.id}>
                            <div className="file">
                              <div className="d-flex justify-content-end">
                                <button title="Sil" className="btn border mr-1 mt-1" onClick={() => this.deleteFileOnClick(file.id)}>
                                  <i className="fa fa-close"></i>
                                </button>
                              </div>
                              <a title="Görüntüle" target="_blank" href={'https://file.satisgaranti.com/' + file.path}>
                                <span className="corner"></span>
                                <div className="icon">
                                  <i className="fa fa-file"></i>
                                </div>
                                <div className="file-name">
                                  {file.filename} <br /><small className="text-black">{isoToDateTime(file.addedDate)}</small>
                                </div>
                              </a>
                            </div>
                          </div>
                        ))
                      : <div> Evrak Bulunamadı </div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  }
}

export default function CreditRequestDetail() {
  return (
    <CreditRequestDetailModule navigate={useNavigate()} searchParams={useSearchParams()[0]} />
  )
}