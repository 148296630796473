import React from 'react';
import {Link, useNavigate} from 'react-router-dom';
import swal from 'sweetalert';

import {getCookie} from '../../../inc/cookies';
import config from '../../../inc/config';
import {isoToDateTime} from '../../../inc/helper';

class AgreementModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,

      agreements: []
    };
  }

  componentDidMount() {
    this.getAgreements();
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
  }

  filteredAgreements(query) {
    let sessionHash = getCookie('sessionHash');

    this.setState({isLoading: true});

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'agreements?' + query, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({isLoading: false, agreements: responseData.agreements});
        }
        break;
        default: {
          this.setState({isLoading: false});

          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  getAgreements() {
    let sessionHash = getCookie('sessionHash');

    this.setState({isLoading: true});

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    let errorMessages = [];
    let remaining = 1;
    let complete = () => {
      remaining--;
      if (remaining == 0) {
        this.setState({isLoading: false});
      }
    }

    fetch(config.restApi + 'agreements', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({agreements: responseData.agreements});
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));
  }

  render() {
    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <>
        <div className="row">
          <div className="col">
            <div className="d-flex ibox justify-content-end">
            </div>
          </div>
        </div>
        <div className="row custom-container">
          <div className="col">
            <div className="ibox">
              <div className="ibox-title d-flex justify-content-between text-darkblue pr-2 pl-0">
                <h5>Gizlilik Sözleşmesi</h5>
                <Link className="btn btn-orange mb-2" title="Blog Ekle" to="/agreementAdd"><i className="fa fa-plus"></i> Sözleşme Ekle</Link>
              </div>
              <div className="table-responsive overflow-x-auto">
                <table className="table table-striped table-bordered">
                <thead>
                  <tr className="text-center text-darkblue">
                    <th>Sözleşme Adı</th>
                    <th>Eklenme Tarihi</th>
                    <th>Ekleyen Adı Soyadı</th>
                    <th>Durum</th>
                    <th>İşlem</th>
                  </tr>
                </thead>
                <tbody className="text-center">
                {this.state.agreements.map((agreementInfo) =>
                  <tr key={agreementInfo.id}>
                    <td className="align-middle">Versiyon {agreementInfo.id}</td>
                    <td className="align-middle">{isoToDateTime(agreementInfo.addedDate)}</td>
                    <td className="align-middle">{agreementInfo.addedFullName}</td>
                    <td className="align-middle">{agreementInfo.active == 1 ? 'Açık' : 'Kapalı'}</td>
                    <td className="align-middle">
                      <Link className="btn btn-orange text-center" to={"/agreementEdit?id=" + agreementInfo.id}><i className="fa fa-pencil text-white action-icon"></i></Link>
                    </td>
                  </tr>
                )}
                {this.state.agreements.length == 0 ?
                  <tr>
                    <td className="align-middle p-3" colSpan="5">Sözleşme Bulunamadı !</td>
                  </tr>
                : null}
                </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
  }
}

export default function Agreements() {
  return (
    <AgreementModule navigate={useNavigate()} />
  )
}