import React from 'react';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import swal from 'sweetalert';

import MemberInvoiceMarketerAddModal from '../../../component/member/payment/MemberInvoiceMarketerAddModal';

import {getCookie} from '../../../inc/cookies';
import config from '../../../inc/config';
import {isoToMysqlDate, isoToDateTime, priceFormat} from '../../../inc/helper';

class TransactionInvoiceEditModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isOpenMemberInvoiceMarketerAddModal: false,

      paymentDate: null,
      price: 0,
      transactionProducts: '',

      memberId: 0,  
      fullname: '',
      identity: '',
      companyName: '',
      taxNumber: '',
      taxOffice: '',
      countryId: 1,
      countryName: 'Türkiye',
      cityId: '',
      cityName: '',
      address: '',
      billingPeriod: null,

      invoiceType: 0,
      locked: 0,

      cities:[],
      countries:[],
      memberInvoiceMarketers: [],

      editInvoiceMarketers: null
    };
  }

  componentDidMount() {
		this.getInvoiceDetail();
    this.getMemberInvoiceMarketers();
    window.scrollTo(0, 0);
  }

  getInvoiceDetail() {
    let sessionHash = getCookie('sessionHash');

    this.setState({isLoading: true});

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    let errorMessages = [];
    let remaining = 3;
    let complete = () => {
      remaining--;
      if (remaining === 0) {
        if (errorMessages.length > 0) {
          swal({dangerMode: true, icon: 'warning', title: 'Hay Aksi', text: errorMessages[0], buttons: 'Tamam'}).then(this.props.navigate(-1));
        }else{
          this.setState({isLoading: false});
        }
      }
    }

    fetch(config.restApi + 'memberInvoice/tr/' + this.props.searchParams.get('id'), requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({isLoading: false, memberId: responseData.memberInvoice.memberId, price: responseData.memberInvoice.price, transactionProducts: responseData.memberInvoice.transactionProducts, paymentDate: responseData.memberInvoice.paymentDate, invoiceType: responseData.memberInvoice.invoiceType, billingPeriod: isoToMysqlDate(responseData.memberInvoice.billingPeriod), fullname: responseData.memberInvoice.fullname, identity: responseData.memberInvoice.identity, companyName: responseData.memberInvoice.companyName, taxNumber: responseData.memberInvoice.taxNumber, taxOffice: responseData.memberInvoice.taxOffice, cityId: responseData.memberInvoice.cityId, address: responseData.memberInvoice.address, locked: responseData.memberInvoice.locked});
        }
        break;
        case 401:  {
          errorMessages.push(responseData.message)
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete(); 
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));

    fetch(config.restApi + 'countries/tr?filterActive=1', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({countries: responseData.countries});
        }
        break;
        case 401:  {
          errorMessages.push(responseData.message)
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));

    fetch(config.restApi + 'cities/tr/' + this.state.countryId + '?filterActive=1', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({cities: responseData.cities});
        }
        break;
        case 401:  {
          errorMessages.push(responseData.message)
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));
  }

  getMemberInvoiceMarketers() {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'memberInvoiceMarketers/tr/' + this.props.searchParams.get('id'), requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({memberInvoiceMarketers: responseData.memberInvoiceMarketers});
        }
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  getCities(countryId) {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'cities/tr/' + countryId + '?filterActive=1', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({cities: responseData.cities});
        }
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  countryOnChange = (event) => {
    let countryId = event.target.value;
    if (countryId) {
      this.getCities(countryId);
      this.setState({countryId: parseInt(countryId), countryName: event.target.options[event.target.selectedIndex].text, cityId: '', cityName: ''})
    } else {
      this.setState({countryId: '', countryName:  '', cityId: '', cityName: ''})
    }
  }

  invoiceTypeOnChange = (event) => {
    this.setState({invoiceType: parseInt(event.target.value) || 0});
  }

  cityOnChange = (event) => {
    let cityId = event.target.value;
    if (cityId) {
      this.setState({cityId: parseInt(cityId), cityName: event.target.options[event.target.selectedIndex].text})
    } else {
      this.setState({cityId: '', cityName:  ''})
    }
  }

  saveInvoiceDetailOnClick = () => {
    let sessionHash = getCookie('sessionHash');

    let infoMessage = '';

    if(this.state.invoiceType === 1) {
     this.setState({taxNumber: '', companyName: ''});
     if(!this.state.fullname) {
      infoMessage = 'Ad soyad boş geçilemez !';
     } else if(!this.state.identity) {
      infoMessage = 'Kimlik bilgisi boş geçilemez !';
     } else if(!this.state.taxOffice) {
      infoMessage = 'Vergi dairesi boş geçilemez !';
     } else if(!this.state.cityId) {
      infoMessage = 'İl boş geçilemez !';
     } else if(!this.state.address) {
      infoMessage = 'Adres boş geçilemez !';
     } else if(this.state.memberInvoiceMarketers.length === 0) {
      infoMessage = 'Satış sorumlusu boş geçilemez !';
     }
    }
    else if (this.state.invoiceType === 2) {
      this.setState({fullname: '', identity: ''});
      if(!this.state.companyName) {
        infoMessage = 'Şirket adı boş geçilemez !';
      } else if(!this.state.taxNumber) {
       infoMessage = 'Vergi numarası boş geçilemez !';
      } else if(!this.state.taxOffice) {
       infoMessage = 'Vergi dairesi boş geçilemez !';
      } else if(!this.state.cityId) {
       infoMessage = 'İl boş geçilemez !';
      } else if(!this.state.address) {
       infoMessage = 'Adres boş geçilemez !';
      } else if(this.state.memberInvoiceMarketers.length === 0) {
        infoMessage = 'Satış sorumlusu boş geçilemez !';
      }
    }

    if (infoMessage) {
      swal({
        dangerMode: true,
        icon: 'info',
        title: 'Eksik Bilgi',
        text: infoMessage,
        buttons: 'Tamam'
      });
    } else {
      this.setState({isCompleted: false});

      const requestOptions = {
        method: 'PUT',
        headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
        body: JSON.stringify({invoiceType: this.state.invoiceType, billingPeriod: isoToMysqlDate(this.state.billingPeriod), taxOffice: this.state.taxOffice, countryId: this.state.countryId, cityId: this.state.cityId, address: this.state.address, fullname: this.state.fullname, identity: this.state.identity,companyName: this.state.companyName, taxNumber: this.state.taxNumber})
      };

      fetch(config.restApi + 'memberInvoice/' + this.props.searchParams.get('id'), requestOptions)
      .then(response => response.json())
      .then(responseData => {
        switch (responseData.status) {
          case 200: {
            swal({
              dangerMode: true,
              icon: 'success',
              title: 'Tamamlandı',
              text: 'Fatura başarıyla güncellendi.',
              buttons: 'Tamam'
            }).then(() => this.getInvoiceDetail());
          }
          break;
          default: {
            swal({
              dangerMode: true,
              icon: 'warning',
              title: 'Hay Aksi',
              text: responseData.message,
              buttons: 'Tamam'
            });
          }
          break;
        }
      }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
    }
  }

  saveMemberInvoiceLockedOnClick = () => {
    swal({
      dangerMode: true,
      icon: 'warning',
      title: 'Onay Durumu',
      text: 'Faturayı onaylamak istediğinize emin misiniz?',
      buttons: {confirm: 'Tamam', cancel: 'Vazgeç'}
    }).then(value => {
      if (value) {
        let sessionHash = getCookie('sessionHash');

        const requestOptions = {
          method: 'PUT',
          headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
        };

        fetch(config.restApi + 'memberInvoiceLocked/' + this.props.searchParams.get('id'), requestOptions)
        .then(response => response.json())
        .then(responseData => {
          this.setState({isLoading: false});

          switch (responseData.status) {
            case 200: {
              swal({
                dangerMode: true,
                icon: 'success',
                title: 'Tamamlandı',
                text: 'Fatura onaylandı.',
                buttons: 'Tamam'
              }).then(() => this.getInvoiceDetail())
            }
            break;
            default: {
              swal({
                dangerMode: true,
                icon: 'warning',
                title: 'Hay Aksi',
                text: responseData.message,
                buttons: 'Tamam'
              });
            }
            break;
          }
        }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
      }
    });
  }

  saveMemberInvoiceMarketerAddOnClick(memberInvoiceMarketers, invoiceId) {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'POST',
      headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
      body: JSON.stringify({memberInvoiceMarketers: [memberInvoiceMarketers]})
    };

    fetch(config.restApi + 'memberInvoiceMarketer/tr/' + invoiceId, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch (responseData.status) {
        case 200: {
          swal({
            dangerMode: true,
            icon: 'success',
            title: 'Tamamlandı',
            text: 'Satış sorumlusu başarıyla eklendi.',
            buttons: 'Tamam'
          }).then(() => this.getMemberInvoiceMarketers(), this.setState({isOpenMemberInvoiceMarketerAddModal: false}), memberInvoiceMarketers.marketerId = 0, memberInvoiceMarketers.fullName = '', memberInvoiceMarketers.price = 0);
        }
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  deleteMemberInvoiceMarketerOnClick(id) {
    swal({
      dangerMode: true,
      icon: 'warning',
      title: 'Onayla',
      text: 'Satış sorumlusunu silmek istediğinize emin misiniz?',
      buttons: {confirm: 'Tamam', cancel: 'Vazgeç'}
    }).then(value => {
      if (value) {
        let sessionHash = getCookie('sessionHash');

        const requestOptions = {
          method: 'DELETE',
          headers: {'Authorization': 'Bearer ' + sessionHash}
        };

        fetch(config.restApi + 'memberInvoiceMarketer/tr/' + this.props.searchParams.get('id') + '/' + id, requestOptions)
        .then(response => response.json())
        .then(responseData => {
          switch (responseData.status) {
            case 200: {
              swal({
                dangerMode: true,
                icon: 'success',
                title: 'Tamamlandı',
                text: 'Satış sorumlusu başarıyla silindi.',
                buttons: 'Tamam'
              }).then(() => this.getMemberInvoiceMarketers());
            }
            break;
            default: {
              swal({
                dangerMode: true,
                icon: 'warning',
                title: 'Hay Aksi',
                text: responseData.message,
                buttons: 'Tamam'
              });
            }
            break;
          }
        }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
      }
    });
  }

  render() {
    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <>
        <div className="row">
          <div className="col">
            <div className="ibox">
              <div className="ibox-content text-darkblue">
                <div className="row d-flex justify-content-between pr-3 pl-3">
                  <h4>Fatura Düzenleme</h4>
                  <div className="d-flex justify-content-end">
                  {
                    this.state.invoiceType === 0 ? null 
                    : 
                    this.state.locked ? <button className="btn btn-green border mr-2" onClick={this.saveMemberInvoiceLockedOnClick} title="Onaylanmış Fatura"><i className="fa fa-check"></i> Onaylanmış Fatura</button>
                    : 
                    <button className="btn btn-orange border mr-2" onClick={this.saveMemberInvoiceLockedOnClick} title="Fatura Onayla"><i className="fa fa-check"></i> Fatura Onayla</button>
                  }
                  <Link className="btn btn-darkblue text-white" title="Geri" to={"/memberEdit?id=" + this.state.memberId}><i className="fa fa-reply"></i></Link>
                  </div>
               </div>
                <div className="hr-line-dashed"></div>
                <div className="row custom-container mb-3">
                  <div className="col overflow-auto">
                    <div className="ibox table-width">
                      <div className="ibox-title text-darkblue">
                        <h5>Faturaya Ait İşlem Bilgileri</h5>
                      </div>
                      <div className="ibox-content overflow-auto">
                        <div className="row">
                          <table className="table table-striped table-bordered">
                          <thead>
                            <tr className="text-center text-darkblue">
                              <th>Ürün İçeriği</th>
                              <th>Toplam Tutar</th>
                              <th>İşlem Tarihi</th>
                            </tr>
                          </thead>
                          <tbody className="text-center">
                            <tr>
                              <td>{this.state.transactionProducts}</td>
                              <td>{this.state.price ? priceFormat(this.state.price) : '-' } ₺</td>
                              <td>{this.state.paymentDate ? isoToDateTime(this.state.paymentDate) : '-'}</td>
                            </tr>
                          </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                  <div className="ibox">
                    <div className="ibox-content text-darkblue">
                      <div className="row">
                        <div className="col-md-6">
                          <h5><strong>Fatura Tipi<strong className="text-danger ml-1">*</strong></strong></h5>
                          <select className="form-control form-control-sm form-input mb-2 mt-1" onChange={this.invoiceTypeOnChange} value={this.state.invoiceType}>
                            <option value="0">Seçiniz</option>
                            <option value="1">Şahıs Şirketi Faturası</option>
                            <option value="2">Kurumsal Fatura</option>
                          </select>
                        </div>
                        <div className="col-md-6">
                          <h5><strong>Fatura Tarihi<strong className="text-danger ml-1">*</strong></strong></h5>
                          <input className="form-control form-input" onChange={event => this.setState({billingPeriod: event.target.value || null})} type="date" value={this.state.billingPeriod} />
                        </div>
                      </div>
                      {
                        this.state.invoiceType === 0 ? null
                        :
                        this.state.invoiceType === 2 ? 
                        <>
                          <div className="row">
                            <div className="col-md-6">
                              <h5><strong>Firma Adı<strong className="text-danger ml-1">*</strong></strong></h5>
                              <input className="form-control form-control-sm form-input mb-1" onChange={event => this.setState({companyName: event.target.value.trimStart()})} placeholder="Firma Adı" type="text" value={this.state.companyName}/>
                            </div>
                            <div className="col-md-6">
                              <h5><strong>Vergi Numarası<strong className="text-danger ml-1">*</strong></strong></h5>
                              <input className="form-control form-control-sm form-input mb-1" maxLength={10} onChange={event => this.setState({taxNumber: event.target.value.replace(/^[^0-9]+/g, '')})} placeholder="Vergi Numarası" type="text" value={this.state.taxNumber}/>
                            </div>
                            <div className="col-md-12">
                              <h5><strong>Vergi Dairesi<strong className="text-danger ml-1">*</strong></strong></h5>
                              <input className="form-control form-control-sm form-input mb-1" onChange={event => this.setState({taxOffice: event.target.value.trimStart()})} placeholder="Vergi Dairesi" type="text" value={this.state.taxOffice}/>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <h5><strong>Ülke<strong className="text-danger ml-1">*</strong></strong></h5>
                              <select className="form-control form-control-sm form-input mb-2 mt-1" disabled={true} onChange={this.countryOnChange} value={this.state.countryId}>
                                <option value="">Seçiniz</option>
                                {this.state.countries.map((countryInfo) => <option key={countryInfo.id} value={countryInfo.id}>{countryInfo.name}</option>)}
                              </select>
                            </div>
                            <div className="col-md-6">
                              <h5><strong>İl<strong className="text-danger ml-1">*</strong></strong></h5>
                              <select className="form-control form-control-sm form-input mb-2 mt-1" onChange={this.cityOnChange} value={this.state.cityId}>
                                <option value="">Seçiniz</option>
                                {this.state.cities.map((cityInfo) => <option key={cityInfo.id} value={cityInfo.id}>{cityInfo.name}</option>)}
                              </select>
                            </div>
                          </div>
                          <div className="row mb-2">
                            <div className="col">
                              <h5><strong>Adres<strong className="text-danger ml-1">*</strong></strong></h5>
                              <textarea className="form-control form-input mt-2" onChange={event => this.setState({address: event.target.value.trimStart()})} placeholder="Adres giriniz." value={this.state.address} />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col">
                              <div className="ibox">
                                <div className="ibox-content text-darkblue">
                                  <div className="d-flex justify-content-between">
                                    <h4>Satış Sorumluları</h4>
                                    <button className="btn btn-orange" onClick={() => this.setState({editInvoiceMarketers: null, isOpenMemberInvoiceMarketerAddModal: true})} title="Satış Sorumlusu Ekle"><i className="fa fa-plus"></i></button>
                                  </div>
                                  <div className="hr-line-dashed"></div>
                                  <div className="overflow-auto">
                                    <table className="table table-striped table-bordered">
                                    <thead>
                                      <tr className="text-center text-darkblue">
                                        <th>Satış Sorumlusu</th>
                                        <th>Satış Tutarı</th>
                                        <th>İşlem</th>
                                      </tr>
                                    </thead>
                                    <tbody className="text-center">
                                      {this.state.memberInvoiceMarketers.map((memberInvoiceMarketerInfo) =>
                                        <tr key={memberInvoiceMarketerInfo.id}>
                                          <td className="align-middle">{memberInvoiceMarketerInfo.memberFullName}</td>
                                          <td className="align-middle">{memberInvoiceMarketerInfo.price ? priceFormat(memberInvoiceMarketerInfo.price) : '-'}</td>
                                          <td className="align-middle">
                                            <button className="btn btn-darkblue" title="Sil" onClick={() => this.deleteMemberInvoiceMarketerOnClick(memberInvoiceMarketerInfo.memberId)}><i className="fa fa-trash"></i></button>
                                          </td>
                                        </tr>
                                      )}
                                      {this.state.memberInvoiceMarketers.length === 0 ?
                                        <tr>
                                          <td className="align-middle p-3" colSpan="3">Satış Sorumlusu Bulunamadı !</td>
                                        </tr>
                                      : null}
                                    </tbody>
                                    </table>
                                    <MemberInvoiceMarketerAddModal isOpen={this.state.isOpenMemberInvoiceMarketerAddModal} modalTitle="Satış Sorumlusu Ekle" modalDescription="Satış sorumlusu bilgisi eklemek için aşağıdaki bilgileri doldurunuz !" onRequestClose={() => this.setState({editInvoiceMarketers: null, isOpenMemberInvoiceMarketerAddModal: false})} saveOnClick={memberInvoiceMarketers => this.saveMemberInvoiceMarketerAddOnClick(memberInvoiceMarketers, this.props.searchParams.get('id'))} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-end">
                            <button className="btn bg-orange text-white" onClick={this.saveInvoiceDetailOnClick}><i className="fa fa-save"></i> Kaydet</button>
                          </div>
                        </>
                         :
                        <>
                          <div className="row">
                            <div className="col-md-6">
                              <h5><strong>Fatura Sahibi<strong className="text-danger ml-1">*</strong></strong></h5>
                              <input className="form-control form-control-sm form-input mb-1" onChange={event => this.setState({fullname: event.target.value.trimStart()})} placeholder="Adı Soyadı" type="text" value={this.state.fullname}/>
                            </div>
                            <div className="col-md-6">
                              <h5><strong>TC Kimlik Numarası<strong className="text-danger ml-1">*</strong></strong></h5>
                              <input className="form-control form-control-sm form-input mb-1" maxLength={11} onChange={event => this.setState({identity: event.target.value.replace(/^0|[^0-9]+/g, '')})} placeholder="TC Kimlik Numarası" type="text" value={this.state.identity}/>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col">
                              <h5><strong>Vergi Dairesi<strong className="text-danger ml-1">*</strong></strong></h5>
                              <input className="form-control form-control-sm form-input mb-1" onChange={event => this.setState({taxOffice: event.target.value.trimStart()})} placeholder="Vergi Dairesi" type="text" value={this.state.taxOffice}/>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <h5><strong>Ülke<strong className="text-danger ml-1">*</strong></strong></h5>
                              <select className="form-control form-control-sm form-input mb-2 mt-1" disabled={true} onChange={this.countryOnChange} value={this.state.countryId}>
                                <option value="">Seçiniz</option>
                                {this.state.countries.map((countryInfo) => <option key={countryInfo.id} value={countryInfo.id}>{countryInfo.name}</option>)}
                              </select>
                            </div>
                            <div className="col-md-6">
                              <h5><strong>İl<strong className="text-danger ml-1">*</strong></strong></h5>
                              <select className="form-control form-control-sm form-input mb-2 mt-1" onChange={this.cityOnChange} value={this.state.cityId}>
                                <option value="">Seçiniz</option>
                                {this.state.cities.map((cityInfo) => <option key={cityInfo.id} value={cityInfo.id}>{cityInfo.name}</option>)}
                              </select>
                            </div>
                          </div>
                          <div className="row mb-2">
                            <div className="col">
                              <h5><strong>Adres<strong className="text-danger ml-1">*</strong></strong></h5>
                              <textarea className="form-control form-input mt-2" onChange={event => this.setState({address: event.target.value.trimStart()})} placeholder="Adres giriniz." value={this.state.address} />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col">
                              <div className="ibox">
                                <div className="ibox-content text-darkblue">
                                  <div className="d-flex justify-content-between">
                                    <h4>Satış Sorumluları</h4>
                                    <button className="btn btn-orange" onClick={() => this.setState({editInvoiceMarketers: null, isOpenMemberInvoiceMarketerAddModal: true})} title="Satış Sorumlusu Ekle"><i className="fa fa-plus"></i></button>
                                  </div>
                                  <div className="hr-line-dashed"></div>
                                  <div className="overflow-auto">
                                    <table className="table table-striped table-bordered">
                                    <thead>
                                      <tr className="text-center text-darkblue">
                                        <th>Satış Sorumlusu</th>
                                        <th>Satış Tutarı</th>
                                        <th>İşlem</th>
                                      </tr>
                                    </thead>
                                    <tbody className="text-center">
                                      {this.state.memberInvoiceMarketers.map((memberInvoiceMarketerInfo) =>
                                        <tr key={memberInvoiceMarketerInfo.id}>
                                          <td className="align-middle">{memberInvoiceMarketerInfo.memberFullName}</td>
                                          <td className="align-middle">{memberInvoiceMarketerInfo.price ? priceFormat(memberInvoiceMarketerInfo.price) : '-'}</td>
                                          <td className="align-middle">
                                            <button className="btn btn-darkblue" title="Sil" onClick={() => this.deleteMemberInvoiceMarketerOnClick(memberInvoiceMarketerInfo.memberId)}><i className="fa fa-trash"></i></button>
                                          </td>
                                        </tr>
                                      )}
                                      {this.state.memberInvoiceMarketers.length === 0 ?
                                        <tr>
                                          <td className="align-middle p-3" colSpan="3">Satış Sorumlusu Bulunamadı !</td>
                                        </tr>
                                      : null}
                                    </tbody>
                                    </table>
                                    <MemberInvoiceMarketerAddModal isOpen={this.state.isOpenMemberInvoiceMarketerAddModal} modalTitle="Satış Sorumlusu Ekle" modalDescription="Satış sorumlusu bilgisi eklemek için aşağıdaki bilgileri doldurunuz !" onRequestClose={() => this.setState({editInvoiceMarketers: null, isOpenMemberInvoiceMarketerAddModal: false})} saveOnClick={memberInvoiceMarketers => this.saveMemberInvoiceMarketerAddOnClick(memberInvoiceMarketers, this.props.searchParams.get('id'))} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-end">
                            <button className="btn bg-orange text-white" onClick={this.saveInvoiceDetailOnClick}><i className="fa fa-save"></i> Kaydet</button>
                          </div>
                        </>
                      }
                    <div>
                    </div>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
  }
}

export default function TransactionInvoiceEdit() {
  return (
    <TransactionInvoiceEditModule navigate={useNavigate()} searchParams={useSearchParams()[0]} />
  )
}