import React from "react";
import { useNavigate, Link, useSearchParams } from "react-router-dom";
import swal from "sweetalert";
import ReactPaginate from "react-paginate";
import { getCookie } from "../../inc/cookies";
import config from "../../inc/config";
import { isoToDateTime, priceFormat } from "../../inc/helper";

class TowingRequestsModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,

      towingRequests: [],
      filterId: '',
      filterPlate: '',
      filterFullName: '',
      filterAddedDate: '',
      filterPickupCity: '',
      filterAppointmentEndDate: '',
      filterAddress: '',
      filterStatus:'',

      pageCount: 0,
      resultCount: 0,
      currentPage: 1,
      limit: 10
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getAppointments(this.state.currentPage);
    this.setState({ isLoading: false }); 
  }

  componentWillUnmount() {}
  
  getAppointments(currentPage) {
    let sessionHash = getCookie("sessionHash");

    const requestOptions = {
      method: "GET",
      headers: { Authorization: "Bearer " + sessionHash },
    };

    const startIndex = 0;
    const endIndex = startIndex + this.state.limit;

    


    fetch(config.restApi + `towingRequests/tr?limit=${this.state.limit}&page=${currentPage}`, requestOptions)
      .then((response) => response.json())
      .then(
        (responseData) => {
          switch (responseData.status) {
            case 200:
              const currenttowingRequests = responseData.towingRequests.slice(startIndex, endIndex);
              this.setState({ isLoading: false, towingRequests: currenttowingRequests, pageCount: responseData.pageCount, resultCount: responseData.resultCount, currentPage: currentPage });
              break;
            case 401:
              swal({
                dangerMode: true,
                icon: 'warning',
                title: 'Hay Aksi',
                text: responseData.message,
                buttons: 'Tamam'
              }).then(this.props.navigate('/home'))
              break;
            default:
              swal({
                dangerMode: true,
                icon: "warning",
                title: "Hay Aksi",
                text: responseData.message,
                buttons: "Tamam",
              });
              break;
          }
        },
        () =>
          swal({
            dangerMode: true,
            icon: "error",
            title: "Bağlantı Hatası",
            text: "Lütfen internet bağlantınızı kontrol ediniz.",
            buttons: "Tamam",
          })
      );
  }

  handlePageClick(page) {
    this.setState({currentPage: page.selected + 1}, () => this.getAppointments(this.state.currentPage))
  }

  phoneFormat(number) {
    let parsedValue = /^(\d{3})(\d{3})(\d{2})(\d{2})$/.exec(number);
    return parsedValue ? '(' + parsedValue[1] + ') ' + parsedValue[2] + ' ' + parsedValue[3] + ' ' + parsedValue[4] : number;
  }

  /* resetOnClick = (event) => {
    this.setState({
      filterId: '',
      filterPlate: '',
      filterFullName: '',
      filterAddedDate: '',
      filterPickupCity: '',
      filterAppointmentEndDate: '',
      filterAddress: '',
      filterStatus:'',
      currentPage: 1
    }, () => {this.setState({isLoading: true}, () => {this.filteredCreditRequests(this.state.currentPage);this.setState({isLoading: false});});});
  } */

  /* handleKeyPress = (event, page) => {
    if (event.key === 'Enter') {
      const query = `filterAdvertId=${this.state.filterAdvertId}&filterFullName=${this.state.filterFullName}&filterProviderId=${this.state.filterProviderId}&filterCreditStatus=${this.state.filterCreditStatus}&filterAddedStartDate=${this.state.filterAddedStartDate}&filterAddedEndDate=${this.state.filterAddedEndDate}`;
      this.setState({isLoading: true, currentPage: 1}, () => {this.filteredCreditRequests(this.state.currentPage, query);this.setState({isLoading: false});});
    }
  } */

  render() {
    return this.state.isLoading ? (
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
    ) : (
      <div className="row custom-container">
        <div className="col-12">
          <div className="ibox">
            <div className="ibox-title">
              <strong className="text-black">Çekici İşlemleri</strong>
            </div>
            <div className="table-responsive-lg mb-5">
              <table className="table table-bordered text-center">
                <thead>
                  <tr className="text-black">
                    <th>Başvuru No</th>
                    <th>Randevu Tarihi</th>
                    <th>Adı Soyadı</th>
                    <th>Telefon</th>
                    <th>Marka Model</th>
                    <th>Alındığı İl</th>
                    <th>Bırakılacağı İl</th>
                    <th>Adres</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.towingRequests.map((towingRequestInfo, index) =>
                    <tr key={index}>
                      <td className="align-middle">{towingRequestInfo.id}</td>
                      <td className="align-middle">{isoToDateTime(towingRequestInfo.pickupDate)}</td>
                      <td className="align-middle"><b>{towingRequestInfo.firstname + " " +towingRequestInfo.lastname}</b><br /><span className="text-darkblue">Üye No: </span><span className="text-darkblue"> # {towingRequestInfo.memberId}</span></td>
                      <td className="align-middle">{this.phoneFormat(towingRequestInfo.number)}</td>
                      <td className="align-middle">{towingRequestInfo.brand + " " +towingRequestInfo.model}</td>
                      <td className="align-middle">{towingRequestInfo.pickupCity}</td>
                      <td className="align-middle">{towingRequestInfo.dropoffCity}</td>
                      <td className="align-middle">{towingRequestInfo.dropoffAddress}</td>
                    </tr>
                  )}
                  {this.state.towingRequests.length === 0 && <tr><td className="align-middle text-darkblue p-3" colSpan="7">Çekici Başvurusu Bulunamadı !</td></tr>}
                </tbody>
              </table>
            </div>
            {this.state.towingRequests.length !== 0 &&
              <ReactPaginate
                previousLabel={"Geri"}
                nextLabel={"İleri"}
                breakLabel={"..."}
                pageCount={this.state.pageCount}
                marginPagesDisplayed={1}
                onPageChange={page => this.handlePageClick(page)}
                pageRangeDisplayed={2}
                containerClassName={'pagination'}
                activeClassName={'activePage'}
              />
            }
          </div>
        </div>
        {/* <div className="col-xl-3 order-first order-xl-last d-xl-none" id="accordion">
          <div className="ibox">
            <div className="ibox-content text-darkblue p-0">
              <h4 className="collapse-title">
                <a className="btn btn-link text-darkblue font-bold d-flex justify-content-between" data-toggle="collapse" href="#filterCollapse" title="Filtrele">Filtrele</a>
              </h4>
            </div>
            <div className="collapse" data-parent="#accordion" id="filterCollapse">
              <div className="ibox-content text-darkblue">
                <strong>Başvuru No</strong>
                <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({ filterMemberId: event.target.value })} onKeyDown={this.handleKeyPress} placeholder="Başvuru No" type="text" value={"this.state.filterMemberId"} />
                <strong>Randevu Tarihi</strong>
                <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({ filterAddedDate: event.target.value })} onKeyDown={this.handleKeyPress} placeholder="Randevu Tarihi" type="text" value={this.state.filterAddedDate} />
                <strong>Adı Soyadı</strong>
                <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({ filterFullName: event.target.value })} onKeyDown={this.handleKeyPress} placeholder="Adı Soyadı" type="text" value={this.state.filterFullName} />
                <strong>Marka Model</strong>
                <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({ filterPlate: event.target.value })} onKeyDown={this.handleKeyPress} placeholder="Marka Model" type="text" value={this.state.filterPlate} />
                <strong>Alındığı İl</strong>
                <input className="form-control form-inputt mb-2 mt-1" onChange={event => this.setState({ filterPickupCity: event.target.value })} onKeyDown={this.handleKeyPress} type="Alındığı İl" value={this.state.filterPickupCity} />
                <strong>Bırakılacağı İl</strong>
                <input className="form-control form-input" onChange={event => this.setState({ filterDropoffCity: event.target.value })} onKeyDown={this.handleKeyPress} type="Bırakılacağı İl" value={this.state.filterDropoffCity} />
                <strong>Adres</strong>
                <input className="form-control form-input" onChange={event => this.setState({ filterAddress: event.target.value })} onKeyDown={this.handleKeyPress} type="Adres" value={this.state.filterAddress} />
                <div className="d-flex justify-content-end">
                  <button className="btn btn-orange mt-3 mr-2" onClick={this.resetOnClick}><i className="fa fa-refresh"></i> Sıfırla</button>
                  <button className="btn btn-orange mt-3" onClick={this.filterOnClick}><i className="fa fa-filter"></i> Filtrele</button>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className="col-xl-3 order-first order-xl-last d-none d-xl-block">
          <div className="ibox">
            <div className="ibox-title text-darkblue">
              <h5><i className="fa fa-filter"></i> Filtrele</h5>
            </div>
            <div className="ibox-content text-darkblue">
              <strong>Başvuru No</strong>
              <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({ filterId: event.target.value })} onKeyDown={this.handleKeyPress} placeholder="Başvuru No" type="text" value={this.state.filterId} />
              <strong>Üye Adı</strong>
              <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({ filterFullName: event.target.value })} onKeyDown={this.handleKeyPress} placeholder="Üye Adı" type="text" value={this.state.filterFullName} />
              <strong>Durum</strong>
              <select className="form-control form-input mb-2 mt-1" onChange={this.filterActiveOnChange} onKeyDown={this.handleKeyPress} value={this.state.filterStatus}>
                <option value="">Seçiniz</option>
                <option value="0">İşlem Bekleniyor</option>
                <option value="1">İşlem Alındı</option>
                <option value="2">İşlem Tamamlandı</option>
                <option value="3">İşlem Başarısız</option>
              </select>
              <strong>Başlangıç Tarihi</strong>
              <input className="form-control form-inputt mb-2 mt-1" onChange={event => this.setState({ filterAppointmentStartDate: event.target.value })} onKeyDown={this.handleKeyPress} type="Başlangıç Tarihi" value={this.state.filterAppointmentStartDate} />
              <strong>Bitiş Tarihi</strong>
              <input className="form-control form-input" onChange={event => this.setState({ filterAppointmentEndDate: event.target.value })} onKeyDown={this.handleKeyPress} type="Bitiş Tarihi" value={this.state.filterAppointmentEndDate} />
              <div className="d-flex justify-content-end">
                <button className="btn btn-orange mt-3 mr-2" onClick={this.resetOnClick}><i className="fa fa-refresh"></i> Sıfırla</button>
                <button className="btn btn-orange mt-3" onClick={this.filterOnClick}><i className="fa fa-filter"></i> Filtrele</button>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    );
  }
}

export default function TowingRequests(props) {
  return (
    <TowingRequestsModule navigate={useNavigate()} searchParams={useSearchParams()[0]}></TowingRequestsModule>
  );
}
