import React from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import swal from 'sweetalert';

import {getCookie} from '../../inc/cookies';
import config from '../../inc/config';
import {isoToDateTime} from '../../inc/helper';

class MyWorkItemModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      
      filterDeliveryStartDate: '',
      filterDeliveryEndDate: '',
      filterReturnStartDate: '',
      filterReturnEndDate: '',

      myWorkItems: []
    };
  }

  componentDidMount() {
    this.getMyWorkItems();
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
  }

  getMyWorkItems() {
    let sessionHash = getCookie('sessionHash');

    this.setState({isLoading: true});

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'myWorkItems/tr?filterActive=1', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({isLoading: false, myWorkItems: responseData.myWorkItems});
        }
        break;
        default: {
          this.setState({isLoading: false});

          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          }).then(() => this.props.navigate('/home'));
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  filteredMyWorkItems(query) {
    let sessionHash = getCookie('sessionHash');

    this.setState({isLoading: true});

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'myWorkItems/tr?' + query, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({isLoading: false, myWorkItems: responseData.myWorkItems});
        }
        break;
        default: {
          this.setState({isLoading: false});

          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  filterOnClick = (event) => {
    this.filteredMyWorkItems('filterDeliveryStartDate=' + this.state.filterDeliveryStartDate + '&filterDeliveryEndDate=' + this.state.filterDeliveryEndDate + '&filterReturnStartDate=' + this.state.filterReturnStartDate + '&filterReturnEndDate=' + this.state.filterReturnEndDate);
  }

  resetOnClick = (event) => {
    this.setState({
      filterDeliveryStartDate: '',
      filterDeliveryEndDate: '',
      filterReturnStartDate: '',
      filterReturnEndDate: ''
    });
    
    this.getMyWorkItems();
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.filterOnClick();
    }
  }


  render() {
    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <>
        <div className="row">
          <div className="col-xl-9">
            <div className="ibox">
              <div className="ibox-title text-darkblue d-flex justify-content-between">
                <h5>Zimmetlerim</h5>
              </div>
              <div className="table-responsive overflow-x-auto">
                <table className="table table-striped table-bordered">
                  <thead>
                    <tr className="text-center text-darkblue">
                      <th>Ürün Adı</th>
                      <th>Ürün Açıklaması</th>
                      <th>Teslim Alma Tarihi</th>
                      <th>Teslim Alma Notu</th>
                      <th>Teslim Edilme Tarihi</th>
                      <th>Teslim Edilme Notu</th>
                      <th>Durum</th>
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {this.state.myWorkItems.map(myWorkItemInfo =>
                      <tr key={myWorkItemInfo.id}>
                        <td className="align-middle">{myWorkItemInfo.workItemName}</td>
                        <td className="align-middle">{myWorkItemInfo.workItemDescription}</td>
                        <td className="align-middle">{isoToDateTime(myWorkItemInfo.deliveryDate)}</td>
                        <td className="align-middle">{myWorkItemInfo.deliveryNote ? myWorkItemInfo.deliveryNote : '-'}</td>
                        <td className="align-middle">{myWorkItemInfo.returnDate ? isoToDateTime(myWorkItemInfo.returnDate) : "-"}</td>
                        <td className="align-middle">{myWorkItemInfo.returnNote ? myWorkItemInfo.returnNote : '-'}</td>
                        <td className="align-middle">{myWorkItemInfo.active === 1 ? 'Açık' : 'Kapalı'}</td>
                      </tr>
                    )}
                    {this.state.myWorkItems.length === 0 ?
                      <tr>
                        <td className="align-middle p-3" colSpan="7">Zimmet Bulunamadı !</td>
                      </tr>
                    : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="col-xl-3 order-first order-xl-last d-xl-none" id="accordion">
            <div className="ibox">
              <div className="ibox-content text-darkblue p-0">
                <h4 className="collapse-title">
                  <a className="btn btn-link text-darkblue font-bold d-flex justify-content-between" data-toggle="collapse" href="#filterCollapse" title="Filtrele">Filtrele</a>
                </h4>
              </div>
              <div className="collapse" data-parent="#accordion" id="filterCollapse">
                <div className="ibox-content text-darkblue">
                  <strong>Teslim Alma Başlangıç Tarihi</strong>
                  <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterDeliveryStartDate: event.target.value})} onKeyDown={this.handleKeyPress} type="date" value={this.state.filterDeliveryStartDate} />
                  <strong>Teslim Alma Bitiş Tarihi</strong>
                  <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterDeliveryEndDate: event.target.value})} onKeyDown={this.handleKeyPress} type="date" value={this.state.filterDeliveryEndDate} />
                  <strong>Teslim Edilme Başlangıç Tarihi</strong>
                  <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterReturnStartDate: event.target.value})} onKeyDown={this.handleKeyPress} type="date" value={this.state.filterReturnStartDate} />
                  <strong>Teslim Edilme Bitiş Tarihi</strong>
                  <input className="form-control form-input" onChange={event => this.setState({filterReturnEndDate: event.target.value})} onKeyDown={this.handleKeyPress} type="date" value={this.state.filterReturnEndDate} />
                  <div className="d-flex justify-content-end">
                    <button className="btn btn-orange mt-3 mr-2" onClick={this.resetOnClick}><i className="fa fa-refresh"></i> Sıfırla</button>
                    <button className="btn btn-orange mt-3" onClick={this.filterOnClick}><i className="fa fa-filter"></i> Filtrele</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 order-first order-xl-last d-none d-xl-block">
            <div className="ibox">
              <div className="ibox-title text-darkblue">
                <h5><i className="fa fa-filter"></i> Filtrele</h5>
              </div>
              <div className="ibox-content text-darkblue">
                <strong>Teslim Alma Başlangıç Tarihi</strong>
                <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterDeliveryStartDate: event.target.value})} onKeyDown={this.handleKeyPress} type="date" value={this.state.filterDeliveryStartDate} />
                <strong>Teslim Alma Bitiş Tarihi</strong>
                <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterDeliveryEndDate: event.target.value})} onKeyDown={this.handleKeyPress} type="date" value={this.state.filterDeliveryEndDate} />
                <strong>Teslim Edilme Başlangıç Tarihi</strong>
                <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterReturnStartDate: event.target.value})} onKeyDown={this.handleKeyPress} type="date" value={this.state.filterReturnStartDate} />
                <strong>Teslim Edilme Bitiş Tarihi</strong>
                <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterReturnEndDate: event.target.value})} onKeyDown={this.handleKeyPress} type="date" value={this.state.filterReturnEndDate} />
                <div className="d-flex justify-content-end">
                  <button className="btn btn-orange mt-3 mr-2" onClick={this.resetOnClick}><i className="fa fa-refresh"></i> Sıfırla</button>
                  <button className="btn btn-orange mt-3" onClick={this.filterOnClick}><i className="fa fa-filter"></i> Filtrele</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
  }
}

export default function MyWorkItems() {
  return (
    <MyWorkItemModule navigate={useNavigate()} searchParams={useSearchParams()[0]} />
  )
}