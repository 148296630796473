import React from 'react';
import {Link, useNavigate} from 'react-router-dom';

import swal from 'sweetalert';

import {getCookie} from '../../../inc/cookies';
import config from '../../../inc/config';
import {isoToDateTime, priceFormat} from '../../../inc/helper';

class ProductModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,

      categories: [],
      products: [],
      productTypes: [],

      filterActive: '',
      filterCategoryId: '',
      filterMinPrice: '',
      filterMaxPrice: '',
      filterName: '',
      filterProductTypeId: ''
    };
  }

  componentDidMount() {
    this.getProducts();
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
  }

  filteredProducts(query) {
    let sessionHash = getCookie('sessionHash');

    this.setState({isLoading: true});

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'products/tr?' + query, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({isLoading: false, products: responseData.products});
        }
        break;
        default: {
          this.setState({isLoading: false});

          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  getProducts() {
    let sessionHash = getCookie('sessionHash');

    this.setState({isLoading: true});

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    let errorMessages = [];
    let remaining = 2;
    let complete = () => {
      remaining--;
      if (remaining == 0) {
        this.setState({isLoading: false});
      }
    }

    fetch(config.restApi + 'products/tr?limit=40', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({products: responseData.products});
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));

    fetch(config.restApi + 'categories/tr/0?filterActive=1', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({categories: responseData.categories});
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));

    fetch(config.restApi + 'productTypes?filterActive=1', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({productTypes: responseData.productTypes});
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));
  }

  filterOnClick = (event) => {
    this.filteredProducts('filterActive=' + this.state.filterActive + '&filterCategoryId=' + this.state.filterCategoryId + '&filterMinPrice=' + this.state.filterMinPrice + '&filterMaxPrice=' + this.state.filterMaxPrice + '&filterName=' + this.state.filterName + '&filterProductTypeId=' + this.state.filterProductTypeId);
  }

  resetOnClick = (event) => {
    this.setState({
      filterActive: '',
      filterCategoryId: '',
      filterMinPrice: '',
      filterMaxPrice: '',
      filterName: '',
      filterProductTypeId: ''
    });

    this.getProducts();
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.filterOnClick();
    }
  }

  render() {
    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <>
        <div className="row">
          <div className="col-xl-9">
            <div className="ibox">
              <div className="ibox-title text-darkblue d-flex justify-content-between pr-2 pl-0">
                <div className="col-7 col-xl-8"><h5>Ürün Listesi</h5></div>
                <Link className="btn btn-orange" title="Ürün Ekle" to="/productAdd"><i className="fa fa-plus"></i> Ürün Ekle</Link>
              </div>
              <div className="table-responsive overflow-x-auto">
                <table className="table table-striped table-bordered">
                  <thead>
                    <tr className="text-center text-darkblue">
                      <th>Ürün Adı</th>
                      <th>Tanımı</th>
                      <th>Miktarı</th>
                      <th>Fiyat Bilgisi</th>
                      <th>Ürün Tipi</th>
                      <th>Kategori</th>
                      <th>Eklenme Tarihi</th>
                      <th>Durum</th>
                      <th>İşlem</th>
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {this.state.products.map((productInfo) =>
                      <tr key={productInfo.id}>
                        <td className="align-middle">{productInfo.name}</td>
                        <td className="align-middle">{productInfo.description}</td>
                        <td className="align-middle">{productInfo.quantity}</td>
                        <td className="align-middle">{priceFormat(productInfo.price)} ₺<br /></td>
                        <td className="align-middle">{productInfo.productTypeName}</td>
                        <td className="align-middle">{productInfo.categoryNames || 'Genel'}</td>
                        <td className="align-middle">{isoToDateTime(productInfo.addedDate)}</td>
                        <td className="align-middle">{productInfo.active == 1 ? 'Açık' : 'Kapalı'}</td>
                        <td className="align-middle">
                          <Link className="btn btn-orange text-center" to={"/productEdit?id=" + productInfo.id}><i className="fa fa-pencil text-white action-icon"></i></Link>
                        </td>
                      </tr>
                    )}
                    {this.state.products.length == 0 ?
                      <tr>
                        <td className="align-middle p-3" colSpan="9">Ürün Bulunamadı !</td>
                      </tr>
                    : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="col-xl-3 order-first order-xl-last d-xl-none" id="accordion">
            <div className="ibox">
              <div className="ibox-content text-darkblue p-0">
                <h4 className="collapse-title">
                  <a className="btn btn-link text-darkblue font-bold d-flex justify-content-between" data-toggle="collapse" href="#filterCollapse" title="Filtrele">Filtrele</a>
                </h4>
              </div>
              <div className="collapse" data-parent="#accordion" id="filterCollapse">
                <div className="ibox-content text-darkblue">
                  <strong>Ürün Adı</strong>
                  <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterName: event.target.value})} onKeyDown={this.handleKeyPress} placeholder="Ürün Adı" type="text" value={this.state.filterName} />
                  <strong>Minimum Fiyat</strong>
                  <input className="form-control form-input mb-2 mt-1" min="0" onChange={event => this.setState({filterMinPrice: event.target.value})} onKeyDown={this.handleKeyPress} placeholder="Fiyat" type="number" value={this.state.filterMinPrice}/>
                  <strong>Maksimum Fiyat</strong>
                  <input className="form-control form-input mb-2 mt-1" min="0" onChange={event => this.setState({filterMaxPrice: event.target.value})} onKeyDown={this.handleKeyPress} placeholder="Fiyat" type="number" value={this.state.filterMaxPrice}/>
                  <strong>Kategori Adı</strong>
                  <select className="form-control form-input mb-2 mt-1" onChange={(event) => this.setState({filterCategoryId: event.target.value})} onKeyDown={this.handleKeyPress} value={this.state.filterCategoryId}>
                    <option value="">Seçiniz</option>
                    {this.state.categories.map((categoryInfo) => <option key={categoryInfo.id} value={categoryInfo.id}>{categoryInfo.name}</option>)}
                  </select>
                  <strong>Ürün Tipi</strong>
                  <select className="form-control form-input mb-2 mt-1" onChange={(event) => this.setState({filterProductTypeId: event.target.value})} onKeyDown={this.handleKeyPress} value={this.state.filterProductTypeId}>
                    <option value="">Seçiniz</option>
                    {this.state.productTypes.map((productTypeInfo) => <option key={productTypeInfo.id} value={productTypeInfo.id}>{productTypeInfo.name}</option>)}
                  </select>
                  <strong>Durum</strong>
                  <select className="form-control form-input mb-2 mt-1" onChange={(event) => this.setState({filterActive: event.target.value})} onKeyDown={this.handleKeyPress} value={this.state.filterActive}>
                    <option value="">Seçiniz</option>
                    <option value="1">Açık</option>
                    <option value="0">Kapalı</option>
                  </select>
                  <div className="d-flex justify-content-end">
                    <button className="btn btn-orange mt-3 mr-2" onClick={this.resetOnClick}><i className="fa fa-refresh"></i> Sıfırla</button>
                    <button className="btn btn-orange mt-3" onClick={this.filterOnClick}><i className="fa fa-filter"></i> Filtrele</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 order-first order-xl-last d-none d-xl-block">
            <div className="ibox">
              <div className="ibox-title text-darkblue">
                <h5><i className="fa fa-filter"></i> Filtrele</h5>
              </div>
              <div className="ibox-content text-darkblue">
                <strong>Ürün Adı</strong>
                <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterName: event.target.value})} onKeyDown={this.handleKeyPress} placeholder="Ürün Adı" type="text" value={this.state.filterName} />
                <strong>Minimum Fiyat</strong>
                <input className="form-control form-input mb-2 mt-1" min="0" onChange={event => this.setState({filterMinPrice: event.target.value})} onKeyDown={this.handleKeyPress} placeholder="Minimum Fiyat" type="number" value={this.state.filterMinPrice}/>
                <strong>Maksimum Fiyat</strong>
                <input className="form-control form-input mb-2 mt-1" min="0" onChange={event => this.setState({filterMaxPrice: event.target.value})} onKeyDown={this.handleKeyPress} placeholder="Maksimum Fiyat" type="number" value={this.state.filterMaxPrice}/>
                <strong>Kategori Adı</strong>
                <select className="form-control form-input mb-2 mt-1" onChange={(event) => this.setState({filterCategoryId: event.target.value})} onKeyDown={this.handleKeyPress} value={this.state.filterCategoryId}>
                  <option value="">Seçiniz</option>
                  {this.state.categories.map((categoryInfo) => <option key={categoryInfo.id} value={categoryInfo.id}>{categoryInfo.name}</option>)}
                </select>
                <strong>Ürün Tipi</strong>
                <select className="form-control form-input mb-2 mt-1" onChange={(event) => this.setState({filterProductTypeId: event.target.value})} onKeyDown={this.handleKeyPress} value={this.state.filterProductTypeId}>
                  <option value="">Seçiniz</option>
                  {this.state.productTypes.map((productTypeInfo) => <option key={productTypeInfo.id} value={productTypeInfo.id}>{productTypeInfo.name}</option>)}
                </select>
                <strong>Durum</strong>
                <select className="form-control form-input mb-2 mt-1" onChange={(event) => this.setState({filterActive: event.target.value})} onKeyDown={this.handleKeyPress} value={this.state.filterActive}>
                  <option value="">Seçiniz</option>
                  <option value="1">Açık</option>
                  <option value="0">Kapalı</option>
                </select>
                <div className="d-flex justify-content-end">
                  <button className="btn btn-orange mt-3 mr-2" onClick={this.resetOnClick}><i className="fa fa-refresh"></i> Sıfırla</button>
                  <button className="btn btn-orange mt-3" onClick={this.filterOnClick}><i className="fa fa-filter"></i> Filtrele</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
  }
}

export default function Products() {
  return (
    <ProductModule navigate={useNavigate()} />
  )
}