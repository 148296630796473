import React from 'react';
import Modal from 'react-modal';

import swal from 'sweetalert';

import {getCookie} from '../inc/cookies';
import config from '../inc/config';
import {patternNumber} from '../inc/regexValidate';

export default class InvoiceModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      /* isLoading: true, */
    };
  }

  componentDidMount() {
  }
  
  render() {
    
    const cleanedCustomFields = this.props.customFields.replace(/[^\x20-\x7E\u011f\u011e\u00fc\u00dc\u015fŞıI\u00f6Ö\u00fcÜ\u00e7Ç]/g, '').replace(/\\/g, '/');
    const customFields = JSON.parse(cleanedCustomFields);
    return (
      <div className="modal inmodal">
        <div className="modal-dialog">
          <div className="modal-content animated fadeIn">
          <Modal
            isOpen={this.props.isOpen}
            onRequestClose={this.props.onRequestClose}
            style={{
              content : {
                position : 'null',
                top : 'null',
                left : 'null',
                right : 'null',
                bottom : 'null',
                border : 'none',
                background : '#fff',
                padding : 'null',
                maxWidth : '50%',
                minWidth : '350px'
              },
              overlay : {
                position : 'fixed',
                top : 0,
                left : 0,
                right : 0,
                bottom : 0,
                display : 'flex',
                alignItems : 'center',
                justifyContent : 'center',
                backgroundColor : 'rgba(0,0,0,00.1)',
                zIndex:2500
              },
            }}
          >
            {this.state.isLoading ?
              <div className="ibox-content sk-loading position-static">
                <div className="sk-spinner sk-spinner-fading-circle">
                  <div className="sk-circle1 sk-circle"></div>
                  <div className="sk-circle2 sk-circle"></div>
                  <div className="sk-circle3 sk-circle"></div>
                  <div className="sk-circle4 sk-circle"></div>
                  <div className="sk-circle5 sk-circle"></div>
                  <div className="sk-circle6 sk-circle"></div>
                  <div className="sk-circle7 sk-circle"></div>
                  <div className="sk-circle8 sk-circle"></div>
                  <div className="sk-circle9 sk-circle"></div>
                  <div className="sk-circle10 sk-circle"></div>
                  <div className="sk-circle11 sk-circle"></div>
                  <div className="sk-circle12 sk-circle"></div>
                </div>
              </div>
            :
            <>
              <div className="modal-header d-block text-center text-darkblue">
                <button className="close" onClick={this.props.onRequestClose}><span>×</span></button>
                <h4 className="modal-title">{this.props.modalTitle}</h4>
                {/* <h5 className="font-normal">{this.props.modalDescription}</h5> */}
              </div>
              {customFields !== null ?
              <div className="modal-body text-darkblue">
                <span className='my-2'><b>Hesap İban No:</b> {customFields["21"] ? customFields["21"] : '-'}<br/></span>
                <span className='my-2'><b>Hesap Sahibi:</b> {customFields["22"] ? customFields["22"] : '-'}<br/></span>
                <span className='my-2'><b>Banka Adı:</b> {customFields["23"] ? customFields["23"] : '-'}<br/></span>
                <span className='my-2'><b>MYK Sicil No:</b> {customFields["24"] ? customFields["24"] : '-'}<br/></span>
                <span className='my-2'><b>Yetki Belge No:</b> {customFields["25"] ? customFields["25"] : '-'}<br/></span>
              </div>:
              <div className='d-flex justify-content-between my-4'>
                <span className='m-auto'><b>Transfer verisi bulunamadı!</b></span>
              </div>
              }
              <div className="modal-footer">
                <button className="btn btn-orange" onClick={this.props.onRequestClose}>Kapat</button>
              </div>
            </>
            }
          </Modal>
          </div>
        </div>
      </div>
    )
  }
}