import React from 'react';
import {Link, useNavigate} from 'react-router-dom';
import swal from 'sweetalert';
import ReactPaginate from 'react-paginate';

import BannerAddModal from '../../component/banners/BannerAddModal';

import {getCookie} from '../../inc/cookies';
import config from '../../inc/config';
import {isoToDateTime} from '../../inc/helper';

class BannerModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isOpenBannerAddModal: false,

      filterBannerLocationName: '',
      filterActive: '',
      filterPeriodStart: '',
      filterPeriodEnd: '',

      banners: [],
      bannerLocations: [],

      pageCount: 0,
      resultCount: 0,
      currentPage: 1,
      limit: 10
    };
    
    this.onCompleted = this.onCompleted.bind(this);
    this.filterOnClick = this.filterOnClick.bind(this);
    this.resetOnClick = this.resetOnClick.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  componentDidMount() {
    this.getBanners(this.state.currentPage);
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
  }

  filteredBanners(query) {
    let sessionHash = getCookie('sessionHash');

    this.setState({isLoading: true});

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'banners?' + query, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({isLoading: false, banners: responseData.banners});
        }
        break;
        default: {
          this.setState({isLoading: false});

          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  getBanners(currentPage) {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    let errorMessages = [];
    let remaining = 2;
    let complete = () => {
      remaining--;
      if (remaining === 0) {
        this.setState({isLoading: false});
      }
    }

    const startIndex = 0;
    const endIndex = startIndex + this.state.limit;

    fetch(config.restApi + `banners/tr?limit=${this.state.limit}&page=${currentPage}`, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          const currentBanners = responseData.banners.slice(startIndex, endIndex);
          this.setState({banners: currentBanners, pageCount: responseData.pageCount, resultCount: responseData.resultCount, currentPage: currentPage});
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));

    fetch(config.restApi + 'bannerLocations', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({bannerLocations: responseData.bannerLocations});
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));
  }
  
  onCompleted() {
    this.getBanners();
    this.setState({isOpenBannerAddModal: false});
  }
  
  handlePageClick(page) {
    this.setState({currentPage: page.selected + 1}, () => this.getBanners(this.state.currentPage))
  }

  filterOnClick(event) {
    this.filteredBanners('filterBannerLocationName=' + this.state.filterBannerLocationName + '&filterActive=' + this.state.filterActive + '&filterPeriodStart=' + this.state.filterPeriodStart + '&filterPeriodEnd=' + this.state.filterPeriodEnd);
  }

  resetOnClick(event) {
    this.setState({
      filterBannerLocationName: '',
      filterActive: '',
      filterPeriodStart: '',
      filterPeriodEnd: ''
    });
    this.getBanners();
  }

  handleKeyPress(event) {
    if (event.key === 'Enter') {
      this.filterOnClick();
    }
  }

  render() {
    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <>
        <div className="row">
          <div className="col">
            <div className="ibox">
              <div className="ibox-title text-darkblue d-flex justify-content-between pr-2">
                <div className="col-7 col-xl-8"><h5>Reklam Listesi</h5></div>
                <button className="btn btn-orange" onClick={() => this.setState({isOpenBannerAddModal: true})} title="Ekle"><i className="fa fa-plus"></i> Reklam Ekle</button>
              </div>
              <div className="table-responsive overflow-x-auto">
                <table className="table table-striped table-bordered">
                  <thead>
                    <tr className="text-center text-darkblue">
                      <th>Reklam Alanı Adı</th>
                      <th>Web Adresi</th>
                      <th>Görsel Kaynağı</th>
                      <th>Başlangıç Dönemi Tarihi</th>
                      <th>Bitiş Dönemi Tarihi</th>
                      <th>Durum</th>
                      <th>İşlem</th>
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {this.state.banners.map(bannerInfo =>
                      <tr key={bannerInfo.id}>
                        <td className="align-middle">{bannerInfo.bannerLocationName}</td>
                        <td className="align-middle">{bannerInfo.url}</td>
                        <td className="align-middle">{bannerInfo.src}</td>
                        <td className="align-middle">{isoToDateTime(bannerInfo.periodStart)}</td>
                        <td className="align-middle">{isoToDateTime(bannerInfo.periodEnd)}</td>
                        <td className="align-middle">{bannerInfo.active === 1 ? 'Açık' : 'Kapalı'}</td>
                        <td className="align-middle">
                          <Link to={"/bannerEdit?id=" + bannerInfo.id}>
                            <button className="btn btn-orange text-center"><i className="fa fa-pencil text-white action-icon"></i></button>
                          </Link>
                        </td>
                      </tr>
                    )}
                    {this.state.banners.length === 0 ?
                      <tr>
                        <td className="align-middle p-3" colSpan="7">Reklam Bulunamadı !</td>
                      </tr>
                    : null}
                  </tbody>
                </table>
                {
                  this.state.banners.length !== 0 &&
                  <ReactPaginate
                  previousLabel={"Geri"}
                  nextLabel={"İleri"}
                  breakLabel={"..."}  
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={1}
                  onPageChange={page => this.handlePageClick(page)}
                  pageRangeDisplayed={2}
                  containerClassName={'pagination'}
                  activeClassName={'activePage'}
                  />
                }
              </div>
            </div>
          </div>
        </div>
        <BannerAddModal isOpen={this.state.isOpenBannerAddModal} modalTitle="Reklam Ekle" modalDescription="Reklam eklemek için aşağıdaki alanları doldurunuz !" onCompleted={this.onCompleted} onRequestClose={() => this.setState({isOpenBannerAddModal: false})} />
      </>
  }
}

export default function Banners() {
  return (
    <BannerModule navigate={useNavigate()} />
  )
}