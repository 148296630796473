import React from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import swal from 'sweetalert';

import AddressModal from '../../../component/AddressModal';
import PhoneModal from '../../../component/PhoneModal';

import config from '../../../inc/config';
import {getCookie} from '../../../inc/cookies';

import Select from 'react-select';

class CrmMemberAddModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isOpenAddressModal: false,
      isOpenPhoneModal: false,

      crmMember: {
        crmMemberTypeId: 2,
        memberId: 0,
        companyName: '',
        companyCategories: [],
        firstname: '',
        lastname: '',
        email: '',
        url: '',
        totalAdvert: 0,
        description: '',
        crmMemberAddresses: [],
        crmMemberPhones: []
      },

      crmMemberTypes: [],
      categories: []
    };

    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  componentDidMount() {
    this.getCrmMemberTypes();
    window.scrollTo(0, 0);
  }

  componentWillMount() {
  }

  getCrmMemberTypes() {
    let sessionHash = getCookie('sessionHash');

    this.setState({isLoading: true});

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    let errorMessages = [];
    let remaining = 2;
    let complete = () => {
      remaining--;
      if (remaining == 0) {
        if (errorMessages.length > 0) {
          swal({dangerMode: true, icon: 'warning', title: 'Hay Aksi', text: errorMessages[0], buttons: 'Tamam'}).then(this.props.navigate('/home'));
        }else{
          this.setState({isLoading: false});
        }
      }
    }

    fetch(config.restApi + 'crmMemberTypes?filterActive=1', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({isLoading: false, crmMemberTypes: responseData.crmMemberTypes});
        }
        break;
        case 401:
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          }).then(this.props.navigate('/home'))
        break;
        default: {
          this.setState({isLoading: false});

          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
      complete();
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));

    fetch(config.restApi + 'categories/tr/' + 0, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({isLoading: false, categories: responseData.categories});
        }
        break;
        case 401:
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          }).then(this.props.navigate('/home'))
        break;
        default: {
          this.setState({isLoading: false});

          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
      complete();
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  saveCrmMemberAddressOnClick(addressInfo) {
    let crmMemberAddresses = this.state.crmMember.crmMemberAddresses;
    crmMemberAddresses.push(addressInfo);

    this.setState({crmMember: {...this.state.crmMember, crmMemberAddresses: crmMemberAddresses}, editAddress: null, isOpenAddressModal: false});
  }

  updateCrmMemberAddressOnClick(addressInfo) {
    let crmMemberAddresses = this.state.crmMember.crmMemberAddresses;

    let index = crmMemberAddresses.findIndex(item => item.id === addressInfo.id);

    crmMemberAddresses[index] = addressInfo;

    this.setState({crmMember: {...this.state.crmMember, crmMemberAddresses: crmMemberAddresses}, editAddress: null, isOpenAddressModal: false});
  }

  deleteCrmMemberAddressOnClick(index) {
    swal({
      dangerMode: true,
      icon: 'warning',
      title: 'Onayla',
      text: 'Adresi silmek istediğinize emin misiniz?',
      buttons: {confirm: 'Tamam', cancel: 'Vazgeç'}
    }).then(value => {
      if (value) {
        let crmMemberAddresses = this.state.crmMember.crmMemberAddresses;
        crmMemberAddresses.splice(index, 1);

        this.setState({crmMember: {...this.state.crmMember, crmMemberAddresses: crmMemberAddresses}, editAddress: null});

        swal({
          dangerMode: true,
          icon: 'success',
          title: 'Tamamlandı',
          text: 'Adres başarıyla silindi.',
          buttons: 'Tamam'
        });
      }
    });
  }

  saveCrmMemberPhoneOnClick(phoneInfo) {
    let crmMemberPhones = this.state.crmMember.crmMemberPhones;
    crmMemberPhones.push(phoneInfo);

    this.setState({crmMember: {...this.state.crmMember, crmMemberPhones: crmMemberPhones}, editPhone: null, isOpenPhoneModal: false});
  }

  updateCrmMemberPhoneOnClick(phoneInfo) {
    let crmMemberPhones = this.state.crmMember.crmMemberPhones;

    let index = crmMemberPhones.findIndex(item => item.id === phoneInfo.id);

    crmMemberPhones[index] = phoneInfo;

    this.setState({crmMember: {...this.state.crmMember, crmMemberPhones: crmMemberPhones}, editPhone: null, isOpenPhoneModal: false});
  }

  deleteCrmMemberPhoneOnClick(index) {
    swal({
      dangerMode: true,
      icon: 'warning',
      title: 'Onayla',
      text: 'Telefonu silmek istediğinize emin misiniz?',
      buttons: {confirm: 'Tamam', cancel: 'Vazgeç'}
    }).then(value => {
      if (value) {
        let crmMemberPhones = this.state.crmMember.crmMemberPhones;
        crmMemberPhones.splice(index, 1);

        this.setState({crmMember: {...this.state.crmMember, crmMemberPhones: crmMemberPhones}, editPhone: null});

        swal({
          dangerMode: true,
          icon: 'success',
          title: 'Tamamlandı',
          text: 'Telefon başarıyla silindi.',
          buttons: 'Tamam'
        });
      }
    });
  }

  handleKeyPress(event) {
    if(event.key === 'Enter') {
      this.saveCrmMemberOnClick();
    }
  }

  saveCrmMemberOnClick = (event) => {
    let sessionHash = getCookie('sessionHash');

    this.setState({isLoading: true});

    const requestOptions = {
      method: 'POST',
      headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
      body: JSON.stringify(this.state.crmMember)
    };

    fetch(config.restApi + 'crmMember/tr', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({isLoading: false});
          swal({
            dangerMode: true,
            icon: 'success',
            title: 'Tamamlandı',
            text: 'Potansiyel üye başarıyla eklendi.',
            buttons: 'Tamam'
          }).then(() => this.props.navigate('/crmMemberEdit?id=' + responseData.crmMemberId));
        }
        break;
        default: {
          this.setState({isLoading: false});

          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  phoneFormat(phone) {
    let parsedValue = /^(\d{3})(\d{3})(\d{2})(\d{2})$/.exec(phone);
    return parsedValue ? '(' + parsedValue[1] + ') ' + parsedValue[2] + ' ' + parsedValue[3] + ' ' + parsedValue[4] : '-';
  }

  companyCategoryType = (selectedOptions) => {
    const selectedValues = selectedOptions.map(option => option.value);

    this.setState(prevState => ({crmMember: {...prevState.crmMember, companyCategories: selectedValues}}));
  }
  
  render() {

    const companyCategories = this.state.categories.map((categoryInfo) => ({
      value: categoryInfo.id,
      label: `${categoryInfo.name}`,
      search: categoryInfo.name
    }))

    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <div className="row custom-container">
        <div className="col">
          <div className="ibox">
            <div className="ibox-title text-darkblue d-flex justify-content-between pr-2">
              <h3>Potansiyel Üye Ekle</h3>
              <button className="btn bg-orange text-white" onClick={() => this.saveCrmMemberOnClick()}><i className="fa fa-save"></i></button>
            </div>
            <div className="row">
              <div className="col">
                <div className="ibox">
                  <div className="ibox-content text-darkblue">
                    <div className="row">
                      <div className="col-md-6">
                        <h5><strong>Üyelik Tipi<strong className="text-danger ml-1">*</strong></strong></h5>
                        <select className="form-control form-control-sm form-input mb-1" onChange={(event) => this.setState({crmMember: {...this.state.crmMember, crmMemberTypeId: parseInt(event.target.value) || ''}})} onKeyDown={this.handleKeyPress} value={this.state.crmMember.crmMemberTypeId}>
                          {this.state.crmMemberTypes.map(crmMemberTypeInfo => <option key={crmMemberTypeInfo.id} value={crmMemberTypeInfo.id}>{crmMemberTypeInfo.name}</option>)}
                        </select>
                      </div>
                      {this.state.crmMember.crmMemberTypeId === 2 ?
                      <div className="col-md-6">
                        <h5><strong>Firma Adı<strong className="text-danger ml-1">*</strong></strong></h5>
                        <input className="form-control form-control-sm form-input mb-1" onChange={event => this.setState({crmMember: {...this.state.crmMember, companyName: event.target.value.trimStart()}})} onKeyDown={this.handleKeyPress} placeholder="Firma Adı" type="text" value={this.state.crmMember.companyName}/>
                      </div>: ''}
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <h5><strong>Müşteri Adı<strong className="text-danger ml-1">*</strong></strong></h5>
                        <input className="form-control form-control-sm form-input mb-1" onChange={event => this.setState({crmMember: {...this.state.crmMember, firstname: event.target.value.replace(/[^a-zA-ZçÇğĞıİöÖşŞüÜ ]/g, '')}})} onKeyDown={this.handleKeyPress} placeholder="Müşteri Adı" type="text" value={this.state.crmMember.firstname} />
                      </div>
                      <div className="col-md-6">
                        <h5><strong>Müşteri Soyadı<strong className="text-danger ml-1">*</strong></strong></h5>
                        <input className="form-control form-control-sm form-input mb-1" onChange={event => this.setState({crmMember: {...this.state.crmMember, lastname: event.target.value.replace(/[^a-zA-ZçÇğĞıİöÖşŞüÜ ]/g, '')}})} onKeyDown={this.handleKeyPress} placeholder="Müşteri Soyadı" type="text" value={this.state.crmMember.lastname} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <h5><strong>Faaliyet Alanı</strong></h5>
                        <Select
                          isMulti
                          placeholder="Faaliyet Alanı"
                          name="colors"
                          options={companyCategories}
                          onChange={this.companyCategoryType}
                          onKeyDown={this.handleKeyPress}
                          value={companyCategories.find((categoryInfo) => categoryInfo.value === this.state.crmMember.companyCategories)}
                          filterOption={(option, searchText) => option.data.search && option.data.search.toLocaleLowerCase('tr-TR').includes(searchText.toLocaleLowerCase('tr-TR'))}
                          className="basic-multi-select"
                          classNamePrefix="select"
                        />
                      </div>
                    </div>
                    <br></br>
                    <h4>İletişim Bilgileri</h4>
                    <div className="hr-line-dashed"></div>
                    <div className="row">
                      <div className="col-md-6">
                        <h5><strong>E-posta</strong></h5>
                        <input className="form-control form-input mb-1" onChange={event => this.setState({crmMember: {...this.state.crmMember, email: event.target.value.trimStart()}})} onKeyDown={this.handleKeyPress} placeholder="E-posta" type="email" value={this.state.crmMember.email} />
                      </div>
                      <div className="col-md-6">
                        <h5><strong>Web Sitesi</strong></h5>
                        <input className="form-control form-input mb-1" onChange={event => this.setState({crmMember: {...this.state.crmMember, url: event.target.value.trimStart()}})} onKeyDown={this.handleKeyPress} placeholder="Web Sitesi" type="text" value={this.state.crmMember.url} />
                      </div>
                    </div>
                    <br></br>
                    <div className="d-flex justify-content-between">
                      <h4>Adres Bilgileri</h4>
                      <button className="btn btn-orange" onClick={() => this.setState({editAddress: null, isOpenAddressModal: true})} title="Adres Ekle"><i className="fa fa-plus"></i></button>
                    </div>
                    <div className="hr-line-dashed"></div>
                    <div className="overflow-auto">
                      <table className="table table-striped table-bordered">
                      <thead>
                        <tr className="text-center text-darkblue">
                          <th>İl</th>
                          <th>İlçe</th>
                          <th>Semt</th>
                          <th>Mahalle</th>
                          <th>Açık Adres</th>
                          <th>Açıklama</th>
                          <th>Durum</th>
                          <th>İşlem</th>
                        </tr>
                      </thead>
                      <tbody className="text-center">
                        {this.state.crmMember.crmMemberAddresses.map((crmMemberAddressInfo, index) =>
                          <tr key={index}>
                            <td className="align-middle">{crmMemberAddressInfo.cityName}</td>
                            <td className="align-middle">{crmMemberAddressInfo.townName}</td>
                            <td className="align-middle">{crmMemberAddressInfo.districtName}</td>
                            <td className="align-middle">{crmMemberAddressInfo.quarterName}</td>
                            <td className="align-middle">{crmMemberAddressInfo.address}</td>
                            <td className="align-middle">{crmMemberAddressInfo.description}</td>
                            <td className="align-middle">{crmMemberAddressInfo.active === 1 ? 'Açık' : 'Kapalı'}</td>
                            <td className="align-middle" style={{minWidth:98.5}}>
                              <button className="btn btn-orange m-1" onClick={() => this.setState({editAddress: crmMemberAddressInfo, isOpenAddressModal: true})} title="Düzenle"><i className="fa fa-pencil"></i></button>
                              <button className="btn btn-darkblue" onClick={() => this.deleteCrmMemberAddressOnClick(index)} title="Sil"><i className="fa fa-trash"></i></button>
                            </td>
                          </tr>
                        )}
                        {this.state.crmMember.crmMemberAddresses.length === 0 ?
                          <tr>
                            <td className="align-middle p-3" colSpan="8">Adres Bulunamadı !</td>
                          </tr>
                        : null}
                      </tbody>
                      </table>
                      <AddressModal isOpen={this.state.isOpenAddressModal} modalTitle="Adres Ekle" modalDescription="Adres bilgisi eklemek için aşağıdaki bilgileri doldurunuz !" onRequestClose={() => this.setState({editAddress: null, isOpenAddressModal: false})} saveOnClick={addressInfo => this.saveCrmMemberAddressOnClick(addressInfo)} />
                      {this.state.editAddress ?
                        <AddressModal defaultValue={this.state.editAddress} isOpen={this.state.isOpenAddressModal} modalTitle="Adres Düzenle" modalDescription="Adres bilgisini düzenlemek için aşağıdaki bilgileri doldurunuz !" onRequestClose={() => this.setState({editAddress: null, isOpenAddressModal: false})} saveOnClick={addressInfo => this.updateCrmMemberAddressOnClick(addressInfo)} />
                      : null}
                    </div>
                    <br></br>
                    <div className="d-flex justify-content-between">
                      <h4>Telefon Bilgileri<strong className="text-danger ml-1">*</strong></h4>
                      <button className="btn btn-orange" onClick={() => this.setState({editPhone: null, isOpenPhoneModal: true})} title="Telefon Ekle"><i className="fa fa-plus"></i></button>
                    </div>
                    <div className="hr-line-dashed"></div>
                    <div className="overflow-auto">
                      <table className="table table-striped table-bordered">
                      <thead>
                        <tr className="text-center text-darkblue">
                          <th>Telefon Tipi</th>
                          <th>Telefon Numarası</th>
                          <th>Ülke</th>
                          <th>Varsayılan</th>
                          <th>Durum</th>
                          <th>İşlem</th>
                        </tr>
                      </thead>
                      <tbody className="text-center">
                        {this.state.crmMember.crmMemberPhones.map((crmMemberPhoneInfo, index) =>
                          <tr key={index}>
                            <td className="align-middle">{crmMemberPhoneInfo.phoneTypeName}</td>
                            <td className="align-middle">{this.phoneFormat(crmMemberPhoneInfo.phoneNumber)}</td>
                            <td className="align-middle">{crmMemberPhoneInfo.countryName}</td>
                            <td className="align-middle">-</td>
                            <td className="align-middle">{crmMemberPhoneInfo.active === 1 ? 'Açık' : 'Kapalı'}</td>
                            <td className="align-middle" style={{minWidth:98.5}}>
                              <button className="btn btn-orange m-1" onClick={() => this.setState({editPhone: crmMemberPhoneInfo, isOpenPhoneModal: true})} title="Düzenle"><i className="fa fa-pencil"></i></button>
                              <button className="btn btn-darkblue" onClick={() => this.deleteCrmMemberPhoneOnClick(index)} title="Sil"><i className="fa fa-trash"></i></button>
                            </td>
                          </tr>
                        )}
                        {this.state.crmMember.crmMemberPhones.length === 0 ?
                          <tr>
                            <td className="align-middle p-3" colSpan="6">Telefon Bulunamadı !</td>
                          </tr>
                        : null}
                      </tbody>
                      </table>
                      <PhoneModal isOpen={this.state.isOpenPhoneModal} modalTitle="Telefon Ekle" modalDescription="Telefon bilgisi eklemek için aşağıdaki alanları doldurunuz !" onRequestClose={() => this.setState({editPhone: null, isOpenPhoneModal: false})} saveOnClick={phoneInfo => this.saveCrmMemberPhoneOnClick(phoneInfo)} />
                      {this.state.editPhone ?
                        <PhoneModal defaultValue={this.state.editPhone} isOpen={this.state.isOpenPhoneModal} modalTitle="Telefon Düzenle" modalDescription="Telefon bilgisini düzenlemek için aşağıdaki alanları doldurunuz !" onRequestClose={() => this.setState({editPhone: null, isOpenPhoneModal: false})} saveOnClick={phoneInfo => this.updateCrmMemberPhoneOnClick(phoneInfo)} />
                      : null}
                    </div>
                    <br/>
                    <h4>Detay Bilgiler</h4>
                    <div className="hr-line-dashed"></div>
                    <div className="row">
                      <div className="col-md-4">
                        <h5><strong>Toplam İlan Sayısı</strong></h5>
                        <div className="input-group w-50">
                          <input className="form-control form-control-sm form-input" max="999" min="0" onChange={event => this.setState({crmMember: {...this.state.crmMember, totalAdvert: parseInt(event.target.value)}})} onKeyDown={this.handleKeyPress} type="number" value={this.state.crmMember.totalAdvert} />
                        </div>
                      </div>
                    </div>
                    <div className="hr-line-dashed"></div>
                    <div className="row">
                      <div className="col">
                        <h5><strong>Açıklama</strong></h5>
                        <textarea className="form-control form-input mt-1" onChange={event => this.setState({crmMember: {...this.state.crmMember, description: event.target.value.trimStart()}})} onKeyDown={this.handleKeyPress} placeholder="Açıklama giriniz" value={this.state.crmMember.description} />
                      </div>
                    </div>
                    <div className="text-row text-right mt-2">
                      <button className="btn bg-orange text-white" onClick={() => this.saveCrmMemberOnClick()}><i className="fa fa-save"></i> Kaydet</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  }
}

export default function CrmMemberAdd() {
  return (
    <CrmMemberAddModule navigate={useNavigate()} searchParams={useSearchParams()[0]} />
  )
}