import React from 'react';
import {useNavigate, Link} from 'react-router-dom';

import swal from 'sweetalert';

import AddedCategoryFieldModal from '../../../component/category/categoryField/AddedCategoryFieldModal';

import config from '../../../inc/config';
import {getCookie} from '../../../inc/cookies';

class CategoryFieldsModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,

      isOpenAddedCategoryFieldModal: false,

      fields: []
    };
  }

  componentDidMount() {
    this.getCategoryFields();
    window.scrollTo(0, 0);
  }

/*   getCategoryFields() {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'fields/tr', requestOptions)
    .then(response => response.json())
    .then(responseData => responseData.status === 200 ? this.setState({isLoading: false, fields: responseData.fields}) : swal({dangerMode: true, icon: 'warning', title: 'Hay Aksi', text: responseData.message, buttons: 'Tamam'}).then(() => this.props.navigate('/')), () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Yeniden Dene'}).then(() => this.getCategoryFields()));
  } */

  getCategoryFields() {

    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'fields/tr/', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200:
          this.setState({isLoading: false, fields: responseData.fields})
        break;
        case 401:
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          }).then(this.props.navigate('/home'));
        break;
        default:
          this.setState({isLoading: false});

          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          }).then(() => this.props.navigate('/'));
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Yeniden Dene'}).then(() => this.getCategoryFields()));
  }

  saveCategoryFieldOnClick(categoryFieldInfo) {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'POST',
      headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
      body: JSON.stringify({name: categoryFieldInfo.name, description: categoryFieldInfo.description, dataType: categoryFieldInfo.dataType, inputType: categoryFieldInfo.inputType, widgetType: categoryFieldInfo.widgetType, sort: parseInt(categoryFieldInfo.sort)})
    };

    fetch(config.restApi + 'field/tr', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch (responseData.status) {
        case 200: {
          swal({
            dangerMode: true,
            icon: 'success',
            title: 'Tamamlandı',
            text: 'Özel alan başarıyla eklendi.',
            buttons: 'Tamam'
          }).then(() => this.getCategoryFields(), this.setState({isOpenAddedCategoryFieldModal: false}), categoryFieldInfo.name= '', categoryFieldInfo.description= '', categoryFieldInfo.dataType, categoryFieldInfo.inputType, categoryFieldInfo.widgetType= '', categoryFieldInfo.sort= 1);
        }
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  deleteCategoryFieldOnClick(id) {
    swal({
      dangerMode: true,
      icon: 'warning',
      title: 'Onayla',
      text: 'Özel alanı silmek istediğinize emin misiniz?',
      buttons: {confirm: 'Tamam', cancel: 'Vazgeç'}
    }).then(value => {
      if (value) {
        let sessionHash = getCookie('sessionHash');

        const requestOptions = {
          method: 'DELETE',
          headers: {'Authorization': 'Bearer ' + sessionHash}
        };

        fetch(config.restApi + 'field/tr/' + id, requestOptions)
        .then(response => response.json())
        .then(responseData => {
          switch (responseData.status) {
            case 200: {
              swal({
                dangerMode: true,
                icon: 'success',
                title: 'Tamamlandı',
                text: 'Özel alan başarıyla silindi.',
                buttons: 'Tamam'
              }).then(() => this.getCategoryFields());
            }
            break;
            default: {
              swal({
                dangerMode: true,
                icon: 'warning',
                title: 'Hay Aksi',
                text: responseData.message,
                buttons: 'Tamam'
              });
            }
            break;
          }
        }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
      }
    });
  }

  render() {
    return this.state.isLoading ?
    <div className="ibox-content sk-loading position-static">
    <div className="sk-spinner sk-spinner-fading-circle">
      <div className="sk-circle1 sk-circle"></div>
      <div className="sk-circle2 sk-circle"></div>
      <div className="sk-circle3 sk-circle"></div>
      <div className="sk-circle4 sk-circle"></div>
      <div className="sk-circle5 sk-circle"></div>
      <div className="sk-circle6 sk-circle"></div>
      <div className="sk-circle7 sk-circle"></div>
      <div className="sk-circle8 sk-circle"></div>
      <div className="sk-circle9 sk-circle"></div>
      <div className="sk-circle10 sk-circle"></div>
      <div className="sk-circle11 sk-circle"></div>
      <div className="sk-circle12 sk-circle"></div>
    </div>
  </div>
  :
  <>
  <div className="row">
    <div className="col">
      <div className="ibox">
        <div className="ibox-title d-flex justify-content-between text-darkblue pr-3">
          <h5>Özel Alan Listesi</h5>
          <button className="btn btn-orange" onClick={() => this.setState({isOpenAddedCategoryFieldModal: true})} title="Ekle"><i className="fa fa-plus"></i></button>
        </div>
        <div className="ibox-content">
          <div className="row mb-3">
            <div className="col">
              <div className="overflow-auto">
                <table className="table table-striped table-bordered">
                <thead>
                  <tr className="text-center text-darkblue">
                    <th>Alan Adı</th>
                    <th>Alan Tipi</th>
                    <th>Giriş Tipi</th>
                    <th>Veri Tipi</th>
                    <th>Açıklama</th>
                    <th>Sıralama</th>
                    <th>Durum</th>
                    <th>İşlem</th>
                  </tr> 
                </thead>
                <tbody className="text-center">
                  {
                    this.state.fields.map((fieldInfo) =>
                      <tr key={fieldInfo.id}>
                        <td className="align-middle">{fieldInfo.name}</td>
                        <td className="align-middle">{fieldInfo.widgetType}</td>
                        <td className="align-middle">{fieldInfo.inputType}</td>
                        <td className="align-middle">{fieldInfo.dataType}</td>
                        <td className="align-middle">{fieldInfo.description}</td>
                        <td className="align-middle">{fieldInfo.sort}</td>
                        <td className="align-middle">{fieldInfo.active === 1 ? 'Açık' : 'Kapalı'}</td>
                        <td className="align-middle" style={{minWidth:154}}>
                          <Link className="btn btn-outline btn-orange text-center m-1" title="Düzenle" to={'/categoryFieldEdit?id=' + fieldInfo.id}><i className="fa fa-pencil text-white action-icon"></i></Link>
                          <Link className="btn btn-outline btn-orange m-1" title="Liste" to={'/categoryFieldItemList?id=' + fieldInfo.id}><i className="fa fa-list"></i></Link>
                          <button className="btn btn-outline btn-darkblue m-1" onClick={() => this.deleteCategoryFieldOnClick(fieldInfo.id)} title="Sil"><i className="fa fa-trash"></i></button>
                        </td>
                      </tr>
                    )
                  }
                  {this.state.fields.length === 0 ?
                    <tr>
                      <td className="align-middle p-3" colSpan="5">Özel Alan Bulunamadı !</td>
                    </tr>
                  : null}
                </tbody>
                </table>
                <div className="d-flex justify-content-end">
                  <button className="btn btn-orange" onClick={() => this.setState({isOpenAddedCategoryFieldModal: true})} title="Ekle"><i className="fa fa-plus"></i></button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AddedCategoryFieldModal isOpen={this.state.isOpenAddedCategoryFieldModal} modalTitle="Özel Alan Ekle" modalDescription="İlgili kategoriye ait özel alan eklemek için aşağıdaki alanları doldurunuz !" onRequestClose={() => this.setState({isOpenAddedCategoryFieldModal: false})} saveOnClick={categoryFieldInfo => this.saveCategoryFieldOnClick(categoryFieldInfo)} />
      </div>
    </div>
  </div>
  </>
  }
}

export default function CategoryFields() {
  return (
    <CategoryFieldsModule navigate={useNavigate()} />
  )
}
