import React from 'react';
import Modal from 'react-modal';
import {Link} from 'react-router-dom';
import Select from 'react-select';

export default class SearchMemberWorkExpenseModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isCompleted: true,

      selectedId: this.props.companyEmployees.length > 0 && this.props.companyEmployees[0].id || 0
    };
  }
  
  selectedEmployeeOnChange = (selectedEmployee) => {
    this.setState({selectedId: selectedEmployee.value})
  };
  
  componentDidMount() {}

  render() {
    const selectedEmployeesOptions = this.props.companyEmployees.map((companyEmployeeInfo) => ({
      value: companyEmployeeInfo.id,
      label: `${companyEmployeeInfo.firstname} ${companyEmployeeInfo.lastname}`,
      search: companyEmployeeInfo.firstname + ' ' + companyEmployeeInfo.lastname
    }))

    return (
      <div className="modal inmodal">
        <div className="modal-dialog">
          <div className="modal-content animated fadeIn">
          <Modal
            isOpen={this.props.isOpen}
            onRequestClose={this.props.onRequestClose}
            style={{
              content : {
                position : 'null',
                top : 'null',
                left : 'null',
                right : 'null',
                bottom : 'null',
                border : 'none',
                background : '#fff',
                padding : 'null',
                maxWidth : '50%',
                minWidth : '350px'
              },
              overlay : {
                position : 'fixed',
                top : 0,
                left : 0,
                right : 0,
                bottom : 0,
                display : 'flex',
                alignItems : 'center',
                justifyContent : 'center',
                backgroundColor : 'rgba(0,0,0,00.1)',
                zIndex:2500
              }
            }}
          >
            <div className={this.state.isCompleted ? "ibox-content no-padding" : "ibox-content sk-loading no-padding"}>
              <div className="sk-spinner sk-spinner-fading-circle">
                <div className="sk-circle1 sk-circle"></div>
                <div className="sk-circle2 sk-circle"></div>
                <div className="sk-circle3 sk-circle"></div>
                <div className="sk-circle4 sk-circle"></div>
                <div className="sk-circle5 sk-circle"></div>
                <div className="sk-circle6 sk-circle"></div>
                <div className="sk-circle7 sk-circle"></div>
                <div className="sk-circle8 sk-circle"></div>
                <div className="sk-circle9 sk-circle"></div>
                <div className="sk-circle10 sk-circle"></div>
                <div className="sk-circle11 sk-circle"></div>
                <div className="sk-circle12 sk-circle"></div>
              </div>
              <div className="modal-header d-block text-center text-darkblue">
                <button className="close" onClick={this.props.onRequestClose}><span>×</span></button>
                <h4 className="modal-title">{this.props.modalTitle}</h4>
                <h5 className="font-normal">{this.props.modalDescription}</h5>
              </div>
              <div className="modal-body text-darkblue">
                <strong>Adı Soyadı</strong>
                <Select
                className=" form-input mb-2 mt-1 text-dark"
                options={selectedEmployeesOptions}
                onChange={this.selectedEmployeeOnChange}
                onKeyDown={this.handleKeyPress}
                value={selectedEmployeesOptions.find((employeeOptionInfo) => employeeOptionInfo.value === this.state.selectedId)}
                filterOption={(option, searchText) => option.data.search && option.data.search.toLocaleLowerCase('tr-TR').includes(searchText.toLocaleLowerCase('tr-TR'))}
                placeholder="Şirket Çalışanı">
                </Select>
                {/* <select className="form-control form-input mb-2 mt-1" defaultValue= {this.state.selectedId} onChange={event => this.setState({selectedId: this.props.companyEmployees.find(item => item.firstname + ' ' + item.lastname == event.target.value).id})}>
                  {this.props.companyEmployees.map(companyEmployeeInfo => <option key={companyEmployeeInfo.id}>{companyEmployeeInfo.firstname} {companyEmployeeInfo.lastname}</option>)}
                </select> */}
              </div>
              <div className="modal-footer">
                <Link className="btn btn-orange" to={"/memberWorkExpenseDetail?id=" + this.state.selectedId}>Masraflara Git</Link>
              </div>
            </div>
          </Modal>
          </div>
        </div>
      </div>
    )
  }
}