import React from 'react';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import swal from 'sweetalert';
import ReactPaginate from 'react-paginate';

import SearchMemberWorkExpenseModal from '../../../component/accounting/SearchMemberWorkExpenseModal';

import {getCookie} from '../../../inc/cookies';
import config from '../../../inc/config';

class MemberWorkExpenseModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isOpenSearchMemberWorkExpenseModal: false,

      companyEmployees: [],

      pageCount: 0,
      resultCount: 0,
      currentPage: 1,
      limit: 20
    };
  }

  componentDidMount() {
    this.getCompanyEmployees(this.state.currentPage);
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
  }

  getCompanyEmployees(currentPage) {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    const startIndex = 0;
    const endIndex = startIndex + this.state.limit;

    fetch(config.restApi + `company/tr/employees`, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          const currentCompanyEmployees = responseData.companyEmployees.slice(startIndex, endIndex);
          this.setState({isLoading: false, companyEmployees: currentCompanyEmployees, pageCount: responseData.pageCount, resultCount: responseData.resultCount, currentPage: currentPage});
        }
        break;
        default: {
          this.setState({isLoading: false});

          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          }).then(() => this.props.navigate('/home'));
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  handlePageClick(page) {
    this.setState({currentPage: page.selected + 1}, () => this.getCompanyEmployees(this.state.currentPage))
  }

  render() {
    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <>
        <div className="row">
          <div className="col">
            <div className="ibox">
              <div className="ibox-title text-darkblue d-flex justify-content-between pr-2 pl-0">
                <div className="col-7 col-xl-8"><h5>Şirket Çalışan Masrafları</h5></div>
                <button className="btn btn-orange" onClick={() => this.setState({isOpenSearchMemberWorkExpenseModal: true})} title="Arama"><i className="fa fa-search"></i> Çalışan Bul</button>
              </div>
              <div className="table-responsive overflow-x-auto">
                <table className="table table-striped table-bordered">
                  <thead>
                    <tr className="text-center text-darkblue">
                      <th>Adı</th>
                      <th>Soyadı</th>
                      <th>Kullanıcı Adı</th>
                      <th>Email</th>
                      <th>Durum</th>
                      <th>Masrafları Görüntüle</th>
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {this.state.companyEmployees.map((companyEmployeeInfo) =>
                      <tr key={companyEmployeeInfo.id}>
                        <td className="align-middle">{companyEmployeeInfo.firstname}</td>
                        <td className="align-middle">{companyEmployeeInfo.lastname}</td>
                        <td className="align-middle">{companyEmployeeInfo.username}</td>
                        <td className="align-middle">{companyEmployeeInfo.email}</td>
                        <td className="align-middle">{companyEmployeeInfo.active === 1 ? 'Açık' : 'Kapalı'}</td>
                        <td className="align-middle">
                          <Link className="btn btn-orange text-center" to={"/memberWorkExpenseDetail?id=" + companyEmployeeInfo.id}><i className="fa fa-pencil text-white action-icon"></i></Link>
                        </td>
                      </tr>
                    )}
                    {this.state.companyEmployees.length === 0 ?
                      <tr>
                        <td className="align-middle p-3" colSpan="6">Üye Bulunamadı !</td>
                      </tr>
                    : null}
                  </tbody>
                </table>
                {
                  this.state.companyEmployees.length !== 0 &&
                  <ReactPaginate
                  previousLabel={"Geri"}
                  nextLabel={"İleri"}
                  breakLabel={"..."}  
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={1}
                  onPageChange={page => this.handlePageClick(page)}
                  pageRangeDisplayed={2}
                  containerClassName={'pagination'}
                  activeClassName={'activePage'}
                  />
                }
              </div>
            </div>
          </div>
        </div>
        <SearchMemberWorkExpenseModal isOpen={this.state.isOpenSearchMemberWorkExpenseModal} companyEmployees={this.state.companyEmployees} modalDescription="Masraf bilgisine ulaşmak istediğiniz şirket çalışanı seçiniz !" modalTitle="Çalışan Masraflarını Bul" onRequestClose={() => this.setState({isOpenSearchMemberWorkExpenseModal: false})} />
      </>
  }
}

export default function MemberWorkExpenses() {
  return (
    <MemberWorkExpenseModule navigate={useNavigate()} searchParams={useSearchParams()[0]} />
  )
}