import React from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import swal from 'sweetalert';

import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertFromHTML, convertToRaw} from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';

import {getCookie} from '../../../inc/cookies';
import config from '../../../inc/config';

class HelpEditModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,

      title: '',
      description: '',
      content: '',
      slug: '',
      keywords: '',
      author: '',
      footerTitle: '',
      footerDescription: '',
      active: 1
    };

    this.activeOnChange = this.activeOnChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.getHelp();
    window.scrollTo(0, 0);
  }

  getHelp() {
    let sessionHash = getCookie('sessionHash');

    this.setState({isLoading: true});

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'helpArticle/tr/' + this.props.searchParams.get('id'), requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          const blocksFromHTML = convertFromHTML(responseData.comment.content);
          const content = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
          this.setState({isLoading: false, active: responseData.comment.active, title: responseData.comment.title, description: responseData.comment.description, content: EditorState.createWithContent(content), slug: responseData.comment.slug, keywords: responseData.comment.keywords, author: responseData.comment.author, footerTitle: responseData.comment.footerTitle, footerDescription: responseData.comment.footerDescription});
        }
        break;
        case 404: {
          this.setState({isLoading: false});
        }
        break;
        default: {
          this.setState({isLoading: false});
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          }).then(() => this.props.navigate('/helps'));
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  activeOnChange(event) {

    let active = event.target.value === '' ? '' : parseInt(event.target.value);

    this.setState({active: active});

    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'PUT',
      headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
    };

    fetch(config.restApi + 'activeStatus/helpArticles/' + this.props.searchParams.get('id') + '/' + active, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      this.setState({isLoading: false});

      switch (responseData.status) {
        case 200: {}
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));

  }

  handleKeyPress(event) {
    if (event.key === 'Enter') {
      this.saveHelpOnClick();
    }
  }

  saveHelpOnClick = () => {
    let sessionHash = getCookie('sessionHash');

    let infoMessage = '';

    if (!this.state.title) {
      infoMessage = 'Başlık boş geçilemez !';
    } else if (!this.state.description) {
      infoMessage = 'Açıklama boş geçilemez !';
    } else if (!this.state.content) {
      infoMessage = 'İçerik boş geçilemez !';
    } else if (!this.state.slug) {
      infoMessage = 'Slug boş geçilemez !';
    } else if (!this.state.keywords) {
      infoMessage = 'Anahtar kelimeler boş geçilemez !';
    } else if (!this.state.author) {
      infoMessage = 'Yazar boş geçilemez !';
    } else if (!this.state.footerTitle) {
      infoMessage = 'Alt başlık boş geçilemez !';
    } else if (!this.state.footerDescription) {
      infoMessage = 'Alt bilgi boş geçilemez !';
    }

    if (infoMessage) {
      swal({
        dangerMode: true,
        icon: 'info',
        title: 'Eksik Bilgi',
        text: infoMessage,
        buttons: 'Tamam'
      });
    } else {
      this.setState({isLoading: true});

      const requestOptions = {
        method: 'PUT',
        headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
        body: JSON.stringify({title: this.state.title, description: this.state.description, content: draftToHtml(convertToRaw(this.state.content.getCurrentContent())), slug: this.state.slug, keywords: this.state.keywords, author: this.state.author, footerTitle: this.state.footerTitle, footerDescription: this.state.footerDescription})
      };

      fetch(config.restApi + 'helpArticle/tr/' + this.props.searchParams.get('id'), requestOptions)
      .then(response => response.json())
      .then(responseData => {
        this.setState({isLoading: false});

        switch (responseData.status) {
          case 200: {
            swal({
              dangerMode: true,
              icon: 'success',
              title: 'Tamamlandı',
              text: 'Yardım bilgisi başarıyla güncellendi.',
              buttons: 'Tamam'
            });
          }
          break;
          default: {
            swal({
              dangerMode: true,
              icon: 'warning',
              title: 'Hay Aksi',
              text: responseData.message,
              buttons: 'Tamam'
            });
          }
          break;
        }
      }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
    }
  }

  deleteHelpOnClick = () => {
    swal({
      dangerMode: true,
      icon: 'warning',
      title: 'Onayla',
      text: "Yardım sayfası'nı silmek istediğinize emin misiniz?",
      buttons: {confirm: 'Tamam', cancel: 'Vazgeç'}
    }).then(value => {
      if (value) {
        let sessionHash = getCookie('sessionHash');

        const requestOptions = {
          method: 'DELETE',
          headers: {'Authorization': 'Bearer ' + sessionHash}
        };

        fetch(config.restApi + 'helpArticle/tr/' + this.props.searchParams.get('id'), requestOptions)
        .then(response => response.json())
        .then(responseData => {
          this.setState({isLoading: false});

          switch (responseData.status) {
            case 200: {
              swal({
                dangerMode: true,
                icon: 'success',
                title: 'Tamamlandı',
                text: 'Yardım sayfası başarıyla silindi.',
                buttons: 'Tamam'
              }).then(() => this.props.navigate('/helps'));
            }
            break;
            default: {
              swal({
                dangerMode: true,
                icon: 'warning',
                title: 'Hay Aksi',
                text: responseData.message,
                buttons: 'Tamam'
              });
            }
            break;
          }
        }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
      }
    });
  }

  onChange(content) {
    this.setState({content: content})
  }

  render() {
    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <>
        <div className="row">
          <div className="col-12">
            <div className="ibox">
              <div className="ibox-title d-flex justify-content-between text-darkblue pr-2">
                <h5>Yardım Düzenle</h5>
                <div className="d-flex">
                  <button className="btn btn-orange mr-2" onClick={this.deleteHelpOnClick} title="Sil"><i className="fa fa-trash"></i></button>
                  <button className="btn btn-orange mr-2" onClick={this.saveHelpOnClick} title="Kaydet"><i className="fa fa-save"></i></button>
                  <button className="btn btn-darkblue text-white" onClick={() => {this.props.navigate(-1)}} title="Geri"><i className="fa fa-reply"></i></button>
                </div>
              </div>
              <div className="ibox-content text-darkblue">
                <h4>Yardım Bilgisi Güncelleme</h4>
                <div className="hr-line-dashed"></div>
                <div className="row">
                  <div className="col">
                    <strong>Yardım Başlığı<strong className="text-danger ml-1">*</strong></strong>
                    <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({title: event.target.value.trimStart()})} onKeyDown={this.handleKeyPress} placeholder="Yardım Başlığı" type="text" value={this.state.title} />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <strong>Açıklama<strong className="text-danger ml-1">*</strong></strong>
                    <textarea className="form-control form-control-sm form-input mb-2 mt-1" onChange={event => this.setState({description: event.target.value.trimStart()})} onKeyDown={this.handleKeyPress} placeholder="Yardım tanımı giriniz.." type="text" value={this.state.description} />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                  <strong>İçerik<strong className="text-danger ml-1">*</strong></strong>
                  <Editor
                    editorClassName="editor-class"
                    editorState={this.state.content}
                    onEditorStateChange={this.onChange}
                    onKeyDown={this.handleKeyPress}
                    editorStyle={{border: '1px solid #F1F1F1', height: '25vh'}}
                  ></Editor>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col">
                  <strong>Slug<strong className="text-danger ml-1">*</strong></strong>
                  <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({slug: event.target.value.trimStart()})} onKeyDown={this.handleKeyPress} placeholder="Slug" type="text" value={this.state.slug}/>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                  <strong>Anahtar Kelimeler<strong className="text-danger ml-1">*</strong></strong>
                  <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({keywords: event.target.value.trimStart()})} onKeyDown={this.handleKeyPress} placeholder="Anahtar Kelimeler" type="text" value={this.state.keywords}/>
                  </div>
                  <div className="col-md-6">
                  <strong>Yazar<strong className="text-danger ml-1">*</strong></strong>
                  <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({author: event.target.value.trimStart()})} onKeyDown={this.handleKeyPress} placeholder="Yazar" type="text" value={this.state.author}/>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                  <strong>Alt Başlık<strong className="text-danger ml-1">*</strong></strong>
                  <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({footerTitle: event.target.value.trimStart()})} onKeyDown={this.handleKeyPress} placeholder="Alt Başlık" type="text" value={this.state.footerTitle}/>
                  </div>
                  <div className="col-md-6">
                  <strong>Alt Açıklama<strong className="text-danger ml-1">*</strong></strong>
                  <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({footerDescription: event.target.value.trimStart()})} onKeyDown={this.handleKeyPress} placeholder="Alt Açıklama" type="text" value={this.state.footerDescription}/>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <h5><strong>Durum</strong></h5>
                    <select className="form-control form-control-sm form-input" onChange={this.activeOnChange} onKeyDown={this.handleKeyPress} value={this.state.active}>
                      <option value="1">Açık</option>
                      <option value="0">Kapalı</option>
                    </select>
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  <button className="btn btn-orange" onClick={this.saveHelpOnClick}>Kaydet</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
  }
}

export default function HelpEdit() {
  return (
    <HelpEditModule navigate={useNavigate()} searchParams={useSearchParams()[0]} />
  )
}