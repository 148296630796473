import React from 'react';
import Modal from 'react-modal';
import swal from 'sweetalert';

import {getCookie} from '../../inc/cookies';
import config from '../../inc/config';
import {isoToMysqlDate, inputDateTimeToIsoDateTime} from '../../inc/helper';

export default class MemberWorkExpenseAddModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isCompleted: true,
      
      memberWorkExpenseInfo: {
        name: '' ,
        description: '',
        expenseDate: null,
        expenseResult: '',
        price: '0'
      }
    };

    this.expenseDateOnChange = this.expenseDateOnChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleKeyPress(event) {
    if (event.key === 'Enter') {
      this.saveOnClick();
    }
  }
 
  saveOnClick() {
    let sessionHash = getCookie('sessionHash');

    let infoMessage = '';
    if (!this.state.memberWorkExpenseInfo.name) {
      infoMessage = 'Masraf adı boş geçilemez !';
    } else if (!this.state.memberWorkExpenseInfo.description) {
      infoMessage = 'Açıklama boş geçilemez !';
    } else if (!this.state.memberWorkExpenseInfo.expenseDate) {
      infoMessage = 'Harcama tarihi boş geçilemez !';
    } else if (!this.state.memberWorkExpenseInfo.price || this.state.memberWorkExpenseInfo.price === '0') {
      infoMessage = 'Tutar boş geçilemez !';
    }

    if (infoMessage) {
      swal({
        dangerMode: true,
        icon: 'info',
        title: 'Eksik Bilgi',
        text: infoMessage,
        buttons: 'Tamam'
      });
    } else {
      this.setState({isCompleted: false});

      const requestOptions = {
        method: 'POST',
        headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
        body: JSON.stringify({name: this.state.memberWorkExpenseInfo.name, description: this.state.memberWorkExpenseInfo.description, expenseDate: inputDateTimeToIsoDateTime(this.state.memberWorkExpenseInfo.expenseDate), expenseResult: this.state.memberWorkExpenseInfo.expenseResult, price: parseInt(this.state.memberWorkExpenseInfo.price)})
      };

      fetch(config.restApi + 'memberWorkExpense/' + this.props.memberId, requestOptions)
      .then(response => response.json())
      .then(responseData => {
        switch (responseData.status) {
          case 200: {
            this.setState({isCompleted: true, memberWorkExpenseInfo: {name: '', description: '', expenseDate: null, expenseResult: '', price: '0'}});

            swal({
              dangerMode: true,
              icon: 'success',
              title: 'Tamamlandı',
              text: 'Çalışana ait masraf bilgileri başarıyla eklendi.',
              buttons: 'Tamam'
            }).then(() => this.props.onCompleted());
          }
          break;
          default: {
            this.setState({isCompleted: true});

            swal({
              dangerMode: true,
              icon: 'warning',
              title: 'Hay Aksi',
              text: responseData.message,
              buttons: 'Tamam'
            });
          }
          break;
        }
      }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
    }
  }

  expenseDateOnChange(event) {
    this.setState({memberWorkExpenseInfo: {...this.state.memberWorkExpenseInfo, expenseDate: event.target.value || null}})
  } 

  render() {
    return (
      <div className="modal inmodal">
        <div className="modal-dialog">
          <div className="modal-content animated fadeIn">
          <Modal
            isOpen={this.props.isOpen}
            onRequestClose={this.props.onRequestClose}
            style={{
              content : {
                position : 'null',
                top : 'null',
                left : 'null',
                right : 'null',
                bottom : 'null',
                border : 'none',
                background : '#fff',
                padding : 'null',
                maxWidth : '50%',
                minWidth : '350px'
              },
              overlay : {
                position : 'fixed',
                top : 0,
                left : 0,
                right : 0,
                bottom : 0,
                display : 'flex',
                alignItems : 'center',
                justifyContent : 'center',
                backgroundColor : 'rgba(0,0,0,00.1)',
                zIndex:2500
              }
            }}
          >
            <div className={this.state.isCompleted ? "ibox-content no-padding" : "ibox-content sk-loading no-padding"}>
              <div className="sk-spinner sk-spinner-fading-circle">
                <div className="sk-circle1 sk-circle"></div>
                <div className="sk-circle2 sk-circle"></div>
                <div className="sk-circle3 sk-circle"></div>
                <div className="sk-circle4 sk-circle"></div>
                <div className="sk-circle5 sk-circle"></div>
                <div className="sk-circle6 sk-circle"></div>
                <div className="sk-circle7 sk-circle"></div>
                <div className="sk-circle8 sk-circle"></div>
                <div className="sk-circle9 sk-circle"></div>
                <div className="sk-circle10 sk-circle"></div>
                <div className="sk-circle11 sk-circle"></div>
                <div className="sk-circle12 sk-circle"></div>
              </div>
              <div className="modal-header d-block text-center text-darkblue">
                <button className="close" onClick={this.props.onRequestClose}><span>×</span></button>
                <h4 className="modal-title">{this.props.modalTitle}</h4>
                <h5 className="font-normal">{this.props.modalDescription}</h5>
              </div>
              <div className="modal-body text-darkblue">
                <strong>Masraf Adı<strong className="text-danger ml-1">*</strong></strong>
                <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({memberWorkExpenseInfo: {...this.state.memberWorkExpenseInfo, name: event.target.value.trimStart()}})} onKeyDown={this.handleKeyPress} placeholder="Masraf Adı" type="text" value={this.state.memberWorkExpenseInfo.name} />
                <strong>Açıklama<strong className="text-danger ml-1">*</strong></strong>
                <textarea className="form-control form-control-sm form-input mb-2 mt-1" onChange={event => this.setState({memberWorkExpenseInfo: {...this.state.memberWorkExpenseInfo, description: event.target.value.trimStart()}})} onKeyDown={this.handleKeyPress} placeholder="Açıklama giriniz.." type="text" value={this.state.memberWorkExpenseInfo.description} />
                <strong>Harcama Tarihi<strong className="text-danger ml-1">*</strong></strong>
                <input className="form-control form-control-sm form-input mb-2 mt-1" onChange={this.expenseDateOnChange} onKeyDown={this.handleKeyPress} type="date" value={this.state.memberWorkExpenseInfo.expenseDate ? isoToMysqlDate(this.state.memberWorkExpenseInfo.expenseDate) : ''} />
                <strong>Not</strong>
                <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({memberWorkExpenseInfo: {...this.state.memberWorkExpenseInfo, expenseResult: event.target.value}})} onKeyDown={this.handleKeyPress} placeholder="İletmek istediğiniz notu giriniz." type="text" value={this.state.memberWorkExpenseInfo.expenseResult} />
                <strong>Tutar<strong className="text-danger ml-1">*</strong></strong>
                <input className="form-control form-input mb-2 mt-1" min="0" onChange={event => this.setState({memberWorkExpenseInfo: {...this.state.memberWorkExpenseInfo, price: event.target.value}})} onKeyDown={this.handleKeyPress} placeholder="Tutar" type="number" value={this.state.memberWorkExpenseInfo.price} />
              </div>
              <div className="modal-footer"> 
                <button className="btn btn-orange" onClick={() => this.saveOnClick()}>Kaydet</button>
              </div>
            </div>
          </Modal>
          </div>
        </div>
      </div>
    )
  }
}
