import React from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import swal from 'sweetalert';
import ReactPaginate from 'react-paginate';

import {getCookie} from '../../inc/cookies';
import config from '../../inc/config';
import {isoToDate, priceFormat} from '../../inc/helper';

class WorkExpenseModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,

      memberWorkExpenses: [],

      filterExpenseStartDate: '',
      filterExpenseEndDate: '',
      filterFullName: '',
      filterActive: '',
      filterLocked: '',

      pageCount: 0,
      resultCount: 0,
      currentPage: 1,
      limit: 10
    };
  }

  componentDidMount() {
    this.getWorkExpenses(this.state.currentPage);
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
  }

  getWorkExpenses(currentPage) {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    const startIndex = 0;
    const endIndex = startIndex + this.state.limit;

    fetch(config.restApi + `memberWorkExpenses/tr?limit=${this.state.limit}&page=${currentPage}`, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          const currentMemberWorkExpenses = responseData.memberWorkExpenses.slice(startIndex, endIndex);
          this.setState({isLoading: false, memberWorkExpenses: currentMemberWorkExpenses, pageCount: responseData.pageCount, resultCount: responseData.resultCount, currentPage: currentPage});
        }
        break;
        default: {
          this.setState({isLoading: false});

          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          }).then(() => this.props.navigate('/home'));
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  filteredWorkExpenses(query) {
    let sessionHash = getCookie('sessionHash');

    this.setState({isLoading: true});

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'memberWorkExpenses/tr?' + query, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({isLoading: false, memberWorkExpenses: responseData.memberWorkExpenses});
        }
        break;
        default: {
          this.setState({isLoading: false});

          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  handlePageClick(page) {
    this.setState({currentPage: page.selected + 1}, () => this.getWorkExpenses(this.state.currentPage))
  }

  filterOnClick = (event) => {
    this.filteredWorkExpenses('filterFullName=' + this.state.filterFullName + '&filterActive=' + this.state.filterActive + '&filterLocked=' + this.state.filterLocked + '&filterExpenseStartDate=' + this.state.filterExpenseStartDate + '&filterExpenseEndDate=' + this.state.filterExpenseEndDate);
  }

  resetOnClick = (event) => {
    this.setState({
      filterExpenseStartDate: '',
      filterExpenseEndDate: '',
      filterFullName: '',
      filterActive: '',
      filterLocked: ''
    });
    
    this.getWorkExpenses();
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.filterOnClick();
    }
  }
  
  render() {
    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <>
        <div className="row">
          <div className="col-xl-9">
            <div className="ibox">
              <div className="ibox-title text-darkblue d-flex justify-content-between pl-0">
                <div className="col-7 col-xl-8"><h5>Tüm Şirket Masrafları</h5></div>
              </div>
              <div className="table-responsive overflow-x-auto">
                <table className="table table-striped table-bordered">
                  <thead>
                    <tr className="text-center text-darkblue">
                      <th>Masraf Adı</th>
                      <th>Açıklama</th>
                      <th>Tutar</th>
                      <th>Muhasebe Notu</th>
                      <th>Onay Durumu</th>
                      <th>Harcama Tarihi</th>
                      <th>Durum</th>
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {this.state.memberWorkExpenses.map((workExpenseInfo) =>
                      <tr key={workExpenseInfo.id}>
                        <td className="align-middle">{workExpenseInfo.name} <br/><span className="text-orange">{workExpenseInfo.memberFullName}</span></td>
                        <td className="align-middle">{workExpenseInfo.description}</td>
                        <td className="align-middle">{priceFormat(workExpenseInfo.price)} ₺</td>
                        <td className="align-middle">{workExpenseInfo.expenseResult ? workExpenseInfo.expenseResult : '-'}</td>
                        <td className="align-middle">{workExpenseInfo.locked ? <i className="fa fa-check pointer text-green font-bold" title="Onaylandı"></i>: <i className="fa fa-reply text-orange font-bold" title="Onaylanmadı"></i>}</td>
                        <td className="align-middle">{workExpenseInfo.expenseDate ? isoToDate(workExpenseInfo.expenseDate) : "-"}</td>
                        <td className="align-middle">{workExpenseInfo.active === 1 ? 'Açık' : 'Kapalı'}</td>
                      </tr>
                    )}
                    {this.state.memberWorkExpenses.length === 0 && 
                      <tr>
                        <td className="align-middle p-3" colSpan="7">Kriterlerinize Uygun Masraf Bulunamadı !</td>
                      </tr>
                    }
                  </tbody>
                </table>
                {
                  this.state.memberWorkExpenses.length !== 0 &&
                  <ReactPaginate
                  previousLabel={"Geri"}
                  nextLabel={"İleri"}
                  breakLabel={"..."}  
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={1}
                  onPageChange={page => this.handlePageClick(page)}
                  pageRangeDisplayed={2}
                  containerClassName={'pagination'}
                  activeClassName={'activePage'}
                  />
                }
              </div>
            </div>
          </div>
          <div className="col-xl-3 order-first order-xl-last d-xl-none" id="accordion">
            <div className="ibox">
              <div className="ibox-content text-darkblue p-0">
                <h4 className="collapse-title">
                  <a className="btn btn-link text-darkblue font-bold d-flex justify-content-between" data-toggle="collapse" href="#filterCollapse" title="Filtrele">Filtrele</a>
                </h4>
              </div>
              <div className="collapse" data-parent="#accordion" id="filterCollapse">
                <div className="ibox-content text-darkblue">
                  <strong>Personel Adı Soyadı</strong>
                  <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterFullName: event.target.value})} onKeyDown={this.handleKeyPress} placeholder="Personel Adı Soyadı" type="text" value={this.state.filterFullName} />
                  <strong>Onay Durumu</strong>
                  <select className="form-control form-input mb-2 mt-1" onChange={(event) => this.setState({filterLocked: event.target.value})} onKeyDown={this.handleKeyPress} value={this.state.filterLocked}>
                    <option value="">Seçiniz</option>
                    <option value="1">Onaylı</option>
                    <option value="0">Onaysız</option>
                  </select>
                  <strong>Durum</strong>
                  <select className="form-control form-input mb-2 mt-1" onChange={(event) => this.setState({filterActive: event.target.value})} onKeyDown={this.handleKeyPress} value={this.state.filterActive}>
                    <option value="">Seçiniz</option>
                    <option value="1">Açık</option>
                    <option value="0">Kapalı</option>
                  </select>
                  <strong>Başlangıç Tarihi</strong>
                  <input className="form-control form-input" onChange={event => this.setState({filterExpenseStartDate: event.target.value})} onKeyDown={this.handleKeyPress} type="date" value={this.state.filterExpenseStartDate} />
                  <strong>Bitiş Tarihi</strong>
                  <input className="form-control form-input" onChange={event => this.setState({filterExpenseEndDate: event.target.value})} onKeyDown={this.handleKeyPress} type="date" value={this.state.filterExpenseEndDate} />
                  <div className="d-flex justify-content-end">
                    <button className="btn btn-orange mt-3 mr-2" onClick={this.resetOnClick}><i className="fa fa-refresh"></i> Sıfırla</button>
                    <button className="btn btn-orange mt-3" onClick={this.filterOnClick}><i className="fa fa-filter"></i> Filtrele</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 order-first order-xl-last d-none d-xl-block">
            <div className="ibox">
              <div className="ibox-title text-darkblue">
                <h5><i className="fa fa-filter"></i> Filtrele</h5>
              </div>
              <div className="ibox-content text-darkblue">
                <strong>Personel Adı Soyadı</strong>
                <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterFullName: event.target.value})} onKeyDown={this.handleKeyPress} placeholder="Personel Adı Soyadı" type="text" value={this.state.filterFullName} />
                <strong>Onay Durumu</strong>
                <select className="form-control form-input mb-2 mt-1" onChange={(event) => this.setState({filterLocked: event.target.value})} onKeyDown={this.handleKeyPress} value={this.state.filterLocked}>
                  <option value="">Seçiniz</option>
                  <option value="1">Onaylı</option>
                  <option value="0">Onaysız</option>
                </select>
                <strong>Durum</strong>
                <select className="form-control form-input mb-2 mt-1" onChange={(event) => this.setState({filterActive: event.target.value})} onKeyDown={this.handleKeyPress} value={this.state.filterActive}>
                  <option value="">Seçiniz</option>
                  <option value="1">Açık</option>
                  <option value="0">Kapalı</option>
                </select>
                <strong>Başlangıç Tarihi</strong>
                <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterExpenseStartDate: event.target.value})} onKeyDown={this.handleKeyPress} type="date" value={this.state.filterExpenseStartDate} />
                <strong>Bitiş Tarihi</strong>
                <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterExpenseEndDate: event.target.value})} onKeyDown={this.handleKeyPress} type="date" value={this.state.filterExpenseEndDate} />
                <div className="d-flex justify-content-end">
                  <button className="btn btn-orange mt-3 mr-2" onClick={this.resetOnClick}><i className="fa fa-refresh"></i> Sıfırla</button>
                  <button className="btn btn-orange mt-3" onClick={this.filterOnClick}><i className="fa fa-filter"></i> Filtrele</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
  }
}

export default function WorkExpenses() {
  return (
    <WorkExpenseModule navigate={useNavigate()} searchParams={useSearchParams()[0]} />
  )
}