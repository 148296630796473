import React from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import swal from 'sweetalert';

import { getCookie } from '../../../inc/cookies';
import config from '../../../inc/config';

import AddedCategoryFieldLanguageModal from '../../../component/category/categoryField/AddedCategoryFieldLanguageModal';

class AttributeGroupEditModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isOpenAddedCategoryFieldLanguageModal: false,

      sort: 0,
      type: '',

      fieldLanguageId: 0,
      fieldLanguages: [],
      editCategoryFieldLanguages: null,

      customWidgetList : config.customWidgetList
    };

    this.fieldTypeOnChange = this.fieldTypeOnChange.bind(this);
  }

  componentDidMount() {
    this.getCategoryFieldDetail();
    window.scrollTo(0, 0);
  }

  getCategoryFieldDetail() {
    let sessionHash = getCookie('sessionHash');

    this.setState({isLoading: true});

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    let errorMessages = [];
    let remaining = 2;
    let complete = () => {
      remaining--;
      if (remaining === 0) {
        this.setState({isLoading: false});
      }
    }

    fetch(config.restApi + 'field/tr/' + this.props.searchParams.get('id'), requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({sort: responseData.fieldInfo.sort, type: responseData.fieldInfo.type});
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));

    fetch(config.restApi + 'fieldLanguages/tr/' + this.props.searchParams.get('id'), requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({fieldLanguages: responseData.fieldLanguages});
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));
  }

  fieldTypeOnChange (event) {
    const selectedOptionName = event.target.value;
    const selectedOption = this.state.customWidgetList.find((widgetInfo) => widgetInfo.name === selectedOptionName);
    this.setState({type: selectedOption.name});
  };

  saveCategoryFieldOnClick = () => {
    let sessionHash = getCookie('sessionHash');

    let infoMessage = '';
    if (!this.state.sort && this.state.sort !== 0) {
      infoMessage = 'Sıralama boş geçilemez !';
    } else if(!this.state.type) {
      infoMessage = 'Tip boş geçilemez !';
    }

    if (infoMessage) {
      swal({
        dangerMode: true,
        icon: 'info',
        title: 'Eksik Bilgi',
        text: infoMessage,
        buttons: 'Tamam'
      });
    } else {
      this.setState({isLoading: true});

      const requestOptions = {
        method: 'PUT',
        headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
        body: JSON.stringify({type: this.state.type, sort: parseInt(this.state.sort)})
      };

      fetch(config.restApi + 'field/tr/' + this.props.searchParams.get('id'), requestOptions)
      .then(response => response.json())
      .then(responseData => {
        this.setState({isLoading: false});

        switch (responseData.status) {
          case 200: {
            swal({
              dangerMode: true,
              icon: 'success',
              title: 'Tamamlandı',
              text: 'Özel alan başarıyla güncellendi.',
              buttons: 'Tamam'
            });
          }
          break;
          default: {
            swal({
              dangerMode: true,
              icon: 'warning',
              title: 'Hay Aksi',
              text: responseData.message,
              buttons: 'Tamam'
            });
          }
          break;
        }
      }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
    }
  }

  saveCategoryFieldLanguageOnClick(categoryFieldLanguageInfo, id) {

    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'POST',
      headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
      body: JSON.stringify({name: categoryFieldLanguageInfo.name, language: categoryFieldLanguageInfo.language, description: categoryFieldLanguageInfo.description})
    };

    fetch(config.restApi + 'fieldLanguage/tr/' + id, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch (responseData.status) {
        case 200: {
          swal({
            dangerMode: true,
            icon: 'success',
            title: 'Tamamlandı',
            text: 'Özel alana ait çeviri başarıyla eklendi.',
            buttons: 'Tamam'
          }).then(() => this.getCategoryFieldDetail(), this.setState({isOpenAddedCategoryFieldLanguageModal: false}), categoryFieldLanguageInfo.name = '', categoryFieldLanguageInfo.language = '', categoryFieldLanguageInfo.description = '');
        }
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  updateCategoryFieldLanguageOnClick(categoryFieldLanguageInfo, id) {
    
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'PUT',
      headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
      body: JSON.stringify(categoryFieldLanguageInfo)
    };

    fetch(config.restApi + 'fieldLanguage/tr/' + id, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch (responseData.status) {
        case 200: {
          swal({
            dangerMode: true,
            icon: 'success',
            title: 'Tamamlandı',
            text: 'Çeviri başarıyla güncellendi.',
            buttons: 'Tamam'
          }).then(() => this.getCategoryFieldDetail(), this.setState({isOpenAddedCategoryFieldLanguageModal: false, editCategoryFieldLanguages: categoryFieldLanguageInfo}));
        }
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  deleteCategoryFieldLanguageOnClick(id) {
    swal({
      dangerMode: true,
      icon: 'warning',
      title: 'Onayla',
      text: 'Çeviriyi silmek istediğinize emin misiniz?',
      buttons: {confirm: 'Tamam', cancel: 'Vazgeç'}
    }).then(value => {
      if (value) {
        let sessionHash = getCookie('sessionHash');

        const requestOptions = {
          method: 'DELETE',
          headers: {'Authorization': 'Bearer ' + sessionHash}
        };

        fetch(config.restApi + 'fieldLanguage/tr/' + id, requestOptions)
        .then(response => response.json())
        .then(responseData => {
          switch (responseData.status) {
            case 200: {
              swal({
                dangerMode: true,
                icon: 'success',
                title: 'Tamamlandı',
                text: 'Çeviri başarıyla silindi.',
                buttons: 'Tamam'
              }).then(() => this.getCategoryFieldDetail());
            }
            break;
            default: {
              swal({
                dangerMode: true,
                icon: 'warning',
                title: 'Hay Aksi',
                text: responseData.message,
                buttons: 'Tamam'
              });
            }
            break;
          }
        }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
      }
    });
  }

  render() {
    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <>
      <div className="row">
        <div className="col">
          <div className="ibox">
            <div className="ibox-title text-darkblue d-flex justify-content-between pr-2">
              <h5>Özel Alan Düzenle</h5>
              <div>
                <button className="btn btn-orange mr-2" title="Kaydet" onClick={() => this.saveCategoryFieldOnClick()}><i className="fa fa-save"></i> Kaydet</button>
                <button className="btn btn-darkblue text-white" title="Geri" onClick={() => {this.props.navigate(-1)}}><i className="fa fa-reply"></i> Geri</button>
              </div>
            </div>
            <div className="ibox-content">  
              <div className="row mb-5">
                <div className="col-md-6">
                  <h5 className="text-darkblue"><strong>Sıralama<strong className="text-danger ml-1">*</strong></strong></h5>
                  <input className="form-control form-input mb-2 mt-1" min="0" onChange={(event) => this.setState({sort: event.target.value})} placeholder="Sıralama" type="number" value={this.state.sort} />
                </div>
                <div className="col-md-6">
                  <strong className="text-darkblue">Alan Tipi<strong className="text-danger ml-1">*</strong></strong>
                  <select className="form-control form-control-sm form-input mb-2 mt-1"  onChange={this.fieldTypeOnChange} value={this.state.type}>
                    <option value="" disabled>Seçiniz</option>
                    {this.state.customWidgetList.map((customWidgetListInfo) => <option key={customWidgetListInfo.id} value={customWidgetListInfo.name}>{customWidgetListInfo.name}</option>)}
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="d-flex justify-content-between">
                    <h4 className="text-darkblue">Özel Alana Ait Çeviri Listesi</h4>
                    <button className="btn btn-orange" onClick={() => this.setState({editCategoryFieldLanguages: null, isOpenAddedCategoryFieldLanguageModal: true})} title="Dil Ekle"><i className="fa fa-plus"></i> Dil Ekle</button>
                  </div>
                  <div className="hr-line-dashed"></div>
                  <div className="row">
                    <div className="col">
                    <div className="overflow-auto">
                      <table className="table table-striped table-bordered">
                      <thead>
                        <tr className="text-center text-darkblue">
                          <th>Çeviri</th>
                          <th>Dil</th>
                          <th>Açıklama</th>
                          <th>İşlem</th>
                        </tr>
                      </thead>
                      <tbody className="text-center">
                        {this.state.fieldLanguages.map((fieldLanguageInfo) =>
                          <tr key={fieldLanguageInfo.id}>
                            <td className="align-middle text-orange">{fieldLanguageInfo.name}</td>
                            <td className="align-middle">{fieldLanguageInfo.language}</td>
                            <td className="align-middle">{fieldLanguageInfo.description}</td>
                            <td className="align-middle">
                              <button onClick={() => this.setState({isOpenAddedCategoryFieldLanguageModal: true, fieldLanguageId: fieldLanguageInfo.id, editCategoryFieldLanguages: fieldLanguageInfo})} className="btn btn-outline btn-orange text-center mr-2" title="Düzenle"><i className="fa fa-pencil text-white action-icon"></i></button>
                              <button className="btn btn-outline btn-darkblue" onClick={() => this.deleteCategoryFieldLanguageOnClick(fieldLanguageInfo.id)} title="Sil"><i className="fa fa-trash"></i></button>
                            </td>
                          </tr>
                        )}
                        {this.state.fieldLanguages.length === 0 ?
                          <tr>
                            <td className="align-middle p-3" colSpan="4">Çeviri Listesi Bulunamadı !</td>
                          </tr>
                        : null}
                      </tbody> 
                      </table>
                    </div>
                    </div>
                  </div>
                  <AddedCategoryFieldLanguageModal isOpen={this.state.isOpenAddedCategoryFieldLanguageModal} modalTitle="Çeviri Ekle" modalDescription="Özel alana ait çeviri eklemek için aşağıdaki alanları doldurunuz !" onRequestClose={() => this.setState({editCategoryFieldLanguages: null, isOpenAddedCategoryFieldLanguageModal: false})} saveOnClick={categoryFieldLanguageInfo => this.saveCategoryFieldLanguageOnClick(categoryFieldLanguageInfo, this.props.searchParams.get('id'))} />
                  {this.state.editCategoryFieldLanguages ?
                  <AddedCategoryFieldLanguageModal defaultValue={this.state.editCategoryFieldLanguages} isOpen={this.state.isOpenAddedCategoryFieldLanguageModal} modalTitle="Çeviri Düzenle" modalDescription="Çeviri bilgisini düzenlemek için aşağıdaki bilgileri doldurunuz !" onRequestClose={() => this.setState({editCategoryFieldLanguages: null, isOpenAddedCategoryFieldLanguageModal: false})} saveOnClick={categoryFieldLanguageInfo => this.updateCategoryFieldLanguageOnClick(categoryFieldLanguageInfo, this.state.fieldLanguageId)} />
                  : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
  }
}

export default function AttributeGroupEdit() {
  return (
    <AttributeGroupEditModule navigate={useNavigate()} searchParams={useSearchParams()[0]} />
  )
}