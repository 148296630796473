import React from 'react';
import Modal from 'react-modal';

import swal from 'sweetalert';

import {getCookie} from '../inc/cookies';
import config from '../inc/config';
import {patternNumber} from '../inc/regexValidate';

export default class PhoneModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,

      phoneInfo: {
        id: this.props.defaultValue && this.props.defaultValue.id || Date.now(),
        countryId: this.props.defaultValue && this.props.defaultValue.countryId || 1,
        countryName: this.props.defaultValue && this.props.defaultValue.countryName || 'Türkiye',
        phoneTypeId: this.props.defaultValue && this.props.defaultValue.phoneTypeId || 0,
        phoneTypeName: this.props.defaultValue && this.props.defaultValue.phoneTypeName || '',
        phoneNumber: this.props.defaultValue && this.props.defaultValue.phoneNumber || '',
        defaultSelected: this.props.defaultValue && this.props.defaultValue.defaultSelected || 0,
        contactSelected: this.props.defaultValue && this.props.defaultValue.contactSelected || 0,
        active: this.props.defaultValue && this.props.defaultValue.active || 1
      },

      phoneTypes: [],
      countries: []
    };

    this.countryOnChange = this.countryOnChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.phoneTypeOnChange = this.phoneTypeOnChange.bind(this);
  }

  componentDidMount() {
    this.getCountries();
    this.getPhoneTypes();
  }

  getCountries() {
    let sessionHash = getCookie('sessionHash');

    this.setState({isLoading: true});

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'countries/tr', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({isLoading: false, countries: responseData.countries});
        }
        break;
        default: {
          this.setState({isLoading: false});

          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  getPhoneTypes() {
    let sessionHash = getCookie('sessionHash');

    this.setState({isLoading: true});

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'phoneTypes?filterActive=1', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({isLoading: false, phoneTypes: responseData.phoneTypes});
        }
        break;
        default: {
          this.setState({isLoading: false});

          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  countryOnChange(event) {
    let countryId = event.target.value;
    if (countryId) {
      this.setState({phoneInfo: {...this.state.phoneInfo, countryId: parseInt(countryId), countryName: event.target.options[event.target.selectedIndex].text}});
    } else {
      this.setState({phoneInfo: {...this.state.phoneInfo, countryId: '', countryName: ''}});
    }
  }

  phoneTypeOnChange(event) {
    this.setState({phoneInfo: {...this.state.phoneInfo, phoneTypeId: parseInt(event.target.value) || '', phoneTypeName: event.target.options[event.target.selectedIndex].text}})
  }
  
  handleKeyPress(event) {
    if (event.key === 'Enter') {
      this.saveOnClick();
      event.preventDefault();
    }
  }

  saveOnClick() {
    let infoMessage = '';
    if (!this.state.phoneInfo.phoneTypeId) {
      infoMessage = 'Telefon tipi boş geçilemez !';
    } else if (!patternNumber.test(this.state.phoneInfo.phoneNumber)) {
      infoMessage = 'Geçersiz telefon numarası girdiniz !';
    } else if (!this.state.phoneInfo.countryId) {
      infoMessage = 'Ülke seçeneği boş geçilemez !';
    }

    if (infoMessage) {
      swal({
        dangerMode: true,
        icon: 'info',
        title: 'Eksik Bilgi',
        text: infoMessage,
        buttons: 'Tamam'
      });
    } else {
      this.props.saveOnClick(this.state.phoneInfo);
      this.setState({phoneInfo: {id: Date.now(), countryId: 1, countryName: 'Türkiye', phoneTypeId: 0, phoneTypeName: '', phoneNumber: '', defaultSelected: 0, contactSelected: 0, active: 1}});
    }
  }
  
  render() {
    return (
      <div className="modal inmodal">
        <div className="modal-dialog">
          <div className="modal-content animated fadeIn">
          <Modal
            isOpen={this.props.isOpen}
            onRequestClose={this.props.onRequestClose}
            style={{
              content : {
                position : 'null',
                top : 'null',
                left : 'null',
                right : 'null',
                bottom : 'null',
                border : 'none',
                background : '#fff',
                padding : 'null',
                maxWidth : '50%',
                minWidth : '350px'
              },
              overlay : {
                position : 'fixed',
                top : 0,
                left : 0,
                right : 0,
                bottom : 0,
                display : 'flex',
                alignItems : 'center',
                justifyContent : 'center',
                backgroundColor : 'rgba(0,0,0,00.1)',
                zIndex:2500
              },
            }}
          >
            {this.state.isLoading ?
              <div className="ibox-content sk-loading position-static">
                <div className="sk-spinner sk-spinner-fading-circle">
                  <div className="sk-circle1 sk-circle"></div>
                  <div className="sk-circle2 sk-circle"></div>
                  <div className="sk-circle3 sk-circle"></div>
                  <div className="sk-circle4 sk-circle"></div>
                  <div className="sk-circle5 sk-circle"></div>
                  <div className="sk-circle6 sk-circle"></div>
                  <div className="sk-circle7 sk-circle"></div>
                  <div className="sk-circle8 sk-circle"></div>
                  <div className="sk-circle9 sk-circle"></div>
                  <div className="sk-circle10 sk-circle"></div>
                  <div className="sk-circle11 sk-circle"></div>
                  <div className="sk-circle12 sk-circle"></div>
                </div>
              </div>
            :
            <>
              <div className="modal-header d-block text-center text-darkblue">
                <button className="close" onClick={this.props.onRequestClose}><span>×</span></button>
                <h4 className="modal-title">{this.props.modalTitle}</h4>
                <h5 className="font-normal">{this.props.modalDescription}</h5>
              </div>
              <div className="modal-body text-darkblue">
                <strong>Telefon Tipi<strong className="text-danger ml-1">*</strong></strong>
                <select className="form-control form-control-sm form-input mb-3 mt-1" onChange={this.phoneTypeOnChange} onKeyDown={this.handleKeyPress} value={this.state.phoneInfo.phoneTypeId}>
                  <option value="" >Seçiniz</option>
                  {this.state.phoneTypes.map(phoneTypeInfo => <option key={phoneTypeInfo.id} value={phoneTypeInfo.id}>{phoneTypeInfo.name}</option>)}
                </select>
                <strong>Ülke<strong className="text-danger ml-1">*</strong></strong>
                <select className="form-control form-control-sm form-input mb-3 mt-1" onChange={this.countryOnChange} onKeyDown={this.handleKeyPress} value={this.state.phoneInfo.countryId}>
                  <option value="">Seçiniz</option>
                  {this.state.countries.map(countryInfo => <option key={countryInfo.id} value={countryInfo.id}>{countryInfo.name}</option>)}
                </select>
                <strong>Telefon Numarası<strong className="text-danger ml-1">*</strong></strong>
                  <input className="form-control form-input mb-3 mt-1" onChange={event => this.setState({phoneInfo: {...this.state.phoneInfo, phoneNumber: event.target.value.replace(/\D+/g, '')}})} onKeyDown={this.handleKeyPress}  placeholder="Telefon Numarası" type="tel" value={this.state.phoneInfo.phoneNumber} />
              </div>
              <div className="modal-footer">
                <button className="btn btn-orange" onClick={() => this.saveOnClick()}>Kaydet</button>
              </div>
            </>
            }
          </Modal>
          </div>
        </div>
      </div>
    )
  }
}