import React from 'react';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import swal from 'sweetalert';

import CrmMemberMarketerAddModal from '../../../component/crmMemberAppointmentModals/CrmMemberMarketerAddModal';

import {getCookie} from '../../../inc/cookies';
import config from '../../../inc/config';
import {isoToMysqlDateTime, inputDateTimeToIsoDateTime} from '../../../inc/helper';

class CrmAppointmentAddModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      priority: [
        {id:1, name: "Sıcak"},
        {id:2, name: "Ilık"},
        {id:3, name: "Soğuk"}
      ],

      crmMemberAppointment: {
        crmMemberId: 0,
        appointmentDate: null,
        comment: '',
        crmMemberAppointmentHistoryComment: '',
        crmMemberAppointmentStatusId: 0,
        crmMemberAppointmentMarketerIds: [],
        priority: 2
      },

      crmMember: {},
      crmMemberAppointmentStatuses: [],
      crmMemberMarketers: []
    };

    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.saveCrmMemberMarketerOnClick = this.saveCrmMemberMarketerOnClick.bind(this);
  }

  componentDidMount() {
    this.getCrmMember();
    window.scrollTo(0, 0);
  }

  getCrmMember() {
    let sessionHash = getCookie('sessionHash');

    this.setState({isLoading: true});

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    let errorMessages = [];
    let remaining = 1;
    let complete = () => {
      remaining--;
      if (remaining === 0) {
        if (errorMessages.length > 0) {
          swal({dangerMode: true, icon: 'warning', title: 'Hay Aksi', text: errorMessages[0], buttons: 'Tamam'}).then(this.props.navigate(-1));
        }else{
          this.setState({isLoading: false});
        }
      }
    }

    fetch(config.restApi + 'crmMember/tr/' + this.props.searchParams.get('id'), requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({crmMember: responseData.crmMember});
        }
        break;
        case 401:  {
          errorMessages.push(responseData.message)
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));

    fetch(config.restApi + 'crmMemberAppointmentStatuses?filterActive=1', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({crmMemberAppointmentStatuses: responseData.crmMemberAppointmentStatuses});
        }
        break;
        case 401:  {
          errorMessages.push(responseData.message)
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));
  }

  saveCrmMemberMarketerOnClick(companyMarketerInfo) {

    let crmMemberMarketers = this.state.crmMemberMarketers;
    crmMemberMarketers.push(companyMarketerInfo);

    let crmMemberAppointmentMarketerIds = crmMemberMarketers.map((crmMemberMarketerInfo,index) => crmMemberMarketerInfo.id)

    this.setState({crmMemberAppointment: {...this.state.crmMemberAppointment, crmMemberAppointmentMarketerIds: crmMemberAppointmentMarketerIds}, isOpenCrmMemberMarketerAddModal: false});
  }

  deleteCrmMemberMarketerOnClick(index) {
    swal({
      dangerMode: true,
      icon: 'warning',
      title: 'Onayla',
      text: 'Satış sorumlusunu silmek istediğinize emin misiniz?',
      buttons: {confirm: 'Tamam', cancel: 'Vazgeç'}
    }).then(value => {
      if (value) {
        let crmMemberMarketers = this.state.crmMemberMarketers;
        crmMemberMarketers.splice(index, 1)

        let crmMemberAppointmentMarketerIds = crmMemberMarketers.map((crmMemberMarketerInfo,index) => crmMemberMarketerInfo.id)

        this.setState({crmMemberAppointment: {...this.state.crmMemberAppointment, crmMemberAppointmentMarketerIds: crmMemberAppointmentMarketerIds}});

        swal({
          dangerMode: true,
          icon: 'success',
          title: 'Tamamlandı',
          text: 'Satış sorumlusu başarıyla silindi.',
          buttons: 'Tamam'
        });
      }
    });
  }

  handleKeyPress(event) {
    if(event.key === 'Enter') {
      this.saveCrmMemberAppointmentOnClick();
    }
  }

  saveCrmMemberAppointmentOnClick = (event) => {
    let sessionHash = getCookie('sessionHash');

    let infoMessage = '';
    if(!this.state.crmMemberAppointment.appointmentDate) {
      infoMessage = 'Randevu tarihi boş geçilemez !';
    } else if(!this.state.crmMemberAppointment.crmMemberAppointmentStatusId) {
      infoMessage = 'Potansiyel üye durum bilgisi boş geçilemez !';
    }/*  else if(!this.state.crmMemberAppointment.crmMemberAppointmentHistoryComment) {
      infoMessage = 'Açıklama boş geçilemez !';
    } */ else if(!this.state.crmMemberAppointment.comment) {
      infoMessage = 'Not boş geçilemez !';
    } else if(!this.state.crmMemberAppointment.crmMemberAppointmentMarketerIds) {
      infoMessage = 'Satış temsilcisi boş geçilemez !';
    }

    if (infoMessage) {
      swal({
        dangerMode: true,
        icon: 'info',
        title: 'Eksik Bilgi',
        text: infoMessage,
        buttons: 'Tamam'
      });
    }  else {
      const selectedDate = this.state.crmMemberAppointment.appointmentDate;
      const currentDate = new Date().toISOString();
      if (selectedDate < currentDate) {
        swal({
          dangerMode: true,
          icon: 'warning',
          title: 'Uyarı',
          text: 'Randevu tarihi mevcut günden önce olamaz!',
          buttons: 'Tamam'
        });
      } else {
      this.setState({isLoading: true});

      const requestOptions = {
        method: 'POST',
        headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
        body: JSON.stringify(this.state.crmMemberAppointment)
      };

        fetch(config.restApi + 'crmMemberAppointment/tr/' + this.state.crmMember.id, requestOptions)
        .then(response => response.json())
        .then(responseData => {
          switch(responseData.status) {
            case 200: {
              this.setState({isLoading: false});
              swal({
                dangerMode: true,
                icon: 'success',
                title: 'Tamamlandı',
                text: 'Randevu başarıyla eklendi.',
                buttons: 'Tamam'
              }).then(() => this.props.navigate('/crmMemberAppointmentEdit?id=' + responseData.crmMemberAppointmentId));
            }
            break;
            default: {
              this.setState({isLoading: false});
    
              swal({
                dangerMode: true,
                icon: 'warning',
                title: 'Hay Aksi',
                text: responseData.message,
                buttons: 'Tamam'
              });
            }
            break;
          }
        }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
      }
    }
  }

  handlePriorityChange = (event) => {
    const selectedPriority = parseInt(event.target.value);
    this.setState({crmMemberAppointment: { ...this.state.crmMemberAppointment, priority: selectedPriority}});
  };

  render() {
    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <>
        <div className="row">
          <div className="col">
            <div className="d-flex ibox justify-content-end">
              <button className="btn btn-orange mr-2" title="Kaydet" onClick={() => this.saveCrmMemberAppointmentOnClick()}><i className="fa fa-save"></i></button>
              <Link className="btn btn-darkblue text-white" title="Geri" to={"/crmMemberEdit?id=" + this.state.crmMember.id}><i className="fa fa-reply"></i></Link>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="ibox">
              <div className="ibox-content text-darkblue">
                <div className=" d-flex justify-content-between">
                  <h4>Randevu Ekle</h4>
                  <h4><i className="fa fa-user-circle-o mr-2"></i>{this.state.crmMember.firstname} {this.state.crmMember.lastname}</h4>
                </div>
                <div className="hr-line-dashed"></div>
                <div className="row mb-2">
                  <div className="col">
                    <h5><strong>Randevu Tarihi<strong className="text-danger ml-1">*</strong></strong></h5>
                    <input className="form-control form-control-sm form-input mb-2 mt-1" value={this.state.crmMemberAppointment.appointmentDate ? isoToMysqlDateTime(this.state.crmMemberAppointment.appointmentDate) : ''} onChange={(event) => this.setState({crmMemberAppointment: {...this.state.crmMemberAppointment, appointmentDate: inputDateTimeToIsoDateTime(event.target.value) || null}})} onKeyDown={this.handleKeyPress} type="datetime-local" />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="ibox">
                      <div className="ibox-content text-darkblue">
                        <div className="d-flex justify-content-between">
                          <h4>Satış Sorumlusu<strong className="text-danger ml-1">*</strong></h4>
                          <button className="btn btn-orange" onClick={() => this.setState({isOpenCrmMemberMarketerAddModal: true})} title="Satış Sorumlusu Ekle"><i className="fa fa-plus"></i></button>
                        </div>
                        <div className="hr-line-dashed"></div>
                        <div className="overflow-auto">
                          <table className="table table-striped table-bordered">
                          <thead>
                            <tr className="text-center text-darkblue">
                              <th>Satış Sorumlusu</th>
                              <th>İşlem</th>
                            </tr>
                          </thead>
                          <tbody className="text-center">
                            {this.state.crmMemberMarketers.map((crmMemberMarketerInfo, index) =>
                              <tr key={index}>
                                <td className="align-middle">{crmMemberMarketerInfo.fullName}</td>
                                <td className="align-middle">
                                  <button className="btn btn-darkblue" onClick={() => this.deleteCrmMemberMarketerOnClick(index)} title="Sil"><i className="fa fa-trash"></i></button>
                                </td>
                              </tr>
                            )}
                            {this.state.crmMemberMarketers.length === 0 ?
                              <tr>
                                <td className="align-middle p-3" colSpan="2">Satış Sorumlusu Bulunamadı !</td>
                              </tr>
                            : null}
                          </tbody>
                          </table>
                          <CrmMemberMarketerAddModal isOpen={this.state.isOpenCrmMemberMarketerAddModal} modalTitle="Satış Sorumlusu Ekle" modalDescription="Satış sorumlusu bilgisi eklemek için aşağıdaki bilgileri doldurunuz !" onRequestClose={() => this.setState({isOpenCrmMemberMarketerAddModal: false})} saveOnClick={companyMarketerInfo => this.saveCrmMemberMarketerOnClick(companyMarketerInfo)}/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-sm-10">
                  <h5><strong>Randevu Önceliği<strong className="text-danger ml-1">*</strong></strong></h5>
                  {this.state.priority.map((priority, index) => (
                  <div className="align-middle" key={index}>
                    <div className="d-flex">
                      <label className="d-flex cursor">
                        <input className="mr-1" type="radio" id={`optionsRadios${priority.id}`} name="priority" onChange={this.handlePriorityChange} value={priority.id}  defaultChecked={this.state.crmMemberAppointment.priority === priority.id}/>
                        {priority.name}
                      </label>
                    </div>
                  </div>
                  ))}
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col">
                    <h5><strong>Potansiyel Üye Durumu<strong className="text-danger ml-1">*</strong></strong></h5>
                    <select className="form-control form-control-sm form-input mb-2 mt-1" onChange={(event) => this.setState({crmMemberAppointment: {...this.state.crmMemberAppointment, crmMemberAppointmentStatusId: parseInt(event.target.value) || ''}})} onKeyDown={this.handleKeyPress} value={this.state.crmMemberAppointment.crmMemberAppointmentStatusId}>
                      <option value="">Seçiniz</option>
                      {this.state.crmMemberAppointmentStatuses.map(crmMemberAppointmentStatusInfo => <option key={crmMemberAppointmentStatusInfo.id} value={crmMemberAppointmentStatusInfo.id}>{crmMemberAppointmentStatusInfo.name}</option>)}
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <strong>Açıklama<strong className="text-danger ml-1"></strong></strong>
                    <textarea className="form-control form-control-sm form-input mb-2 mt-1" disabled placeholder="Açıklama yazınız.." onChange={event => this.setState({crmMemberAppointment: {...this.state.crmMemberAppointment, crmMemberAppointmentHistoryComment: event.target.value.trimStart()}})} onKeyDown={this.handleKeyPress} type="text" value={this.state.crmMemberAppointment.crmMemberAppointmentHistoryComment} />
                  </div>
                </div>
                <div className="row">
                  <div className="col mb-3">
                    <strong className="text-darkblue">Not<strong className="text-danger ml-1">*</strong></strong>
                    <textarea className="form-control form-input mb-2 mt-1" onChange={event => this.setState({crmMemberAppointment: {...this.state.crmMemberAppointment, comment: event.target.value.trimStart()}})} onKeyDown={this.handleKeyPress} placeholder="Potansiyel üye hakkında açıklama giriniz." value={this.state.crmMemberAppointment.comment} />
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                <button className="btn btn-orange" onClick={() => this.saveCrmMemberAppointmentOnClick()}>Kaydet</button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </>
  }
}

export default function CrmAppointmentAdd() {
  return (
    <CrmAppointmentAddModule navigate={useNavigate()} searchParams={useSearchParams()[0]} />
  )
}