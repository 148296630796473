import React from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import swal from 'sweetalert';

import {getCookie} from '../../inc/cookies';
import config from '../../inc/config';
import {isoToDateTime, priceFormat} from '../../inc/helper';

class MySalesModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,

      filterPaymentStartDate: '',
      filterPaymentEndDate: '',

      mySales: []
    };
  }

  componentDidMount() {
    this.getMySales();
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
  }

  getMySales() {
    let sessionHash = getCookie('sessionHash');

    this.setState({isLoading: true});

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'mySales/tr', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({isLoading: false, mySales: responseData.mySales});
        }
        break;
        default: {
          this.setState({isLoading: false});

          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          }).then(() => this.props.navigate('/home'));
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  getInvoiceStatus(mySalesInfo) {
    if (!mySalesInfo.billingPeriod) {
      return <span className="text-orange font-bold">Fatura Bekleniyor <i className="fa fa-clock-o"></i></span>
    } else if (mySalesInfo.locked == 0) {
      return <span className="text-warning font-bold">Fatura Oluşturuldu. Onay Bekleniyor <i className="fa fa-clock-o"></i></span>
    } else if (mySalesInfo.locked == 1) {
      return <span className="text-green font-bold">Onaylandı <i className="fa fa-check"></i></span>
    }
  }

  filteredMySales(query) {
    let sessionHash = getCookie('sessionHash');

    this.setState({isLoading: true});

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'mySales/tr?' + query, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({isLoading: false, mySales: responseData.mySales});
        }
        break;
        default: {
          this.setState({isLoading: false});

          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  filterOnClick = (event) => {
    this.filteredMySales('filterPaymentStartDate=' + this.state.filterPaymentStartDate + '&filterPaymentEndDate=' + this.state.filterPaymentEndDate);
  }

  resetOnClick = (event) => {
    this.setState({
      filterPaymentStartDate: '',
      filterPaymentEndDate: ''
    });
    
    this.getMySales();
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.filterOnClick();
    }
  }

  render() {
    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <>
        <div className="row">
          <div className="col-xl-9">
            <div className="ibox">
              <div className="ibox-title text-darkblue d-flex justify-content-between">
                <h5>Satışlarım</h5>
              </div>
              <div className="table-responsive overflow-x-auto">
                <table className="table table-striped table-bordered">
                  <thead>
                    <tr className="text-center text-darkblue">
                      <th>İşlem İçeriği</th>
                      <th>Toplam Tutar</th>
                      <th>Satış Sorumluları</th>
                      <th>Fatura Durumu</th>
                      <th>Ödeme Tarihi</th>
                      <th>Fatura Tarihi</th>  
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {this.state.mySales.map((mySalesInfo) =>
                      <tr key={mySalesInfo.id}>
                        <td className="align-middle">{mySalesInfo.transactionProducts || '-'}</td>
                        <td className="align-middle">{priceFormat(mySalesInfo.transactionPrice)}</td>
                        <td className="align-middle">{(mySalesInfo.marketers.map((marketerInfo) => <h5 className="font-normal" key={marketerInfo.id}>{marketerInfo.fullName + ' (' + priceFormat(marketerInfo.price) + ' ₺)'}</h5>))}</td>
                        <td className="align-middle">{this.getInvoiceStatus(mySalesInfo)}</td>
                        <td className="align-middle">{mySalesInfo.paymentDate ? isoToDateTime(mySalesInfo.paymentDate) : '-'}</td>
                        <td className="align-middle">{mySalesInfo.billingPeriod ? isoToDateTime(mySalesInfo.billingPeriod) : '-'}</td>
                      </tr>
                    )}
                    {this.state.mySales.length === 0 &&
                     <tr>
                        <td className="align-middle p-3" colSpan="6">Satış Bulunamadı !</td>
                      </tr>
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="col-xl-3 order-first order-xl-last d-xl-none" id="accordion">
            <div className="ibox">
              <div className="ibox-content text-darkblue p-0">
                <h4 className="collapse-title">
                  <a className="btn btn-link text-darkblue font-bold d-flex justify-content-between" data-toggle="collapse" href="#filterCollapse" title="Filtrele">Filtrele</a>
                </h4>
              </div>
              <div className="collapse" data-parent="#accordion" id="filterCollapse">
                <div className="ibox-content text-darkblue">
                  <strong>Ödeme Başlangıç Tarihi</strong>
                  <input className="form-control form-input" onChange={event => this.setState({filterPaymentStartDate: event.target.value})} onKeyDown={this.handleKeyPress} type="date" value={this.state.filterPaymentStartDate} />
                  <strong>Ödeme Bitiş Tarihi</strong>
                  <input className="form-control form-input" onChange={event => this.setState({filterPaymentEndDate: event.target.value})} onKeyDown={this.handleKeyPress} type="date" value={this.state.filterPaymentEndDate} />
                  <div className="d-flex justify-content-end">
                    <button className="btn btn-orange mt-3 mr-2" onClick={this.resetOnClick}><i className="fa fa-refresh"></i> Sıfırla</button>
                    <button className="btn btn-orange mt-3" onClick={this.filterOnClick}><i className="fa fa-filter"></i> Filtrele</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 order-first order-xl-last d-none d-xl-block">
            <div className="ibox">
              <div className="ibox-title text-darkblue">
                <h5><i className="fa fa-filter"></i> Filtrele</h5>
              </div>
              <div className="ibox-content text-darkblue">
                <strong>Ödeme Başlangıç Tarihi</strong>
                <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterPaymentStartDate: event.target.value})} onKeyDown={this.handleKeyPress} type="date" value={this.state.filterPaymentStartDate} />
                <strong>Ödeme Bitiş Tarihi</strong>
                <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({filterPaymentEndDate: event.target.value})} onKeyDown={this.handleKeyPress} type="date" value={this.state.filterPaymentEndDate} />
                <div className="d-flex justify-content-end">
                  <button className="btn btn-orange mt-3 mr-2" onClick={this.resetOnClick}><i className="fa fa-refresh"></i> Sıfırla</button>
                  <button className="btn btn-orange mt-3" onClick={this.filterOnClick}><i className="fa fa-filter"></i> Filtrele</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
  }
}

export default function MySales() {
  return (
    <MySalesModule navigate={useNavigate()} searchParams={useSearchParams()[0]} />
  )
}