import React from 'react';
import {useNavigate, Link} from 'react-router-dom';
import swal from 'sweetalert';

import config from '../../../inc/config';
import {getCookie} from '../../../inc/cookies';

import AddedCategoryAttributeModal from '../../../component/category/categoryAttribute/AddedCategoryAttributeModal';

class CategoryAttributesModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isOpenAddedCategoryAttributeModal: false,

      attributes: []
    };

    this.onCompleted = this.onCompleted.bind(this);
  }

  componentDidMount() {
    this.getCategoryAttributes();
    window.scrollTo(0, 0);
  }

  getCategoryAttributes() {

    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'attributes/tr/', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200:
          this.setState({isLoading: false, attributes: responseData.attributes})
        break;
        case 401:
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          }).then(this.props.navigate('/home'));
        break;
        default:
          this.setState({isLoading: false});

          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          }).then(() => this.props.navigate('/'));
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Yeniden Dene'}).then(() => this.getCategoryAttributes()));
  }

  deleteCategoryAttributeOnClick(id) {
    swal({
      dangerMode: true,
      icon: 'warning',
      title: 'Onayla',
      text: 'Özelliği silmek istediğinize emin misiniz?',
      buttons: {confirm: 'Tamam', cancel: 'Vazgeç'}
    }).then(value => {
      if (value) {
        let sessionHash = getCookie('sessionHash');

        const requestOptions = {
          method: 'DELETE',
          headers: {'Authorization': 'Bearer ' + sessionHash}
        };

        fetch(config.restApi + 'attribute/tr/' + id, requestOptions)
        .then(response => response.json())
        .then(responseData => {
          switch (responseData.status) {
            case 200: {
              swal({
                dangerMode: true,
                icon: 'success',
                title: 'Tamamlandı',
                text: 'Özellik başarıyla silindi.',
                buttons: 'Tamam'
              }).then(() => this.getCategoryAttributes());
            }
            break;
            default: {
              swal({
                dangerMode: true,
                icon: 'warning',
                title: 'Hay Aksi',
                text: responseData.message,
                buttons: 'Tamam'
              });
            }
            break;
          }
        }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
      }
    });
  }

  onCompleted() {
    this.getCategoryAttributes();
    this.setState({isOpenAddedCategoryAttributeModal: false});
  }

  render() {
    return this.state.isLoading ?
    <div className="ibox-content sk-loading position-static">
    <div className="sk-spinner sk-spinner-fading-circle">
      <div className="sk-circle1 sk-circle"></div>
      <div className="sk-circle2 sk-circle"></div>
      <div className="sk-circle3 sk-circle"></div>
      <div className="sk-circle4 sk-circle"></div>
      <div className="sk-circle5 sk-circle"></div>
      <div className="sk-circle6 sk-circle"></div>
      <div className="sk-circle7 sk-circle"></div>
      <div className="sk-circle8 sk-circle"></div>
      <div className="sk-circle9 sk-circle"></div>
      <div className="sk-circle10 sk-circle"></div>
      <div className="sk-circle11 sk-circle"></div>
      <div className="sk-circle12 sk-circle"></div>
    </div>
  </div>
  :
  <>
  <div className="row">
    <div className="col">
      <div className="ibox">
        <div className="ibox-title d-flex justify-content-between text-darkblue pr-3">
          <h5>Özellik Listesi</h5>
          <button className="btn btn-orange" onClick={() => this.setState({isOpenAddedCategoryAttributeModal: true})} title="Ekle"><i className="fa fa-plus"></i></button>
        </div>
        <div className="ibox-content">
          <div className="row mb-3">
            {this.state.attributes.length !== 0 ?
              this.state.attributes.map((attributeInfo) =>
                <div className="col-12 mt-2" key={attributeInfo.id}>
                <div className="d-flex justify-content-start">
                  <h4 className="text-darkblue">{attributeInfo.name}</h4>
                  <h5 className="ml-1 d-flex align-items-end">{"(" + attributeInfo.description + ")"}</h5>
                </div>
                <div className="overflow-auto mt-2">
                  <table className="table table-striped table-bordered">
                  <thead>
                    <tr className="text-center text-darkblue">
                      <th>Özellik Adı</th>
                      <th>Açıklama</th>
                      <th>Sıralama</th>
                      <th>Durum</th>
                      <th>İşlem</th>
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {attributeInfo.items.map((itemInfo) =>
                      <tr key={itemInfo.id}>
                        <td>{itemInfo.name}</td>
                        <td>{itemInfo.description}</td>
                        <td>{itemInfo.sort}</td>
                        <td>{itemInfo.active === 1 ? 'Açık' : 'Kapalı'}</td>
                        <td className="align-middle" style={{minWidth: 98.5}}>
                          <Link className="btn btn-outline btn-orange text-center m-1" title="Düzenle" to={'/categoryAttributeEdit?id=' + itemInfo.id}><i className="fa fa-pencil text-white action-icon"></i></Link>
                          <button className="btn btn-outline btn-darkblue" onClick={() => this.deleteCategoryAttributeOnClick(itemInfo.id)} title="Sil"><i className="fa fa-trash"></i></button>
                        </td>
                      </tr>
                      )
                    }
                  </tbody>
                  </table>
                  <div className="d-flex justify-content-end">
                    <button className="btn btn-orange" onClick={() => this.setState({isOpenAddedCategoryAttributeModal: true})} title="Ekle"><i className="fa fa-plus"></i></button>
                  </div>
                </div>
              </div>
            )
            : <div className='mx-auto'>Özellik Bulunamadı !</div>}
          </div>
        </div>
        <AddedCategoryAttributeModal isOpen={this.state.isOpenAddedCategoryAttributeModal} modalTitle="Özellik Ekle" modalDescription="İlgili gruba ait özellik eklemek için aşağıdaki alanları doldurunuz !" onCompleted={this.onCompleted}onRequestClose={() => this.setState({isOpenAddedCategoryAttributeModal: false})} />
      </div>
    </div>
  </div>
  </>
  }
}

export default function CategoryAttributes() {
  return (
    <CategoryAttributesModule navigate={useNavigate()} />
  )
}
