import React from 'react';
import Modal from 'react-modal';
import { Navigate } from 'react-router-dom';
import swal from 'sweetalert';

import {getCookie} from '../../inc/cookies';
import config from '../../inc/config';
import {inputDateTimeToIsoDateTime, isoToMysqlDateTime} from '../../inc/helper';

export default class MemberWorkItemAddModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isCompleted: true,
      
      memberWorkItemInfo: {
        workItemId: 0,
        deliveryDate: null,
        deliveryNote: '',
        returnDate: null,
        returnNote: ''
      },

      workItems: []
    };

    this.deliveryDateOnChange = this.deliveryDateOnChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.memberWorkItemOnChange = this.memberWorkItemOnChange.bind(this);
    this.returnDateOnChange = this.returnDateOnChange.bind(this);
  }

  componentDidMount() {
    this.getWorkItems();
    window.scrollTo(0, 0);
  }
  
  getWorkItems() {
    let sessionHash = getCookie('sessionHash');

    this.setState({isLoading: true});

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'workItems/tr?limit=1000&filterActive=1', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({isLoading: false, workItems: responseData.workItems});
        }
        break;
        default: {
          this.setState({isLoading: false});

          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          }).then(() => <Navigate to={'/home'} replace={true}></Navigate>);
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  deliveryDateOnChange(event) {
    this.setState({memberWorkItemInfo: {...this.state.memberWorkItemInfo, deliveryDate: inputDateTimeToIsoDateTime(event.target.value) || null}});
  } 

  memberWorkItemOnChange(event) {
    this.setState({memberWorkItemInfo: {...this.state.memberWorkItemInfo, workItemId: parseInt(event.target.value) || 0, workItemName: event.target.options[event.target.selectedIndex].text}});
  }

  returnDateOnChange(event) {
    this.setState({memberWorkItemInfo: {...this.state.memberWorkItemInfo, returnDate: inputDateTimeToIsoDateTime(event.target.value) || null}});
  }

  handleKeyPress(event) {
    if (event.key === 'Enter') {
      this.saveOnClick();
    }
  }

  saveOnClick() {
    let sessionHash = getCookie('sessionHash');

    let infoMessage = '';
    if (!this.state.memberWorkItemInfo.workItemId) {
      infoMessage = 'Ürün adı boş geçilemez !';
    } else if (!this.state.memberWorkItemInfo.deliveryDate) {
      infoMessage = 'Teslim edilme tarihi boş geçilemez !';
    }

    if (infoMessage) {
      swal({
        dangerMode: true,
        icon: 'info',
        title: 'Eksik Bilgi',
        text: infoMessage,
        buttons: 'Tamam'
      });
    } else {
      this.setState({isCompleted: false});

      const requestOptions = {
        method: 'POST',
        headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
        body: JSON.stringify(this.state.memberWorkItemInfo)
      };

      fetch(config.restApi + 'memberWorkItem/tr/' + this.props.memberId, requestOptions)
      .then(response => response.json())
      .then(responseData => {
        switch (responseData.status) {
          case 200: {
            this.setState({isCompleted: true, memberWorkItemInfo: {workItemId: 0, deliveryDate: null, deliveryNote: '', returnDate: null, returnNote: ''}});

            swal({
              dangerMode: true,
              icon: 'success',
              title: 'Tamamlandı',
              text: 'Ürün kişinin zimmet listesine başarıyla eklendi.',
              buttons: 'Tamam'
            }).then(() => this.props.onCompleted());
          }
          break;
          default: {
            this.setState({isCompleted: true});

            swal({
              dangerMode: true,
              icon: 'warning',
              title: 'Hay Aksi',
              text: responseData.message,
              buttons: 'Tamam'
            });
          }
          break;
        }
      }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
    }
  }
 
  render() {
    return (
      <div className="modal inmodal">
        <div className="modal-dialog">
          <div className="modal-content animated fadeIn">
          <Modal
            isOpen={this.props.isOpen}
            onRequestClose={this.props.onRequestClose}
            style={{
              content : {
                position : 'null',
                top : 'null',
                left : 'null',
                right : 'null',
                bottom : 'null',
                border : 'none',
                background : '#fff',
                padding : 'null',
                maxWidth : '50%',
                minWidth : '350px'
              },
              overlay : {
                position : 'fixed',
                top : 0,
                left : 0,
                right : 0,
                bottom : 0,
                display : 'flex',
                alignItems : 'center',
                justifyContent : 'center',
                backgroundColor : 'rgba(0,0,0,00.1)',
                zIndex : 2500
              }
            }}
          >
            <div className={this.state.isCompleted ? "ibox-content no-padding" : "ibox-content sk-loading no-padding"}>
              <div className="sk-spinner sk-spinner-fading-circle">
                <div className="sk-circle1 sk-circle"></div>
                <div className="sk-circle2 sk-circle"></div>
                <div className="sk-circle3 sk-circle"></div>
                <div className="sk-circle4 sk-circle"></div>
                <div className="sk-circle5 sk-circle"></div>
                <div className="sk-circle6 sk-circle"></div>
                <div className="sk-circle7 sk-circle"></div>
                <div className="sk-circle8 sk-circle"></div>
                <div className="sk-circle9 sk-circle"></div>
                <div className="sk-circle10 sk-circle"></div>
                <div className="sk-circle11 sk-circle"></div>
                <div className="sk-circle12 sk-circle"></div>
              </div>
              <div className="modal-header d-block text-center text-darkblue">
                <button className="close" onClick={this.props.onRequestClose}><span>×</span></button>
                <h4 className="modal-title">{this.props.modalTitle}</h4>
                <h5 className="font-normal">{this.props.modalDescription}</h5>
              </div>
              <div className="modal-body text-darkblue">
                <strong>Ürün Adı<strong className="text-danger ml-1">*</strong></strong>
                <select className="form-control form-control-sm form-input mb-2 mt-1" onChange={this.memberWorkItemOnChange} onKeyDown={this.handleKeyPress} value={this.state.memberWorkItemInfo.workItemId}>
                  <option value="">Seçiniz</option>
                  {this.state.workItems.map(workItemInfo => <option key={workItemInfo.id} value={workItemInfo.id}>{workItemInfo.name}</option>)}
                </select>
                <strong>Teslim Edilme Tarihi<strong className="text-danger ml-1">*</strong></strong>
                <input className="form-control form-control-sm form-input mb-2 mt-1" onChange={this.deliveryDateOnChange} onKeyDown={this.handleKeyPress} type="datetime-local" value={this.state.memberWorkItemInfo.deliveryDate ? isoToMysqlDateTime(this.state.memberWorkItemInfo.deliveryDate) : ''} />
                <strong>Teslim Edilme Notu</strong>
                <textarea className="form-control form-control-sm form-input mb-2 mt-1" onChange={event => this.setState({memberWorkItemInfo: {...this.state.memberWorkItemInfo, deliveryNote: event.target.value}})} onKeyDown={this.handleKeyPress} placeholder="Açıklama giriniz.." type="text" value={this.state.memberWorkItemInfo.deliveryNote} />
                <strong>Teslim Alma Tarihi</strong>
                <input className="form-control form-control-sm form-input mb-2 mt-1" onChange={this.returnDateOnChange} onKeyDown={this.handleKeyPress} type="datetime-local" value={this.state.memberWorkItemInfo.returnDate ? isoToMysqlDateTime(this.state.memberWorkItemInfo.returnDate) : ''} />
                <strong>Teslim Alma Notu</strong>
                <textarea className="form-control form-control-sm form-input mb-2 mt-1" onChange={event => this.setState({memberWorkItemInfo: {...this.state.memberWorkItemInfo, returnNote: event.target.value}})} onKeyDown={this.handleKeyPress} placeholder="Açıklama giriniz.." type="text" value={this.state.memberWorkItemInfo.returnNote} />
              </div>
              <div className="modal-footer"> 
                <button className="btn btn-orange" onClick={() => this.saveOnClick()}>Kaydet</button>
              </div>
            </div>
          </Modal>
          </div>
        </div>
      </div>
    )
  }
}
