import React from 'react';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import swal from 'sweetalert';

import AddressModal from '../../../component/AddressModal';

import {getCookie} from '../../../inc/cookies';
import config from '../../../inc/config';
import { isoToDateTime } from '../../../inc/helper';

class PaymentTransactionByBankTransferModule extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isOpenAddressModal: false,

      memberId: 0,
      price: 0,
      transactionProducts: '',
      
      memberTransactionId: 0,
      memberAddressId: 0,
      paymentHash: '',
      accountName: '',
      iban: '',

      memberAddresses: [],
      
      editAddress: null
    };
  }

  componentDidMount() {
    this.getMemberTransaction();
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
  }

  getMemberTransaction() {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    let memberTransactionResponse;

    fetch(config.restApi + 'memberTransaction/tr/' + this.props.searchParams.get('id'), requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          memberTransactionResponse = {memberTransactionId: responseData.memberTransaction.id, memberId: responseData.memberTransaction.memberId, transactionProducts: responseData.memberTransaction.transactionProducts, price: responseData.memberTransaction.price, paymentStatus: responseData.memberTransaction.paymentStatus}}
          fetch(config.restApi + 'memberAddresses/tr/' + responseData.memberTransaction.memberId + '?filterActive=1', requestOptions)
          .then(response => response.json())
          .then(responseData => {
            switch(responseData.status) {
              case 200: {
                const memberAddressId = responseData.memberAddresses.length === 1 && responseData.memberAddresses[0].id;
                this.setState({isLoading: false, ...memberTransactionResponse, memberAddresses: responseData.memberAddresses, memberAddressId: memberAddressId})} 
              break;
              case 401:
                swal({
                  dangerMode: true,
                  icon: 'warning',
                  title: 'Hay Aksi',
                  text: responseData.message,
                  buttons: 'Tamam'
                }).then(this.props.navigate(-1));
              break;
              default: {
                swal({
                  dangerMode: true,
                  icon: 'warning',
                  title: 'Hay Aksi',
                  text: responseData.message,
                  buttons: 'Tamam'
                });
              }
              break;
            }
          }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
        break;
        case 401:
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          }).then(this.props.navigate(-1));
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  paymentByBankTransferOnClick = (event) => {
    let sessionHash = getCookie('sessionHash');

    let infoMessage = '';

    if (!this.state.accountName) {
      infoMessage = 'Hesap adı boş geçilemez !';
    } else if (!this.state.paymentHash) {
      infoMessage = 'Dekont numarası boş geçilemez !';
    } else if (!this.state.iban) {
      infoMessage = 'Iban bilgisi boş geçilemez !';
    } else if (!this.state.memberAddressId) {
      infoMessage = 'Adres seçimi boş geçilemez !';
    }

    if (infoMessage) {
      swal({
        dangerMode: true,
        icon: 'info',
        title: 'Eksik Bilgi',
        text: infoMessage,
        buttons: 'Tamam'
      });
    }
    else {
      this.setState({isLoading: true});

      const requestOptions = {
        method: 'POST',
        headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
        body: JSON.stringify({memberTransactionId: this.state.memberTransactionId, memberAddressId: this.state.memberAddressId, paymentHash: this.state.paymentHash, accountName: this.state.accountName, iban: this.state.iban})
      };

      fetch(config.restApi + 'paymentWithWireTransfer/tr', requestOptions)
      .then(response => response.json())
      .then(responseData => {
        switch(responseData.status) {
          case 200: {
            this.setState({isLoading: false});
            swal({
              dangerMode: true,
              icon: 'success',
              title: 'Tamamlandı',
              text:  'Ödeme işlemi başarıyla gerçekleşti.',
              buttons: 'Tamam'
            }).then(() => this.props.navigate('/memberEdit?id=' + this.state.memberId + '&tab=1'));
          }
          break;
          default: {
            this.setState({isLoading: false});
  
            swal({
              dangerMode: true,
              icon: 'warning',
              title: 'Hay Aksi',
              text: responseData.message,
              buttons: 'Tamam'
            });
          }
          break;
        }
      }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
    }
  }

  saveMemberAddressOnClick(addressInfo, memberId) {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'POST',
      headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
      body: JSON.stringify({memberAddresses: [addressInfo]})
    };

    fetch(config.restApi + 'memberAddress/tr/' + memberId, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch (responseData.status) {
        case 200: {
          swal({
            dangerMode: true,
            icon: 'success',
            title: 'Tamamlandı',
            text: 'Adres başarıyla eklendi.',
            buttons: 'Tamam'
          }).then(() => this.getMemberTransaction(), this.setState({isOpenAddressModal: false}));
        }
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  render() {
    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <>
        <div className="row d-flex justify-content-end mb-3 pr-3">
          <Link className="btn btn-darkblue text-white" title="Geri" to={"/memberEdit?id=" + this.state.memberId}><i className="fa fa-reply"></i></Link>
        </div>
        <div className="row">
          <div className="col">
            <div className="ibox">
              <div className="ibox-content text-darkblue">
                <h4>Havale İle Ödeme</h4>
                <div className="hr-line-dashed"></div>
                <div className="row custom-container mb-3">
                  <div className="col overflow-auto">
                    <div className="ibox table-width">
                      <div className="ibox-title text-darkblue">
                        <h5>Ödemesi Yapılmak Üzere Olan İşlem Bilgileri</h5>
                      </div>
                      <div className="ibox-content overflow-auto">
                        <div className="row">
                          <table className="table table-striped table-bordered">
                          <thead>
                            <tr className="text-center text-darkblue">
                            <th>Ürün İçeriği</th>
                            <th>Toplam Tutar</th>
                            </tr>
                          </thead>
                          <tbody className="text-center">
                            <tr>
                              <td>{this.state.transactionProducts}</td>
                              <td>{this.state.price}</td>
                            </tr>
                          </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row custom-container mb-3">
                  <div className="col overflow-auto">
                    <div className="ibox table-width">
                     <div className="d-flex justify-content-between ibox-title text-darkblue pr-2">
                        <h5>Adres Bilgileri<strong className="text-danger ml-1">*</strong></h5>
                        <button className="btn btn-orange" onClick={() => this.setState({editAddress: null, isOpenAddressModal: true})}><i className="fa fa-plus"></i> Adres Ekle</button>
                      </div>
                      <div className="ibox-content overflow-auto">
                        <div className="row">
                          <table className="table table-striped table-bordered">
                          <thead>
                            <tr className="text-center text-darkblue">
                              <th>İl</th>
                              <th>İlçe</th>
                              <th>Semt</th>
                              <th>Mahalle</th>
                              <th>Açık Adres</th>
                              <th>Açıklama</th>
                              <th>Durum</th>
                              <th>Fatura Adresi</th>
                            </tr>
                          </thead>
                          <tbody className="text-center">
                            {this.state.memberAddresses.length >0 && this.state.memberAddresses.map(memberAddressInfo =>
                              <tr key={memberAddressInfo.id}>
                                <td className="align-middle">{memberAddressInfo.cityName}</td>
                                <td className="align-middle">{memberAddressInfo.townName}</td>
                                <td className="align-middle">{memberAddressInfo.districtName}</td>
                                <td className="align-middle">{memberAddressInfo.quarterName}</td>
                                <td className="align-middle">{memberAddressInfo.address}</td>
                                <td className="align-middle">{memberAddressInfo.description}</td>
                                <td className="align-middle">{memberAddressInfo.active == 1 ? 'Açık' : 'Kapalı'}</td>
                                <td><input defaultChecked={this.state.memberAddressId === memberAddressInfo.id} name="memberAddress" onChange={() => this.setState({memberAddressId: memberAddressInfo.id})} type="radio" value="selectedAddress" /></td>
                              </tr>
                            )}
                            {this.state.memberAddresses.length === 0 ?
                              <tr>
                                <td className="align-middle p-3" colSpan="8">Adres Bulunamadı !</td>
                              </tr>
                            : null}
                          </tbody>
                          </table>
                        </div>
                      </div>
                      <AddressModal isOpen={this.state.isOpenAddressModal} modalTitle="Adres Ekle" modalDescription="Adres bilgisi eklemek için aşağıdaki bilgileri doldurunuz !" onRequestClose={() => this.setState({editAddress: null, isOpenAddressModal: false})} saveOnClick={addressInfo => this.saveMemberAddressOnClick(addressInfo, this.state.memberId)} />
                    </div>
                  </div>
                </div>
                <div className="row overflow-auto">
                  <div className="col">
                    <div className="ibox">
                      <div className="ibox-title text-darkblue">
                        <h5>Ödeme Bilgileri<strong className="text-danger ml-1">*</strong></h5>
                      </div>
                      <div className="ibox-content">
                        <div className="col">
                          <div className="row">
                            <div className="col-12">
                              <div className="mb-2">
                                <label>Hesap Adı<strong className="text-danger ml-1">*</strong></label>
                                <input autoComplete="off" className="form-control form-input" onChange={event => this.setState({accountName: event.target.value.trimStart()})} placeholder="Hesap Adı" value={this.state.accountName} />
                              </div>
                            </div>
                            </div>
                            <div className="row">
                              <div className="col-12">
                                <div className="mb-2">
                                  <label>Dekont Numarası<strong className="text-danger ml-1">*</strong></label>
                                  <div className="input-group">
                                    <input autoComplete="off" className="form-control form-input" onChange={event => this.setState({paymentHash: event.target.value.trimStart()})} placeholder="Dekont Numarası" value={this.state.paymentHash} /> 
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12">
                                <div className="mb-2">
                                  <label>IBAN<strong className="text-danger ml-1">*</strong></label>
                                  <div className="input-group">
                                    <input autoComplete="off" className="form-control form-input" onChange={event => this.setState({iban: event.target.value.trimStart()})} placeholder="IBAN" value={this.state.iban} /> 
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row mt-3">
                              <div className="col-12 d-flex justify-content-end">
                                <button className="btn btn-orange2 font-bold mr-2" onClick={this.paymentByBankTransferOnClick}><i className="fa fa-money"></i> Havale İle Öde</button>
                              </div>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
  }
}

export default function PaymentTransactionByBankTransfer() {
  return (
    <PaymentTransactionByBankTransferModule navigate={useNavigate()} searchParams={useSearchParams()[0]} />
  )
}