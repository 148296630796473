import React from 'react';
import {Link, useNavigate} from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import Images from '../../utilies/Images';

import swal from 'sweetalert';
import Select from 'react-select';

import {getCookie} from '../../inc/cookies';
import config from '../../inc/config';
import {isoToDateTime, priceFormat, slugify} from '../../inc/helper';

class PendingAdvertModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,

      adverts: [],
      cities: [],
      towns: [],
      members: [],

      advertStatuses: [], 

      filterActive: '',
      filterMemberId: '',
      filterAdvertStatus: '',
      filterSearchText: '',
      filterAdvertId: '',
      filterMinPrice: '',
      filterMaxPrice: '',
      filterAddedStartDate: '',
      filterAddedEndDate: '',
      filterCityId: '',
      filterTownId: '',

      pageCount: 0,
      resultCount: 0,
      currentPage: 1,
      limit: 10
    };
  }

  componentDidMount() {
    this.getAdverts(this.state.currentPage);
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
  }

  filteredAdverts(currentPage) {
    let sessionHash = getCookie('sessionHash');

    this.setState({isLoading: true});

    const query = `limit=${this.state.limit}&page=${currentPage}&filterMemberId=${this.state.filterMemberId}&filterAdvertStatus=1&filterSearchText=${this.state.filterSearchText}&filterAdvertId=${this.state.filterAdvertId}&filterCityId=${this.state.filterCityId}&filterTownId=${this.state.filterTownId}&filterMinPrice=${this.state.filterMinPrice}&filterMaxPrice=${this.state.filterMaxPrice}&filterAddedStartDate=${this.state.filterAddedStartDate}&filterAddedEndDate=${this.state.filterAddedEndDate}&filterActive=${this.state.filterActive}`

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    const startIndex = 0;
    const endIndex = startIndex + this.state.limit;

    fetch(config.restApi + `adverts/tr?${query}`, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          const currentAdverts = responseData.adverts.slice(startIndex, endIndex);
          this.setState({isLoading: false, adverts: currentAdverts, pageCount: responseData.pageCount, resultCount: responseData.resultCount, currentPage: currentPage});
        }
        break;
        case 401:
          this.setState({isLoading: false});

          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          }).then(this.props.navigate('/home'))
        break;
        default: {
          this.setState({isLoading: false});

          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  getAdverts(currentPage) {
    let sessionHash = getCookie('sessionHash');

    this.setState({isLoading: true});

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    const startIndex = 0;
    const endIndex = startIndex + this.state.limit;

    let errorMessages = [];
    let remaining = 4;
    let complete = () => {
      remaining--;
      if (remaining === 0) {
        this.setState({isLoading: false});
        if (errorMessages.length > 0) {
          swal({dangerMode: true, icon: 'warning', title: 'Hay Aksi', text: errorMessages[0], buttons: 'Tamam'}).then(this.props.navigate('/home'));
        }else{
          this.setState({isLoading: false});
        }
      }
    }

    fetch(config.restApi +  `adverts/tr?limit=${this.state.limit}&page=${currentPage}` + '&filterAdvertStatus=1', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          const currentAdverts = responseData.adverts.slice(startIndex, endIndex);
          this.setState({adverts: currentAdverts, pageCount: responseData.pageCount, resultCount: responseData.resultCount, currentPage: currentPage});
        }
        break;
        case 401:  {
          errorMessages.push(responseData.message);
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));

    fetch(config.restApi + 'members/tr', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({members: responseData.members});
        }
        break;
        case 401:  {
          errorMessages.push(responseData.message);
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));

    fetch(config.restApi + 'cities/tr/1?filterActive=1', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          const sortedCities = responseData.cities.sort((first, last) => first.name.localeCompare(last.name));
          this.setState({cities: sortedCities, towns: []});
        }
        break;
        case 401:  {
          errorMessages.push(responseData.message);
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));

    fetch(config.restApi + 'advertStatuses/tr', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({advertStatuses: responseData.advertStatuses});
        }
        break;
        case 401:  {
          errorMessages.push(responseData.message);
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));
  }

  getTowns(cityId) {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'towns/tr/' + cityId + '?filterActive=1', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({filterCityId: cityId, filterTownId: '', towns: responseData.towns});
        }
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  selectedMemberOnChange = (selectedMember) => {
    this.setState({filterMemberId: selectedMember ? selectedMember.value : ''});
  };
  
  selectedCityOnChange = (selectedCity) => {
    let filterCityIdId = selectedCity ? selectedCity.value : '';
    if (filterCityIdId) {
      this.getTowns(filterCityIdId);
    } else {
      this.setState({filterCityId: '', filterTownId: '', towns: []});
    }
  }

  selectedTownOnChange = (selectedTown) => {
    this.setState({filterTownId: selectedTown ? selectedTown.value : ''});
  }

  filterOnClick = (event) => {
    const query = `filterMemberId=${this.state.filterMemberId}&filterAdvertStatus=${this.state.filterAdvertStatus}&filterSearchText=${this.state.filterSearchText}&filterAdvertId=${this.state.filterAdvertId}&filterCityId=${this.state.filterCityId}&filterTownId=${this.state.filterTownId}&filterMinPrice=${this.state.filterMinPrice}&filterMaxPrice=${this.state.filterMaxPrice}&filterAddedStartDate=${this.state.filterAddedStartDate}&filterAddedEndDate=${this.state.filterAddedEndDate}&filterActive=${this.state.filterActive}`
    this.filteredAdverts(1, query)
  }

  resetOnClick = (event) => {
    this.setState({
      filterMemberId: '',
      filterAdvertStatus: '',
      filterAdvertId: '',
      filterCityId: '',
      filterTownId: '',
      filterMinPrice: '',
      filterMaxPrice: '',
      filterAddedStartDate: '',
      filterAddedEndDate: ''
    }, () => {this.setState({isLoading: true}, () => {this.filteredAdverts(this.state.currentPage);this.setState({isLoading: false});});});
  }

  getAdvertStatus(id) {
    if(id === 1) {
      return 'Onay Bekliyor'
    } else if(id === 2) {
      return 'Yayında'
    } else if(id === 3) {
      return 'Reddedildi'
    } 
    return 'Taslak'
  }

  handleKeyPress = (event, page) => {
    if (event.key === 'Enter') {
      const query = `filterMemberId=${this.state.filterMemberId}&filterAdvertStatus=${this.state.filterAdvertStatus}&filterSearchText=${this.state.filterSearchText}&filterAdvertId=${this.state.filterAdvertId}&filterCityId=${this.state.filterCityId}&filterTownId=${this.state.filterTownId}&filterMinPrice=${this.state.filterMinPrice}&filterMaxPrice=${this.state.filterMaxPrice}&filterAddedStartDate=${this.state.filterAddedStartDate}&filterAddedEndDate=${this.state.filterAddedEndDate}&filterActive=${this.state.filterActive}`
      this.setState({isLoading: true, currentPage: 1}, () => {this.filteredAdverts(this.state.currentPage, query);this.setState({isLoading: false});});
    }
  }

  handlePageClick = (page) => {
    const updatedPage = page.selected + 1;
    this.setState({ currentPage: updatedPage }, () => {this.filteredAdverts(updatedPage);});
  }

  render() {
    const selectedMemberOptions = this.state.members.map((memberInfo) => ({
      value: memberInfo.id,
      label: `${memberInfo.firstname} ${memberInfo.lastname}`,
      search: memberInfo.firstname + ' ' + memberInfo.lastname
    }))

    const selectedCitiesOptions = [
      {value: '', label: 'Seçiniz' }, ...this.state.cities.map((cityInfo) => ({
        value: cityInfo.id,
        label: `${cityInfo.name}`,
        search: cityInfo.name
      }))
    ];
    
    const selectedTownsOptions = [
      { value: '', label: 'Seçiniz' }, ...this.state.towns.map((townInfo) => ({
        value: townInfo.id,
        label: `${townInfo.name}`,
        search: townInfo.name
      }))
    ];

    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <>
        <div className="row custom-container">
          <div className="col-xl-9">
            <div className="ibox">
              <div className="ibox-title text-darkblue">
                <h5>Onay Bekleyen İlan Listesi</h5>
              </div>
              <div className="table-responsive overflow-x-auto">
                <table className="table table-striped table-bordered">
                <thead>
                  <tr className="text-center text-darkblue">
                    <th>İlan Görseli</th>
                    <th>İlan Sahibi</th>
                    <th>İlan Başlığı</th>
                    <th>Fiyat</th>
                    <th>Bölge</th>
                    <th>Eklenme Tarihi</th>
                    <th>İlan Durumu</th>
                    <th>Durum</th>
                    <th>Detay</th>
                  </tr>
                </thead>
                <tbody className="text-center">
                {this.state.adverts.map((advertInfo) =>
                  <tr key={advertInfo.id}>
                    <td className="align-middle">
                      {advertInfo.photos.length > 0 ? <img alt="İlan Görseli" className="w-md-50" style={{height: 100, width:200, objectFit:"contain", overflow: "visible"}} src={`https://file.satisgaranti.com/${advertInfo.photos[0].path}`}/>
                      : <i className="fa fa-camera d-flex align-items-center justify-content-center" style={{height: 100, objectFit:"contain", overflow: "visible", fontSize: 48, color: '#d2d2d2' }}></i>}<br /><strong className="text-darkblue font-bold"> İlan No: #{advertInfo.id}</strong>
                    </td>
                    <td className="align-middle">{advertInfo.memberFullName}</td>
                    <td className="align-middle">{advertInfo.title ? <Link target='_blank' to={`https://${config.host}/ilan/${slugify(advertInfo.title)}-${advertInfo.id}/detay`}>{advertInfo.title}</Link> : "İlan başlığı girilmedi"}<br /><strong className="text-orange font-normal" title="Kategori Adı"> {advertInfo.categoryName}</strong></td>
                    <td className="align-middle">{priceFormat(advertInfo.price)} {advertInfo.currencySymbol}</td>
                    <td className="align-middle">{advertInfo.cityName + '/' + advertInfo.townName}</td>
                    <td className="align-middle">{isoToDateTime(advertInfo.addedDate)}</td>
                    <td className="align-middle">{this.getAdvertStatus(advertInfo.advertStatus)}</td>
                    <td className="align-middle">{advertInfo.active === 1 ? 'Açık' : 'Kapalı'}</td>
                    <td className="align-middle">
                      <Link className="btn btn-outline btn-orange text-center mb-1" title={'İlan Detayı'} to={'/pendingAdvertDetail?id=' + advertInfo.id}><i className="fa fa-eye text-white action-icon"></i></Link>
                      {/* <Link className="btn btn-outline btn-orange text-center mb-1" to={'/advertEdit?id=' + advertInfo.id} title="Düzenle"><i className="fa fa-pencil text-white action-icon"></i></Link> */}
                    </td>
                  </tr>
                )}
                {this.state.adverts.length === 0 ?
                  <tr>
                    <td className="align-middle p-3" colSpan="9">Onaylanmamış İlan Bulunamadı !</td>
                  </tr>
                : null}
                </tbody>
                </table>
                {
                  this.state.adverts.length !== 0 &&
                  <ReactPaginate
                  previousLabel={"Geri"}
                  nextLabel={"İleri"}
                  breakLabel={"..."}  
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={1}
                  onPageChange={page => this.handlePageClick(page)}
                  pageRangeDisplayed={2}
                  containerClassName={'pagination'}
                  activeClassName={'activePage'}
                  forcePage={this.state.currentPage - 1}
                  />
                }
              </div>
            </div>
          </div>
          <div className="col-xl-3 order-first order-xl-last d-xl-none" id="accordion">
            <div className="ibox">
              <div className="ibox-content text-darkblue p-0">
                <h4 className="collapse-title">
                  <a className="btn btn-link text-darkblue font-bold d-flex justify-content-between" data-toggle="collapse" href="#filterCollapse" title="Filtrele">Filtrele</a>
                </h4>
              </div>
              <div className="collapse" data-parent="#accordion" id="filterCollapse">
                <div className="ibox-content text-darkblue">  
                  <strong>Üye Adı</strong>
                  <Select
                  className=" form-input mb-2 mt-1 text-dark"
                  options={selectedMemberOptions}
                  isClearable={true}
                  defaultValue=""
                  onChange={this.selectedMemberOnChange}
                  value={selectedMemberOptions.find((memberOptionInfo) => memberOptionInfo.value === this.state.filterMemberId)}
                  filterOption={(option, searchText) => option.data.search && option.data.search.toLocaleLowerCase('tr-TR').includes(searchText.toLocaleLowerCase('tr-TR'))}
                  placeholder="Üye Adı"
                  />
                  <strong>İlan Numarası</strong>
                  <input className="form-control form-input mb-2 mt-1" onChange={(event) => this.setState({filterAdvertId: event.target.value.replace(/\D+/g, '')})} onKeyDown={this.handleKeyPress} placeholder="İlan Numarası" type="text" value={this.state.filterAdvertId} />
                  <strong>İlan Başlığı</strong>
                  <input className="form-control form-input mb-2 mt-1" onChange={(event) => this.setState({filterSearchText: event.target.value})} onKeyDown={this.handleKeyPress} placeholder="İlan Başlığı" type="text" value={this.state.filterSearchText} />
                  <strong>Şehir</strong>
                  <Select
                    className="form-input mb-2 mt-1 text-dark"
                    onChange={this.selectedCityOnChange}
                    options={selectedCitiesOptions}
                    isClearable={true}
                    defaultValue={selectedCitiesOptions[0]}
                    placeholder="Seçiniz"
                    value={selectedCitiesOptions.find((cityInfo) => cityInfo.value === this.state.filterCityId)}
                    filterOption={(option, searchText) => option.data.search && option.data.search.toLocaleLowerCase('tr-TR').includes(searchText.toLocaleLowerCase('tr-TR'))}
                  />
                  <strong>İlçe</strong>
                  <Select
                    className="form-input mb-2 mt-1 text-dark"
                    isDisabled={this.state.towns.length === 0}
                    onChange={this.selectedTownOnChange}
                    options={selectedTownsOptions}
                    isClearable={true}
                    defaultValue={selectedTownsOptions[0]}
                    placeholder="Seçiniz"
                    value={selectedTownsOptions.find((townInfo) => townInfo.value === this.state.filterTownId)}
                    filterOption={(option, searchText) => option.data.search && option.data.search.toLocaleLowerCase('tr-TR').includes(searchText.toLocaleLowerCase('tr-TR'))}
                  />
                  <strong>Fiyat</strong>
                  <input className="form-control form-input mb-2 mt-1" min="0" onChange={event => this.setState({filterMinPrice: event.target.value})} onKeyDown={this.handleKeyPress} placeholder="Minimum Fiyat" type="number" value={this.state.filterMinPrice} />
                  <input className="form-control form-input mb-2 mt-1" min="0" onChange={event => this.setState({filterMaxPrice: event.target.value})} onKeyDown={this.handleKeyPress} placeholder="Maksimum Fiyat" type="number" value={this.state.filterMaxPrice} />
                  <strong>Durum</strong>
                  <select className="form-control form-input mb-2 mt-1" onChange={(event) => {this.setState({filterActive: event.target.value})}} onKeyDown={this.handleKeyPress} value={this.state.filterActive}>
                    <option value="">Seçiniz</option>
                    <option value="1">Açık</option>
                    <option value="0">Kapalı</option>
                  </select>
                  <strong>Başlangıç Tarihi</strong>
                  <input className="form-control form-input mb-2" onChange={event => this.setState({filterAddedStartDate: event.target.value})} onKeyDown={this.handleKeyPress} type="date" value={this.state.filterAddedStartDate} />
                  <strong>Bitiş Tarihi</strong>
                  <input className="form-control form-input" onChange={event => this.setState({filterAddedEndDate: event.target.value})} onKeyDown={this.handleKeyPress} type="date" value={this.state.filterAddedEndDate} />
                  <div className="d-flex justify-content-end">
                    <button className="btn btn-orange mt-3 mr-2" onClick={this.resetOnClick}><i className="fa fa-refresh"></i> Sıfırla</button>
                    <button className="btn btn-orange mt-3" onClick={this.filterOnClick}><i className="fa fa-filter"></i> Filtrele</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 order-first order-xl-last d-none d-xl-block">
            <div className="ibox">
              <div className="ibox-title text-darkblue">
                <h5><i className="fa fa-filter"></i> Filtrele</h5>
              </div>
              <div className="ibox-content text-darkblue">
                <strong>Üye Adı</strong>
                <Select
                  className=" form-input mb-2 mt-1 text-dark"
                  options={selectedMemberOptions}
                  isClearable={true}
                  defaultValue=""
                  onChange={this.selectedMemberOnChange}
                  value={selectedMemberOptions.find((memberOptionInfo) => memberOptionInfo.value === this.state.filterMemberId)}
                  filterOption={(option, searchText) => option.data.search && option.data.search.toLocaleLowerCase('tr-TR').includes(searchText.toLocaleLowerCase('tr-TR'))}
                  placeholder="Üye Adı"
                />
                <strong>İlan Numarası</strong>
                <input className="form-control form-input mb-2 mt-1" onChange={(event) => this.setState({filterAdvertId: event.target.value.replace(/\D+/g, '')})} onKeyDown={this.handleKeyPress} placeholder="İlan Numarası" type="text" value={this.state.filterAdvertId} />
                <strong>İlan Başlığı</strong>
                <input className="form-control form-input mb-2 mt-1" onChange={(event) => this.setState({filterSearchText: event.target.value})} onKeyDown={this.handleKeyPress} placeholder="İlan Başlığı" type="text" value={this.state.filterSearchText} />
                <strong>Şehir</strong>
                <Select
                  className="form-input mb-2 mt-1 text-dark"
                  onChange={this.selectedCityOnChange}
                  options={selectedCitiesOptions}
                  isClearable={true}
                  defaultValue={selectedCitiesOptions[0]}
                  placeholder="Seçiniz"
                  value={selectedCitiesOptions.find((cityInfo) => cityInfo.value === this.state.filterCityId)}
                  filterOption={(option, searchText) => option.data.search && option.data.search.toLocaleLowerCase('tr-TR').includes(searchText.toLocaleLowerCase('tr-TR'))}
                  />
                <strong>İlçe</strong>
                <Select
                  className="form-input mb-2 mt-1 text-dark"
                  isDisabled={this.state.towns.length === 0}
                  onChange={this.selectedTownOnChange}
                  options={selectedTownsOptions}
                  isClearable={true}
                  defaultValue={selectedTownsOptions[0]}
                  placeholder="Seçiniz"
                  value={selectedTownsOptions.find((townInfo) => townInfo.value === this.state.filterTownId)}
                  filterOption={(option, searchText) => option.data.search && option.data.search.toLocaleLowerCase('tr-TR').includes(searchText.toLocaleLowerCase('tr-TR'))}
                  />
                <strong>Fiyat</strong>
                <input className="form-control form-input mb-2 mt-1" min="0" onChange={event => this.setState({filterMinPrice: event.target.value})} onKeyDown={this.handleKeyPress} placeholder="Minimum Fiyat" type="number" value={this.state.filterMinPrice} />
                <input className="form-control form-input mb-2 mt-1" min="0" onChange={event => this.setState({filterMaxPrice: event.target.value})} onKeyDown={this.handleKeyPress} placeholder="Maksimum Fiyat" type="number" value={this.state.filterMaxPrice} />
                <strong>Durum</strong>
                <select className="form-control form-input mb-2 mt-1" onChange={(event) => {this.setState({filterActive: event.target.value})}} onKeyDown={this.handleKeyPress} value={this.state.filterActive}>
                  <option value="">Seçiniz</option>
                  <option value="1">Açık</option>
                  <option value="0">Kapalı</option>
                </select>
                <strong>Başlangıç Tarihi</strong>
                <input className="form-control form-inputt mb-2 mt-1" onChange={event => this.setState({filterAddedStartDate: event.target.value})} onKeyDown={this.handleKeyPress} type="date" value={this.state.filterAddedStartDate} />
                <strong>Bitiş Tarihi</strong>
                <input className="form-control form-inputt mb-2 mt-1" onChange={event => this.setState({filterAddedEndDate: event.target.value})} onKeyDown={this.handleKeyPress} type="date" value={this.state.filterAddedEndDate} />
                <div className="d-flex justify-content-end">
                  <button className="btn btn-orange mt-3 mr-2" onClick={this.resetOnClick}><i className="fa fa-refresh"></i> Sıfırla</button>
                  <button className="btn btn-orange mt-3" onClick={this.filterOnClick}><i className="fa fa-filter"></i> Filtrele</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
  }
}

export default function PendingAdverts() {
  return (
    <PendingAdvertModule navigate={useNavigate()} />
  )
}