import React from 'react';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import swal from 'sweetalert';

import ProductToPacketAddModal from '../../../component/siteManagementModals/products/ProductToPacketAddModal';

import {getCookie} from '../../../inc/cookies';
import config from '../../../inc/config';

class ProductAddModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isOpenProductToPacketAddModal: false,

      productInfo: {
        name: '',
        description: '',
        quantity: '0',
        price: '0',
        priceOld: '0',
        categoryId: 0,
        productPeriod: '',
        productTypeId: 0,
        sort: '0',
        productIds: []
      },

      categories: [],
      products: [],
      productTypes: [],
      productItems: []
    };
      this.saveProductToPacketAddOnClick = this.saveProductToPacketAddOnClick.bind(this);
  }

  componentDidMount() {
    this.getProductDetails();
    window.scrollTo(0, 0);
  }

  getProductDetails() {
    let sessionHash = getCookie('sessionHash');

    this.setState({isLoading: true});

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    let errorMessages = [];
    let remaining = 3;
    let complete = () => {
      remaining--;
      if (remaining == 0) {
        this.setState({isLoading: false});
      }
    }

    fetch(config.restApi + 'categories/tr/0?filterActive=1', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({categories: responseData.categories});
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));

    fetch(config.restApi + 'products/tr?filterActive=1', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({products: responseData.products});
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));

    fetch(config.restApi + 'productTypes?filterActive=1', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({productTypes: responseData.productTypes});
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));
  }

  categoryOnChange = (event) => {
    this.setState({productInfo: {...this.state.productInfo, categoryId: parseInt(event.target.value) || 0, categoryName: event.target.options[event.target.selectedIndex].text}});
  }

  productTypeOnChange = (event) => {
    this.setState({productInfo: {...this.state.productInfo, categoryId: 0, productTypeId: event.target.value || 0, productTypeName: event.target.options[event.target.selectedIndex].text}});
  }

  saveProductToPacketAddOnClick(productToPacketInfo) {
    let productItems = this.state.productItems;
    productItems.push(productToPacketInfo)

    let productIds =  productItems.map((itemInfo,index) => itemInfo.id)

    this.setState({productInfo: {...this.state.productInfo, productIds: productIds}, isOpenProductToPacketAddModal: false});
  }
  
  deleteProductToPacketOnClick(index) {
    swal({
      dangerMode: true,
      icon: 'warning',
      title: 'Onayla',
      text: 'Ürünü paket içerisinden çıkartmak istediğinize emin misiniz?',
      buttons: {confirm: 'Tamam', cancel: 'Vazgeç'}
    }).then(value => {
      if (value) {
        let productItems = this.state.productItems;
        productItems.splice(index, 1)

        let productIds =  productItems.map((itemInfo,index) => itemInfo.id)

        this.setState({productInfo: {...this.state.productInfo, productIds: productIds}});

        swal({
          dangerMode: true,
          icon: 'success',
          title: 'Tamamlandı',
          text: 'Ürün paket içerisinden başarıyla silindi.',
          buttons: 'Tamam'
        });
      }
    });
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.saveProductAddOnClick();
    }
  }

  saveProductAddOnClick = () => {
    let sessionHash = getCookie('sessionHash');

    let infoMessage = '';
    
    if (!this.state.productInfo.name) {
      infoMessage = 'Ürün adı boş geçilemez !';
    } else if (!this.state.productInfo.description) {
      infoMessage = 'Açıklama boş geçilemez !';
    } else if (!this.state.productInfo.quantity) {
      infoMessage = 'Ürün miktarı boş geçilemez !';
    } else if (!this.state.productInfo.price) {
      infoMessage = 'Güncel fiyat bilgisi boş geçilemez !';
    } else if (this.state.productInfo.productTypeId >1 && !this.state.productInfo.productPeriod) {
      infoMessage = 'Geçerlilik süresi boş geçilemez !';
    } else if (!this.state.productInfo.productTypeId) {
      infoMessage = 'Ürün tipi boş geçilemez !';
    } else if (!this.state.productInfo.sort) {
      infoMessage = 'Sıralama boş geçilemez !';
    }
    if (infoMessage) {
      swal({
        dangerMode: true,
        icon: 'info',
        title: 'Eksik Bilgi',
        text: infoMessage,
        buttons: 'Tamam'
      });
    } else {
      this.setState({isCompleted: false});

      const requestOptions = {
        method: 'POST',
        headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
        body: JSON.stringify({name: this.state.productInfo.name, productIds: this.state.productInfo.productIds, description: this.state.productInfo.description, quantity: parseInt(this.state.productInfo.quantity), price: parseInt(this.state.productInfo.price), priceOld: parseInt(this.state.productInfo.priceOld), categoryId: this.state.productInfo.categoryId, productPeriod: this.state.productInfo.productPeriod, productTypeId: parseInt(this.state.productInfo.productTypeId), sort: parseInt(this.state.productInfo.sort)})
      };

      fetch(config.restApi + 'product', requestOptions)
      .then(response => response.json())
      .then(responseData => {
        switch (responseData.status) {
          case 200: {
            this.setState({isCompleted: true, productInfo: {name: '', description: '', quantity: '0', price: '0', priceOld: '0', categoryId: 0, productPeriod: '', productTypeId: 0, sort: '0'}});
            swal({
              dangerMode: true,
              icon: 'success',
              title: 'Tamamlandı',
              text: 'Ürün başarıyla eklendi.',
              buttons: 'Tamam'
            }).then(() => this.props.navigate('/productEdit?id=' + responseData.productId));
          }
          break;
          default: {
            this.setState({isCompleted: true});

            swal({
              dangerMode: true,
              icon: 'warning',
              title: 'Hay Aksi',
              text: responseData.message,
              buttons: 'Tamam'
            });
          }
          break;
        }
      }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
    }
  }

  render() {
    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <>
        <div className="row">
          <div className="col-12">
            <div className="ibox">
              <div className="ibox-content text-darkblue">
                <div className="d-flex justify-content-between">
                  <h4>Ürün Ekle</h4>
                  <div className="d-flex">
                    <button className="btn btn-orange text-white mr-2" onClick={this.saveProductAddOnClick} title="Kaydet"><i className="fa fa-save"></i></button>
                    <button className="btn btn-darkblue text-white" title="Geri" onClick={() => {this.props.navigate(-1)}}><i className="fa fa-reply"></i></button>
                  </div>
                </div>
                <div className="hr-line-dashed"></div>
                <div className="row">
                  <div className="col-md-6">
                    <strong>Ürün Adı<strong className="text-danger ml-1">*</strong></strong>
                    <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({productInfo: {...this.state.productInfo, name: event.target.value.trimStart()}})} onKeyDown={this.handleKeyPress} placeholder="Ürün Adı" type="text" value={this.state.productInfo.name} />
                  </div>
                  <div className="col-md-6">
                    <strong>Ürün Tipi<strong className="text-danger ml-1">*</strong></strong>
                    <select className="form-control form-control-sm form-input mb-2 mt-1" onChange={this.productTypeOnChange} onKeyDown={this.handleKeyPress} value={this.state.productInfo.productTypeId}>
                      <option value="">Seçiniz</option>
                      {this.state.productTypes.map(productTypeInfo => <option key={productTypeInfo.id} value={productTypeInfo.id}>{productTypeInfo.name}</option>)}
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <strong>Kategori Adı</strong>
                    <select className="form-control form-control-sm form-input mb-2 mt-1" disabled={this.state.productInfo.productTypeId <= 1} onChange={this.categoryOnChange} onKeyDown={this.handleKeyPress} value={this.state.productInfo.categoryId}>
                      <option value="">Seçiniz</option>
                      {this.state.categories.map(categoryInfo => <option key={categoryInfo.id} value={categoryInfo.id}>{categoryInfo.name}</option>)}
                    </select>
                  </div>
                  <div className="col-md-6">
                    <strong>Ürün Miktarı<strong className="text-danger ml-1" >*</strong></strong>
                    <input className="form-control form-input mb-2 mt-1" disabled={this.state.productInfo.productTypeId <= 1} min="0" onChange={event => this.setState({productInfo: {...this.state.productInfo, quantity: event.target.value}})} onKeyDown={this.handleKeyPress} placeholder="Ürün Miktarı" type="number" value={this.state.productInfo.quantity}/>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <strong>Güncel Fiyat<strong className="text-danger ml-1">*</strong></strong>
                    <input className="form-control form-input mb-2 mt-1" min="0" onChange={event => this.setState({productInfo: {...this.state.productInfo, price: event.target.value}})} onKeyDown={this.handleKeyPress} placeholder="Güncel Fiyat" type="number" value={this.state.productInfo.price} />
                  </div>
                  <div className="col-md-6">
                    <strong>Eski Fiyat</strong>
                    <input className="form-control form-input mb-2 mt-1" min="0" onChange={event => this.setState({productInfo: {...this.state.productInfo, priceOld: event.target.value}})} onKeyDown={this.handleKeyPress} placeholder="Eski Fiyat" type="number" value={this.state.productInfo.priceOld} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <strong>Geçerlilik Süresi<strong className="text-danger ml-1">*</strong></strong>
                    <select className="form-control form-control-sm form-input mb-2 mt-1" disabled={this.state.productInfo.productTypeId <= 1} onChange={(event) => this.setState({productInfo: {...this.state.productInfo, productPeriod: event.target.value || ''}})} onKeyDown={this.handleKeyPress} value={this.state.productInfo.productPeriod}>
                      <option value="">Seçiniz</option>
                      <option value="INTERVAL 1 DAY">1 Günlük</option>
                      <option value="INTERVAL 1 WEEK">1 Haftalık</option>
                      <option value="INTERVAL 1 MONTH">1 Aylık</option>
                      <option value="INTERVAL 2 MONTH">2 Aylık</option>
                      <option value="INTERVAL 3 MONTH">3 Aylık</option>
                      <option value="INTERVAL 6 MONTH">6 Aylık</option>
                      <option value="INTERVAL 1 YEAR">1 Yıllık</option>
                    </select>
                  </div>
                  <div className="col-md-6">
                    <strong>Sıralama<strong className="text-danger ml-1">*</strong></strong>
                    <input className="form-control form-input mb-2 mt-1" max="999" min="0" onChange={event => this.setState({productInfo: {...this.state.productInfo, sort: event.target.value}})} onKeyDown={this.handleKeyPress} placeholder="Sıralama" type="number" value={this.state.productInfo.sort}/>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <strong>Ürün Açıklaması<strong className="text-danger ml-1">*</strong></strong>
                    <textarea className="form-control input-bordercolor form-input mb-2 mt-1" onChange={event => this.setState({productInfo: {...this.state.productInfo, description: event.target.value.trimStart()}})} onKeyDown={this.handleKeyPress} placeholder="Ürün Açıklaması" value={this.state.productInfo.description}></textarea>
                  </div>
                </div>
                {
                  this.state.productInfo.productTypeId==1 && 
                  (
                    <div className="row">
                      <div className="col">
                        <div className="ibox">
                          <div className="ibox-content text-darkblue">
                            <div className="d-flex justify-content-between">
                              <h4>Paket İçerisine Ürün Ekle</h4>
                              <button className="btn btn-orange" onClick={() => this.setState({isOpenProductToPacketAddModal: true})} title="Paket İçerisine Ürün Ekle"><i className="fa fa-plus"></i></button>
                            </div>
                            <div className="hr-line-dashed"></div>
                            <div className="overflow-auto">
                              <table className="table table-striped table-bordered">
                              <thead>
                                <tr className="text-center text-darkblue">
                                  <th>Ürün Adı</th>
                                  <th>İşlem</th>
                                </tr>
                              </thead>
                              <tbody className="text-center">
                                {this.state.productItems.map((itemInfo, index) =>
                                  <tr key={index}>
                                    <td className="align-middle">{itemInfo.name}</td>
                                    <td className="align-middle">
                                      <button className="btn btn-darkblue" onClick={() => this.deleteProductToPacketOnClick(index)} title="Sil"><i className="fa fa-trash"></i></button>
                                    </td>
                                  </tr>
                                )}
                                {this.state.productItems.length == 0 ?
                                  <tr>
                                    <td className="align-middle p-3" colSpan="4">Ürün Bulunamadı !</td>
                                  </tr>
                                : null}
                              </tbody>
                              </table>
                              <ProductToPacketAddModal isOpen={this.state.isOpenProductToPacketAddModal} modalTitle="Paket İçerisine Ürün Ekle" modalDescription="Paket içerisine ürün eklemek için aşağıdaki bilgileri doldurunuz !" onRequestClose={() => this.setState({isOpenProductToPacketAddModal: false})} saveOnClick={productToPacketInfo => this.saveProductToPacketAddOnClick(productToPacketInfo)}/>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }
                <div className="d-flex justify-content-end mt-2">
                  <button className="btn btn-orange" onClick={this.saveProductAddOnClick}>Kaydet</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
  }
}

export default function ProductAdd() {
  return (
    <ProductAddModule navigate={useNavigate()} searchParams={useSearchParams()[0]} />
  )
}