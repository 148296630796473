import React from 'react';
import Modal from 'react-modal';
import {Navigate} from 'react-router-dom';
import Select from 'react-select';

import swal from 'sweetalert';
import {getCookie} from '../../../inc/cookies';
import config from '../../../inc/config';

export default class AddedCategoryAttributeModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isCompleted: true,

      attributeInfo: {
        groupId: 0,
        name: '',
        description: '',
        sort: 0
      },
      
      attributeGroups: []
    };
  }

  componentDidMount() {
    this.getAttributeGroups();
  }

/*   getAttributeGroups() {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'attributeGroups/tr', requestOptions)
    .then(response => response.json())
    .then(responseData => responseData.status === 200 ? this.setState({isLoading: false, attributeGroups: responseData.attributeGroups}) : swal({dangerMode: true, icon: 'warning', title: 'Hay Aksi', text: responseData.message, buttons: 'Tamam'}).then(() => <Navigate to={'/'} replace={true}></Navigate>), () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Yeniden Dene'}).then(() => this.getAttributeGroups()));
  } */

  getAttributeGroups() {

    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'attributeGroups/tr/', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200:
          this.setState({isLoading: false, attributeGroups: responseData.attributeGroups})
        break;
        case 401:
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          }).then(this.props.navigate(-1));
        break;
        default:
          this.setState({isLoading: false});

          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          }).then(() => this.props.navigate('/'));
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Yeniden Dene'}).then(() => this.getAttributeGroups()));
  }

  selectedCategoryGroupOnChange = (selectedCategoryGroup) => {
    this.setState({attributeInfo: {...this.state.attributeInfo, groupId: selectedCategoryGroup.value}});
  };

  saveOnClick = () => {
    let sessionHash = getCookie('sessionHash');

    let infoMessage = '';
    if(!this.state.attributeInfo.groupId) {
        infoMessage = "Grup boş geçilemez !";
      } else if(!this.state.attributeInfo.name) {
        infoMessage = "Özellik adı boş geçilemez !";
      } else if(!this.state.attributeInfo.description) {
        infoMessage = "Açıklama boş geçilemez !";
      }

    if (infoMessage) {
      swal({
        dangerMode: true,
        icon: 'info',
        title: 'Eksik Bilgi',
        text: infoMessage,
        buttons: 'Tamam'
      });
    } else {
      this.setState({isCompleted: false});

      const requestOptions = {
        method: 'POST',
        headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
        body: JSON.stringify(this.state.attributeInfo)
      };

      fetch(config.restApi + 'attribute/tr', requestOptions)
      .then(response => response.json())
      .then(responseData => {
        switch (responseData.status) {
          case 200: {
            this.setState({isCompleted: true, attributeInfo: {groupId: 0, name: '', description: '', sort: 0}});

            swal({
              dangerMode: true,
              icon: 'success',
              title: 'Tamamlandı',
              text: 'Gruba ait özellik başarıyla eklendi.',
              buttons: 'Tamam'
            }).then(() => this.props.onCompleted());
          }
          break;
          default: {
            this.setState({isCompleted: true});

            swal({
              dangerMode: true,
              icon: 'warning',
              title: 'Hay Aksi',
              text: responseData.message,
              buttons: 'Tamam'
            });
          }
          break;
        }
      }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
    }
  }

  handleKeyPress = (event) => {
    if(event.key === 'Enter') {
      this.saveOnClick();
    }
  }

  render() {

    const selectedCategoryGroupOptions = this.state.attributeGroups.map((attributeGroupInfo) => ({
      value: attributeGroupInfo.id,
      label: <><b>{attributeGroupInfo.name}</b> - <span>({attributeGroupInfo.description})</span></>,
      search: attributeGroupInfo.name + ' ' + attributeGroupInfo.description
    }));

    return (
      <div className="modal inmodal">
        <div className="modal-dialog">
          <div className="modal-content animated fadeIn">
          <Modal
            isOpen={this.props.isOpen}
            onRequestClose={this.props.onRequestClose}
            style={{
              content : {
                position : 'null',
                top : 'null',
                left : 'null',
                right : 'null',
                bottom : 'null',
                border : 'none',
                background : '#fff',
                padding : 'null',
                maxWidth : '50%',
                minWidth : '350px'
              },
              overlay : {
                position : 'fixed',
                top : 0,
                left : 0,
                right : 0,
                bottom : 0,
                display : 'flex',
                alignItems : 'center',
                justifyContent : 'center',
                backgroundColor : 'rgba(0,0,0,00.1)',
                zIndex : 2500
              }
            }}
          >
            <div className={this.state.isCompleted ? "ibox-content no-padding" : "ibox-content sk-loading no-padding"}>
              <div className="sk-spinner sk-spinner-fading-circle">
                <div className="sk-circle1 sk-circle"></div>
                <div className="sk-circle2 sk-circle"></div>
                <div className="sk-circle3 sk-circle"></div>
                <div className="sk-circle4 sk-circle"></div>
                <div className="sk-circle5 sk-circle"></div>
                <div className="sk-circle6 sk-circle"></div>
                <div className="sk-circle7 sk-circle"></div>
                <div className="sk-circle8 sk-circle"></div>
                <div className="sk-circle9 sk-circle"></div>
                <div className="sk-circle10 sk-circle"></div>
                <div className="sk-circle11 sk-circle"></div>
                <div className="sk-circle12 sk-circle"></div>
              </div>
              <div className="modal-header d-block text-center text-darkblue">
                <button className="close" onClick={this.props.onRequestClose}><span>×</span></button>
                <h4 className="modal-title">{this.props.modalTitle}</h4>
                <h5 className="font-normal">{this.props.modalDescription}</h5>
              </div>
              <div className="modal-body text-darkblue">
                <strong>Grup Adı<strong className="text-danger ml-1">*</strong></strong>
                <Select
                  className="form-input mb-2 mt-1 text-dark"
                  options={selectedCategoryGroupOptions}
                  onChange={this.selectedCategoryGroupOnChange}
                  onKeyDown={this.handleKeyPress}
                  value={selectedCategoryGroupOptions.find((selectedCategoryGroupOptionInfo) => selectedCategoryGroupOptionInfo.value === this.state.selectedCategoryGroupOptionInfo)}
                  placeholder="Grup Adı"
                  menuPortalTarget={document.body}
                  menuPosition="fixed"
                  filterOption={(option, searchText) => option.data.search.toLocaleLowerCase('tr-TR').includes(searchText.toLocaleLowerCase('tr-TR'))}
                  styles={{
                    menuPortal: (provided) => ({
                      ...provided,
                      zIndex:3000
                    }),
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 3000,
                    }),
                  }}
                  />
                <strong>Özellik Adı<strong className="text-danger ml-1">*</strong></strong>
                <input className="form-control form-input mb-2 mt-1" onChange={(event) => this.setState({attributeInfo: {...this.state.attributeInfo, name: event.target.value}})} onKeyDown={this.handleKeyPress} placeholder="Özellik Adı" type="text" value={this.state.attributeInfo.name} />
                <strong>Açıklama<strong className="text-danger ml-1">*</strong></strong>
                <input className="form-control form-input mb-2 mt-1" onChange={(event) => this.setState({attributeInfo: {...this.state.attributeInfo, description: event.target.value}})} onKeyDown={this.handleKeyPress} placeholder="Açıklama" type="text" value={this.state.attributeInfo.description} />
                <strong>Sıralama</strong>
                <input className="form-control form-input mb-2 mt-1" min="0" onChange={(event) => this.setState({attributeInfo: {...this.state.attributeInfo, sort: parseInt(event.target.value)}})} onKeyDown={this.handleKeyPress} placeholder="Sıralama" type="number" value={this.state.attributeInfo.sort}/>
              </div>
              <div className="modal-footer">
                <button className="btn btn-orange" onClick={this.saveOnClick}>Kaydet</button>
              </div>
            </div>
          </Modal>
          </div>
        </div>
      </div>
    )
  }
}
