import React from 'react';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import ReactPaginate from 'react-paginate';

import swal from 'sweetalert';
import Select from 'react-select'

import {getCookie} from '../../inc/cookies';
import config from '../../inc/config';
import {isoToDateTime} from '../../inc/helper';

class CrmMemberAppointmentReportModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      initialLoadCompleted: false,

      crmMemberAppointments: [],
      cities: [],
      towns: [],
      quantity: "1000",

      filterModifiedStartDate: '',

      pageCount: 0,
      resultCount: 0,
      currentPage: 1,
      limit: 10
    }
  }

  componentDidMount() {
    console.log('crmMemberAppointments: componentDidMount');
    this.getCrmMemberAppointmentsReport(this.state.currentPage);
    window.scrollTo(0, 0);
  }
  
  componentDidUpdate(prevProps, prevState) {
    console.log('crmMemberAppointments: componentDidUpdate');
    
    if (this.props.searchParams !== prevProps.searchParams) {
    }

    let newTabIndex = parseInt(this.props.searchParams.get('tab')) || 0;
    if (prevState.selectedTabIndex !== newTabIndex) {
      this.setState({selectedTabIndex: newTabIndex});
    }
  }
  
  componentWillUnmount() {
    console.log('crmMemberAppointments: componentWillUnmount');
  }

  filteredCrmMemberAppointmentsReport(currentPage) {
    let sessionHash = getCookie('sessionHash');

    this.setState({isLoading: true});

    const query = `limit=${this.state.limit}&page=${currentPage}&filterModifiedStartDate=${this.state.filterModifiedStartDate}`;

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    const startIndex = 0;
    const endIndex = startIndex + this.state.limit;

    fetch(config.restApi + `report/crmMemberAppointments/tr?${query}`, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          const currentCrmMemberAppointments = responseData.crmMemberAppointments.slice(startIndex, endIndex);
          this.setState({isLoading: false, crmMemberAppointments: currentCrmMemberAppointments, pageCount: responseData.pageCount, resultCount: responseData.resultCount, currentPage: currentPage});
        }
        break;
        case 401:
          this.setState({isLoading: false});

          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          }).then(this.props.navigate('/home'))
        break;
        default: {
          this.setState({isLoading: false});

          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  getCrmMemberAppointmentsReport(currentPage) {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    this.setState({isLoading: true});

    let errorMessages = [];
    let remaining = 1;
    let complete = () => {
      remaining--;
      if (remaining === 0) {
        this.setState({isLoading: false});
        if (errorMessages.length > 0) {
          swal({dangerMode: true, icon: 'warning', title: 'Hay Aksi', text: errorMessages[0], buttons: 'Tamam'}).then(() => {});
        }else{
          this.setState({isLoading: false});
        }
      }
    }

    const startIndex = 0;
    const endIndex = startIndex + this.state.limit;

    fetch(config.restApi + `report/crmMemberAppointments/tr?limit=${this.state.limit}&page=${currentPage}`, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          const currentCrmMemberAppointments = responseData.crmMemberAppointments.slice(startIndex, endIndex);
          this.setState({crmMemberAppointments: currentCrmMemberAppointments, pageCount: responseData.pageCount, resultCount: responseData.resultCount, currentPage: currentPage});
        }
        break;
        case 401:  {
          errorMessages.push(responseData.message);
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));
  }

  handlePageClick = (page) => {
    const updatedPage = page.selected + 1;
    this.setState({ currentPage: updatedPage }, () => {this.filteredCrmMemberAppointmentsReport(updatedPage);});
  }

  filterOnClick = (event) => {
    const query = `&filterModifiedStartDate=${this.state.filterModifiedStartDate}`;
    this.filteredCrmMemberAppointmentsReport(1, query)
  }

  resetOnClick = (event) => {
    this.setState({
      filterAppointmentDate: '',
      filterModifiedStartDate: '',
      currentPage: 1
    }, () => {this.setState({isLoading: true}, () => {this.filteredCrmMemberAppointmentsReport(this.state.currentPage);this.setState({isLoading: false});});});
  }

  handleKeyPress = (event, page) => {
    if (event.key === 'Enter') {
      const query = `&filterModifiedStartDate=${this.state.filterModifiedStartDate}`;
      
      this.setState({isLoading: true, currentPage: 1}, () => {
        this.filteredCrmMemberAppointmentsReport(this.state.currentPage, query);
        this.setState({isLoading: false});
      });
    }
  }

  render() {
    let sessionHash = getCookie("sessionHash");

    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <div className="row custom-container">
        <div className="col-xl-9">
          <div className="ibox">
            <div className="ibox-content text-darkblue pr-3">
              <div className="d-flex justify-content-between">
                <h4>Arama ve Ziyaret Raporu</h4>
                <div className="d-flex justify-content-end">
                  <input className="col-md-4 form-control form-input mx-2" onChange={event => this.setState({quantity: event.target.value})} placeholder="Miktar" type="number" value={this.state.quantity} />
                  <a className="btn btn-orange" href={`${config.restApi}report/crmMemberAppointments/tr?download=1&limit=${this.state.quantity}` + `&sessionHash=${sessionHash}&filterModifiedStartDate=${this.state.filterModifiedStartDate}`} target="_blank" title="İndir"><i className="fa fa-save"></i></a>
                </div>
              </div>
            </div>
            <div className="table-responsive overflow-x-auto">
              <table className="table table-striped table-bordered">
              <thead>
                <tr className="text-center text-darkblue">
                  <th>Randevu No</th>
                  <th>Satış Sorumluları</th>
                  <th>Firma Adı</th>
                  <th>Potansiyel Üye Durumu</th>
                  <th>Mesajı Yazan</th>
                  <th>Mesaj</th>
                  <th>Mesaj Tarihi ve Saati</th>
                  <th>Randevu Tarihi ve Saati</th>
                  <th>İşlem</th>
                </tr>
              </thead>
              <tbody className="text-center">
              {this.state.crmMemberAppointments.map(crmMemberAppointmentReportInfo =>
                <tr key={crmMemberAppointmentReportInfo.id}>
                  <td className="align-middle">{crmMemberAppointmentReportInfo.id}</td>
                  <td className="align-middle">{crmMemberAppointmentReportInfo.crmMemberAppointmentMarketers.map((crmMemberAppointmentMarketerInfo, index) => 
                    <div key={index}>{crmMemberAppointmentMarketerInfo.fullName}<br /></div>)}</td>
                  <td className="align-middle"><b>{crmMemberAppointmentReportInfo.companyName || '-'}</b><br /><span className="text-darkblue">Üye No: </span><span className="text-darkblue"> #{crmMemberAppointmentReportInfo.crmMemberId}</span></td>
                  <td className="align-middle">{crmMemberAppointmentReportInfo.appointmentStatus}</td>
                  <td className="align-middle">{crmMemberAppointmentReportInfo.crmMemberAppointmentHistories[0].modifiedByFullName}</td>
                  <td className="align-middle">{crmMemberAppointmentReportInfo.crmMemberAppointmentHistories[0].comment}</td>
                  <td className="align-middle">{isoToDateTime(crmMemberAppointmentReportInfo.crmMemberAppointmentHistories[0].modifiedDate)}</td>
                  <td className="align-middle">{isoToDateTime(crmMemberAppointmentReportInfo.appointmentDate)}</td>
                  <td className="align-middle"><Link className="btn btn-orange text-center" to={"/crmMemberAppointmentEdit?id=" + crmMemberAppointmentReportInfo.id}><i className="fa fa-pencil text-white action-icon"></i></Link></td>
                </tr>
              )}
              {this.state.crmMemberAppointments.length === 0 ?
                <tr>
                  <td className="align-middle p-3" colSpan="9">Ziyaretçi Bulunamadı !</td>
                </tr>
              : null}
              </tbody>
              </table>
              {
                this.state.crmMemberAppointments.length !== 0 &&
                <div className="d-flex justify-content-sm-end justify-content-start pl-1 pl-sm-0">
                  <ReactPaginate
                  previousLabel={"Geri"}
                  nextLabel={"İleri"}
                  breakLabel={"..."}  
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={1}
                  onPageChange={page => this.handlePageClick(page)}
                  pageRangeDisplayed={10}
                  containerClassName={'pagination'}
                  activeClassName={'activePage'}
                  forcePage={this.state.currentPage - 1}
                  />
                </div>
                }
            </div>
          </div>
        </div>
        <div className="col-xl-3 order-first order-xl-last d-xl-none" id="accordion">
          <div className="ibox">
            <div className="ibox-content text-darkblue p-0">
              <h4 className="collapse-title">
                <a className="btn btn-link text-darkblue font-bold d-flex justify-content-between" data-toggle="collapse" href="#filterCollapse" title="Filtrele">Filtrele</a>
              </h4>
            </div>
            <div className="collapse" data-parent="#accordion" id="filterCollapse">
              <div className="ibox-content text-darkblue">
                <strong>Mesaj Tarihi ve Saati</strong>
                <input className="form-control form-input" onChange={event => this.setState({filterModifiedStartDate: event.target.value})} onKeyDown={this.handleKeyPress} type="date" value={this.state.filterModifiedStartDate} />
                <div className="d-flex justify-content-end">
                  <button className="btn btn-orange mt-3 mr-2" onClick={this.resetOnClick} type="button"><i className="fa fa-refresh"></i> Sıfırla</button>
                  <button className="btn btn-orange mt-3" onClick={this.filterOnClick} type="button"><i className="fa fa-filter"></i> Filtrele</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 order-first order-xl-last d-none d-xl-block">
          <div className="ibox">
            <div className="ibox-title text-darkblue">
              <h5><i className="fa fa-filter"></i> Filtrele</h5>
            </div>
            <div className="ibox-content text-darkblue">
              <strong>Mesaj Tarihi ve Saati</strong>
              <input className="form-control form-input mb-2" onChange={event => this.setState({filterModifiedStartDate: event.target.value})} onKeyDown={this.handleKeyPress} type="date" value={this.state.filterModifiedStartDate} />
              <div className="d-flex justify-content-end">
                <button className="btn btn-orange mt-3 mr-2" onClick={this.resetOnClick}><i className="fa fa-refresh"></i> Sıfırla</button>
                <button className="btn btn-orange mt-3" onClick={this.filterOnClick}><i className="fa fa-filter"></i> Filtrele</button>
              </div>
            </div>
          </div>
        </div>
      </div>
  }
}

export default function CrmMemberAppointmentsReport() {
  return (
    <CrmMemberAppointmentReportModule navigate={useNavigate()} searchParams={useSearchParams()[0]} />
  )
}