import React from 'react';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import ReactPaginate from 'react-paginate';

import swal from 'sweetalert';
import Select from 'react-select'

import {getCookie} from '../../inc/cookies';
import config from '../../inc/config';
import {isoToDateTime} from '../../inc/helper';

class ActiveMemberModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      initialLoadCompleted: false,

      activeMembers: [],
      quantity: "1000",
      
      pageCount: 0,
      resultCount: 0,
      currentPage: 1,
      limit: 10
    }
  }

  componentDidMount() {
    console.log('activeMembers: componentDidMount');
    
    this.getActiveMembers(this.state.currentPage);
    
    window.scrollTo(0, 0);
  }
  
  componentDidUpdate(prevProps, prevState) {
    console.log('activeMembers: componentDidUpdate');

    let newTabIndex = parseInt(this.props.searchParams.get('tab')) || 0;
    if (prevState.selectedTabIndex !== newTabIndex) {
      this.setState({selectedTabIndex: newTabIndex});
    }
  }
  
  componentWillUnmount() {
    console.log('activeMembers: componentWillUnmount');
  }

  getActiveMembers(currentPage) {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    this.setState({isLoading: true});

    let errorMessages = [];
    let remaining = 1;
    let complete = () => {
      remaining--;
      if (remaining === 0) {
        this.setState({isLoading: false});
        if (errorMessages.length > 0) {
          swal({dangerMode: true, icon: 'warning', title: 'Hay Aksi', text: errorMessages[0], buttons: 'Tamam'}).then(this.props.navigate('/home'));
        }else{
          this.setState({isLoading: false});
        }
      }
    }

    const startIndex = 0;
    const endIndex = startIndex + this.state.limit;

    fetch(config.restApi + `report/activeMembers/tr?limit=${this.state.limit}&page=${currentPage}`, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          const currentActiveMembers = responseData.members.slice(startIndex, endIndex);
          this.setState({activeMembers: currentActiveMembers, pageCount: responseData.pageCount, resultCount: responseData.resultCount, currentPage: currentPage});
        }
        break;
        case 401:  {
          errorMessages.push(responseData.message);
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));
  }

  handlePageClick = (page) => {
    const updatedPage = page.selected + 1;
    this.setState({ currentPage: updatedPage }, () => {this.getActiveMembers(updatedPage);});
  }

  getPhoneNumber(memberInfo) {
    let activeMemberPhones = memberInfo.memberPhones.filter(item => item.active == 1);

    if(activeMemberPhones.length > 0) {
      let activeMemberPhoneNumber = activeMemberPhones[0].phoneNumber.match(/(\d{3})(\d{3})(\d{2})(\d{2})/);
      let phoneMasking = `(${activeMemberPhoneNumber[1]}) ${activeMemberPhoneNumber[2]} ${activeMemberPhoneNumber[3]} ${activeMemberPhoneNumber[4]}` 
      return phoneMasking;
    }
    
    return '-'
  }

  render() {
    let sessionHash = getCookie("sessionHash");

    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <div className="row custom-container">
        <div className="col-12">
          <div className="ibox">
            <div className="ibox-content text-darkblue">
              <div className="d-flex justify-content-between">
                <h4>Aktif Üye Raporları</h4>
                <div className="d-flex justify-content-end">
                  <input className="col-md-4 form-control form-input mx-2" onChange={event => this.setState({quantity: event.target.value})} placeholder="Miktar" type="number" value={this.state.quantity} />
                  <a className="btn btn-orange" href={config.restApi + `report/activeMembers/tr?download=1&limit=${this.state.quantity}` + `&sessionHash=${sessionHash}`} target="_blank" title="İndir"><i className="fa fa-save"></i></a>
                </div>
              </div>
            </div>
            <div className="table-responsive overflow-x-auto">
              <table className="table table-striped table-bordered">
              <thead>
                <tr className="text-center text-darkblue">
                  <th>Adı Soyadı</th>
                  <th>Firma Adı</th>
                  <th>Telefon</th>
                  <th>Bölge</th>
                  <th>Paketler</th>
                  <th>Ürünler</th>
                  <th>Başlangıç Tarihi</th>
                  <th>Bitiş Tarihi</th>
                  <th>İşlem</th>
                </tr>
              </thead>
              <tbody className="text-center">
              {this.state.activeMembers.map(activeMemberInfo =>
                <tr key={activeMemberInfo.id}>
                  <td className="align-middle">{activeMemberInfo.firstname} {activeMemberInfo.lastname}{activeMemberInfo.identityVerified === 1 ? <i className="fa fa-check text-green ml-1"></i> : "" }<br /><span className="text-darkblue">Üye No: </span><span className="text-darkblue"> # {activeMemberInfo.id}</span></td>
                  <td className="align-middle">{activeMemberInfo.storeName || '-'} <br/></td>
                  <td className="align-middle">{this.getPhoneNumber(activeMemberInfo)}</td> 
                  <td className="align-middle">{activeMemberInfo.memberAddresses.map(memberAddressesInfo => `${memberAddressesInfo.cityName} / ${memberAddressesInfo.townName}`) || '-'}</td>
                  <td className="align-middle">{activeMemberInfo.transactionProducts}</td>
                  <td className="align-middle">{activeMemberInfo.productNames}</td>
                  <td className="align-middle">{isoToDateTime(activeMemberInfo.periodStart)}</td>
                  <td className="align-middle">{isoToDateTime(activeMemberInfo.periodEnd)}</td>
                  <td className="align-middle"><Link className="btn btn-orange text-center" to={"/memberEdit?id=" + activeMemberInfo.id}><i className="fa fa-pencil text-white action-icon"></i></Link></td>
                </tr>
              )}
              {this.state.activeMembers.length === 0 ?
                <tr>
                  <td className="align-middle p-3" colSpan="10">Aktif Üye Bulunamadı !</td>
                </tr>
              : null}
              </tbody>
              </table>
              {
                this.state.activeMembers.length !== 0 &&
                <div className="d-flex justify-content-sm-end justify-content-start pl-1 pl-sm-0">
                  <ReactPaginate
                  previousLabel={"Geri"}
                  nextLabel={"İleri"}
                  breakLabel={"..."}  
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={1}
                  onPageChange={page => this.handlePageClick(page)}
                  pageRangeDisplayed={10}
                  containerClassName={'pagination'}
                  activeClassName={'activePage'}
                  forcePage={this.state.currentPage - 1}
                  />
                </div>
                }
            </div>
          </div>
        </div>
      </div>
  }
}

export default function ActiveMembers() {
  return (
    <ActiveMemberModule navigate={useNavigate()} searchParams={useSearchParams()[0]} />
  )
}