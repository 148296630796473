import React from 'react';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import ReactPaginate from 'react-paginate';

import MemberWorkExpenseAddModal from '../../../component/accounting/MemberWorkExpenseAddModal';

import {getCookie} from '../../../inc/cookies';
import config from '../../../inc/config';
import {isoToDate, priceFormat} from '../../../inc/helper';

class MemberWorkExpenseDetailModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isOpenMemberWorkExpenseAddModal: false,

      member: {},
      memberWorkExpenses: [],

      pageCount: 0,
      resultCount: 0,
      currentPage: 1,
      limit: 10
    };

    this.onCompleted = this.onCompleted.bind(this);
  }

  componentDidMount() {
    this.getMemberWorkExpenses(this.state.currentPage);
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
  }

  getMemberWorkExpenses(currentPage) {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    let errorMessages = [];
    let remaining = 2;
    let complete = () => {
      remaining--;
      if (remaining === 0) {
        this.setState({isLoading: false});
      }
    }

    const startIndex = 0;
    const endIndex = startIndex + this.state.limit;

    fetch(config.restApi + 'member/tr/' + this.props.searchParams.get('id'), requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({isLoading: false, member: responseData.member});
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));

    fetch(config.restApi + `memberWorkExpenses/tr?filterMemberId=${this.props.searchParams.get('id')}&limit=${this.state.limit}&page=${currentPage}`, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          const currentMemberWorkExpenses = responseData.memberWorkExpenses.slice(startIndex, endIndex);
          this.setState({memberWorkExpenses: currentMemberWorkExpenses, pageCount: responseData.pageCount, resultCount: responseData.resultCount, currentPage: currentPage});
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));
  }

  handlePageClick(page) {
    this.setState({currentPage: page.selected + 1}, () => this.getMemberWorkExpenses(this.state.currentPage))
  }

  onCompleted() {
    this.getMemberWorkExpenses();
    this.setState({isOpenMemberWorkExpenseAddModal: false});
  }

  render() {
    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <>
        <div className="row">
          <div className="col">
            <div className="ibox">
              <div className="ibox-title text-darkblue d-flex justify-content-between pr-2">
                <h4 className="text-darkblue">{this.state.member.firstname} {this.state.member.firstname} Çalışanına Ait Masraf Listesi</h4>
                <div className="d-flex ibox justify-content-end">
                  <button className="btn btn-orange mr-2" onClick={() => this.setState({isOpenMemberWorkExpenseAddModal: true})} title="Ekle"><i className="fa fa-plus"></i></button>
                  <button className="btn btn-darkblue text-white" title="Geri" onClick={() => {this.props.navigate(-1)}}><i className="fa fa-reply"></i></button>
                </div>
              </div>
              <div className="table-responsive overflow-x-auto">
                <table className="table table-striped table-bordered">
                  <thead>
                    <tr className="text-center text-darkblue">
                      <th>Masraf Adı</th>
                      <th>Açıklama</th>
                      <th>Tutar</th>
                      <th>Muhasebe Notu</th>
                      <th>Onay Durumu</th>
                      <th>Harcama Tarihi</th>
                      <th>Durum</th>
                      <th>İşlem</th>
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {this.state.memberWorkExpenses.map((memberWorkExpenseInfo) =>
                      <tr key={memberWorkExpenseInfo.id}>
                        <td className="align-middle">{memberWorkExpenseInfo.name}</td>
                        <td className="align-middle">{memberWorkExpenseInfo.description}</td>
                        <td className="align-middle">{priceFormat(memberWorkExpenseInfo.price)}</td>
                        <td className="align-middle">{memberWorkExpenseInfo.expenseResult ? memberWorkExpenseInfo.expenseResult : "-"}</td>
                        <td className="align-middle">{memberWorkExpenseInfo.locked ? <i className="fa fa-check pointer text-green font-bold" title="Onaylandı"></i> : <i className="fa fa-reply pointer text-orange font-bold" title="Onaylanmadı"></i>}</td>
                        <td className="align-middle">{memberWorkExpenseInfo.expenseDate ? isoToDate(memberWorkExpenseInfo.expenseDate) : "-"}</td>
                        <td className="align-middle">{memberWorkExpenseInfo.active === 1 ? 'Açık' : 'Kapalı'}</td>
                        <td className="align-middle">
                          <Link className="btn btn-orange text-center mb-2" to={"/memberWorkExpenseEdit?id=" + memberWorkExpenseInfo.id}><i className="fa fa-pencil text-white action-icon"></i></Link>
                        </td>
                      </tr>
                    )}
                    {this.state.memberWorkExpenses.length === 0 ?
                      <tr>
                        <td className="align-middle p-3" colSpan="8">Çalışana Ait Masraf Bulunamadı !</td>
                      </tr>
                    : null}
                  </tbody>
                </table>
                {
                  this.state.memberWorkExpenses.length !== 0 &&
                  <ReactPaginate
                  previousLabel={"Geri"}
                  nextLabel={"İleri"}
                  breakLabel={"..."}  
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={1}
                  onPageChange={page => this.handlePageClick(page)}
                  pageRangeDisplayed={2}
                  containerClassName={'pagination'}
                  activeClassName={'activePage'}
                  />
                }
              </div>
            </div>
          </div>
        </div>
        <MemberWorkExpenseAddModal isOpen={this.state.isOpenMemberWorkExpenseAddModal} memberId={this.props.searchParams.get('id')} modalDescription="Çalışana ait masraflar için aşağıdaki alanları doldurunuz !" modalTitle="Masraf Ekle" onCompleted={this.onCompleted} onRequestClose={() => this.setState({isOpenMemberWorkExpenseAddModal: false})} />
      </>
}}

export default function MemberWorkExpenses() {
  return (
    <MemberWorkExpenseDetailModule navigate={useNavigate()} searchParams={useSearchParams()[0]} />
  )
}