import React from 'react';
import Modal from 'react-modal';
import swal from 'sweetalert';

import {getCookie} from '../inc/cookies';
import config from '../inc/config';

export default class AddressModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,

      addressInfo: {
        id: this.props.defaultValue && this.props.defaultValue.id || Date.now(),
        countryId: this.props.defaultValue && this.props.defaultValue.countryId || 1,
        countryName: this.props.defaultValue && this.props.defaultValue.countryName || 'Türkiye',
        cityId: this.props.defaultValue && this.props.defaultValue.cityId || '',
        cityName: this.props.defaultValue && this.props.defaultValue.cityName || '',
        townId: this.props.defaultValue && this.props.defaultValue.townId || '',
        townName: this.props.defaultValue && this.props.defaultValue.townName || '',
        districtId: this.props.defaultValue && this.props.defaultValue.districtId || '',
        districtName: this.props.defaultValue && this.props.defaultValue.districtName || '',
        quarterId: this.props.defaultValue && this.props.defaultValue.quarterId || '',
        quarterName: this.props.defaultValue && this.props.defaultValue.quarterName || '',
        address: this.props.defaultValue && this.props.defaultValue.address || '',
        description: this.props.defaultValue && this.props.defaultValue.description || '',
        active: this.props.defaultValue && this.props.defaultValue.active || 1
      },

      countries: [],
      cities: [],
      towns: [],
      regions: [],
      quarters: []
    };

    this.countryOnChange = this.countryOnChange.bind(this);
    this.cityOnChange = this.cityOnChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.townOnChange = this.townOnChange.bind(this);
    this.districtOnChange = this.districtOnChange.bind(this);
    this.quarterOnChange = this.quarterOnChange.bind(this);
  }

  componentDidMount() {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    let errorMessages = [];
    let remaining = 1;

    if (this.state.addressInfo.countryId) remaining++;
    if (this.state.addressInfo.cityId) remaining++;
    if (this.state.addressInfo.townId) remaining++;

    let complete = () => {
      remaining--;
      if (remaining == 0) {
        this.setState({isLoading: false});
      }
    }

    fetch(config.restApi + 'countries/tr?filterActive=1', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({countries: responseData.countries});
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete(); 
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));

    if (this.state.addressInfo.countryId) {
      fetch(config.restApi + 'cities/tr/' + this.state.addressInfo.countryId + '?filterActive=1', requestOptions)
      .then(response => response.json())
      .then(responseData => {
        switch(responseData.status) {
          case 200: {
            const sortedCities = responseData.cities.sort((first, last) => first.name.localeCompare(last.name));
            this.setState({cities: sortedCities});
          }
          break;
          default: {
            errorMessages.push(responseData.message);
          }
          break;
        }
        complete();
      }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));
    }

    if (this.state.addressInfo.cityId) {
      fetch(config.restApi + 'towns/tr/' + this.state.addressInfo.cityId + '?filterActive=1', requestOptions)
      .then(response => response.json())
      .then(responseData => {
        switch(responseData.status) {
          case 200: {
            this.setState({towns: responseData.towns});
          }
          break;
          default: {
            errorMessages.push(responseData.message);
          }
          break;
        }
        complete();
      }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));
    }

    if (this.state.addressInfo.townId) {
      fetch(config.restApi + 'regions/tr/' + this.state.addressInfo.townId + '?filterActive=1', requestOptions)
      .then(response => response.json())
      .then(responseData => {
        switch(responseData.status) {
          case 200: {
            this.setState({regions: responseData.regions, quarters: responseData.regions.filter(districtInfo => districtInfo.id === this.state.addressInfo.districtId)[0].quarters});
          }
          break;
          default: {
            errorMessages.push(responseData.message);
          }
          break;
        }
        complete();
      }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));
    }
  }

  countryOnChange(event) {
    let countryId = event.target.value;
    if (countryId) {
      this.getCities(countryId);
      this.setState({addressInfo: {...this.state.addressInfo, countryId: parseInt(countryId), countryName: event.target.options[event.target.selectedIndex].text, cityId: '', cityName: '', townId: '', townName: '', districtId: '', districtName: '', quarterId: '', quarterName: ''}, cities: [], towns: [], regions: [], quarters: []});
    } else {
      this.setState({addressInfo: {...this.state.addressInfo, countryId: '', countryName: '', cityId: '', cityName: '', townId: '', townName: '', districtId: '', districtName: '', quarterId: '', quarterName: ''}, cities: [], towns: [], regions: [], quarters: []});
    }
  }

  cityOnChange(event) {
    let cityId = event.target.value;
    if (cityId) {
      this.getTowns(cityId);
      this.setState({addressInfo: {...this.state.addressInfo, cityId: parseInt(cityId), cityName: event.target.options[event.target.selectedIndex].text, townId: '', townName: '', districtId: '', districtName: '', quarterId: '', quarterName: ''}, towns: [], regions: [], quarters: []});
    } else {
      this.setState({addressInfo: {...this.state.addressInfo, cityId: '', cityName: '', townId: '', townName: '', districtId: '', districtName: '', quarterId: '', quarterName: ''}, towns: [], regions: [], quarters: []});
    }
  }

  townOnChange(event) {
    let townId = event.target.value;
    if (townId) {
      this.getRegions(townId);
      this.setState({addressInfo: {...this.state.addressInfo, townId: parseInt(townId), townName: event.target.options[event.target.selectedIndex].text, districtId: '', districtName: '', quarterId: '', quarterName: ''}, regions: [], quarters: []});
    } else {
      this.setState({addressInfo: {...this.state.addressInfo, townId: '', townName: '', districtId: '', districtName: '', quarterId: '', quarterName: ''}, regions: [], quarters: []});
    }
  }

  districtOnChange(event) {
    let districtId = event.target.value;
    if (districtId) {
      this.setState({addressInfo: {...this.state.addressInfo, districtId: parseInt(districtId), districtName: event.target.options[event.target.selectedIndex].text, quarterId: '', quarterName: ''}, quarters: this.state.regions.filter(districtInfo => districtInfo.id == parseInt(districtId))[0].quarters});
    } else {
      this.setState({addressInfo: {...this.state.addressInfo, districtId: '', districtName: '', quarterId: '', quarterName: ''}, quarters: []});
    }
  }

  quarterOnChange(event) {
    let quarterId = event.target.value;
    if (quarterId) {
      this.setState({addressInfo: {...this.state.addressInfo, quarterId: parseInt(quarterId), quarterName: event.target.options[event.target.selectedIndex].text}});
    } else {
      this.setState({addressInfo: {...this.state.addressInfo, quarterId: '', quarterName: ''}});
    }
  }

  getCities(countryId) {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'cities/tr/' + countryId + '?filterActive=1', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({cities: responseData.cities, towns: [], regions: []});
        }
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  getTowns(cityId) {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'towns/tr/' + cityId + '?filterActive=1', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({towns: responseData.towns, regions: []});
        }
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  getRegions(townId) {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'regions/tr/' + townId + '?filterActive=1', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({regions: responseData.regions});
        }
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  saveOnClick() {
    let infoMessage = '';
    if (!this.state.addressInfo.countryId) {
      infoMessage = 'Ülke boş geçilemez !';
    } else if (!this.state.addressInfo.cityId) {
      infoMessage = 'İl boş geçilemez !';
    } else if (!this.state.addressInfo.townId) {
      infoMessage = 'İlçe boş geçilemez !';
    } else if (!this.state.addressInfo.districtId) {
      infoMessage = 'Semt boş geçilemez !';
    } else if (!this.state.addressInfo.quarterId) {
      infoMessage = 'Mahalle boş geçilemez !';
    } else if (!this.state.addressInfo.address) {
      infoMessage = 'Adres boş geçilemez !';
    } else if (!this.state.addressInfo.description) {
      infoMessage = 'Açıklama boş geçilemez !';
    }

    if (infoMessage) {
      swal({
        dangerMode: true,
        icon: 'info',
        title: 'Eksik Bilgi',
        text: infoMessage,
        buttons: 'Tamam'
      });
    } else {
      this.props.saveOnClick(this.state.addressInfo);
      this.setState({addressInfo: {id: Date.now(), countryId: 1, countryName: 'Türkiye', cityId: '', cityName: '', townId: '', townName: '', districtId: '', districtName: '', quarterId: '', quarterName: '', address: '', description: '', active: 1}, towns: [], regions: [], quarters: []});
    }
  }

    handleKeyPress(event) {
    if (event.key === 'Enter') {
      this.saveOnClick();
      event.preventDefault();
    }
  }

  render() {
    return (
      <div className="modal inmodal">
        <div className="modal-dialog">
          <div className="modal-content animated fadeIn">
          <Modal
            isOpen={this.props.isOpen}
            onRequestClose={this.props.onRequestClose}
            style={{
              content : {
                position : 'null',
                top : 'null',
                left : 'null',
                right : 'null',
                bottom : 'null',
                border : 'none',
                background : '#fff',
                padding : 'null',
                maxWidth : '50%',
                minWidth : '350px'
              },
              overlay : {
                position : 'fixed',
                top : 0,
                left : 0,
                right : 0,
                bottom : 0,
                display : 'flex',
                alignItems : 'center',
                justifyContent : 'center',
                backgroundColor : 'rgba(0,0,0,00.1)',
                zIndex:2500
              }
            }}
          >
            {this.state.isLoading ?
              <div className="ibox-content sk-loading position-static">
                <div className="sk-spinner sk-spinner-fading-circle">
                  <div className="sk-circle1 sk-circle"></div>
                  <div className="sk-circle2 sk-circle"></div>
                  <div className="sk-circle3 sk-circle"></div>
                  <div className="sk-circle4 sk-circle"></div>
                  <div className="sk-circle5 sk-circle"></div>
                  <div className="sk-circle6 sk-circle"></div>
                  <div className="sk-circle7 sk-circle"></div>
                  <div className="sk-circle8 sk-circle"></div>
                  <div className="sk-circle9 sk-circle"></div>
                  <div className="sk-circle10 sk-circle"></div>
                  <div className="sk-circle11 sk-circle"></div>
                  <div className="sk-circle12 sk-circle"></div>
                </div>
              </div>
            :
              <>
                <div className="modal-header d-block text-center text-darkblue">
                  <button className="close" onClick={this.props.onRequestClose}><span>×</span></button>
                  <h4 className="modal-title">{this.props.modalTitle}</h4>
                  <h5 className="font-normal">{this.props.modalDescription}</h5>
                </div>
                <div className="modal-body text-darkblue">
                  <strong>Ülke<strong className="text-danger ml-1">*</strong></strong>
                  <select className="form-control form-control-sm form-input mb-2 mt-1" disabled={true} onChange={this.countryOnChange} onKeyDown={this.handleKeyPress} value={this.state.addressInfo.countryId}>
                    <option value="">Seçiniz</option>
                    {this.state.countries.map(countryInfo => <option key={countryInfo.id} value={countryInfo.id}>{countryInfo.name}</option>)}
                  </select>
                  <strong>il<strong className="text-danger ml-1">*</strong></strong>
                  <select className="form-control form-control-sm form-input mb-2 mt-1" onChange={this.cityOnChange} onKeyDown={this.handleKeyPress} value={this.state.addressInfo.cityId}>
                    <option value="">Seçiniz</option>
                    {this.state.cities.map(cityInfo => <option key={cityInfo.id} value={cityInfo.id}>{cityInfo.name}</option>)}
                  </select>
                  <strong>İlçe<strong className="text-danger ml-1">*</strong></strong>
                  <select className="form-control form-control-sm form-input mb-2 mt-1" onChange={this.townOnChange} onKeyDown={this.handleKeyPress} value={this.state.addressInfo.townId}>
                    <option value="">Seçiniz</option>
                    {this.state.towns.map(townInfo => <option key={townInfo.id} value={townInfo.id}>{townInfo.name}</option>)}
                  </select>
                  <strong>Semt<strong className="text-danger ml-1">*</strong></strong>
                  <select className="form-control form-control-sm form-input mb-2 mt-1" onChange={this.districtOnChange} onKeyDown={this.handleKeyPress} value={this.state.addressInfo.districtId}>
                    <option value="">Seçiniz</option>
                    {this.state.regions.map(regionInfo => <option key={regionInfo.id} value={regionInfo.id}>{regionInfo.name}</option>)}
                  </select>
                  <strong>Mahalle<strong className="text-danger ml-1">*</strong></strong>
                  <select className="form-control form-control-sm form-input mb-2 mt-1" onChange={this.quarterOnChange} onKeyDown={this.handleKeyPress} value={this.state.addressInfo.quarterId}>
                    <option value="">Seçiniz</option>
                    {this.state.quarters.map(quarterInfo => <option key={quarterInfo.id} value={quarterInfo.id}>{quarterInfo.name}</option>)}
                  </select>
                  <strong>Adres<strong className="text-danger ml-1">*</strong></strong>
                  <textarea className="form-control form-input mb-2 mt-1" onChange={event => this.setState({addressInfo: {...this.state.addressInfo, address: event.target.value.trimStart()}})} onKeyDown={this.handleKeyPress}  placeholder="Adres" type="text" value={this.state.addressInfo.address} />
                  <strong>Açıklama<strong className="text-danger ml-1">*</strong></strong>
                  <input className="form-control input-bordercolor form-input mb-2 mt-1" onChange={event => this.setState({addressInfo: {...this.state.addressInfo, description: event.target.value.trimStart()}})} onKeyDown={this.handleKeyPress}  placeholder="Açıklama giriniz" value={this.state.addressInfo.description} />
                </div>
                <div className="modal-footer">
                  <button className="btn btn-orange" onClick={() => this.saveOnClick()}>Kaydet</button>
                </div>
              </>
            }
          </Modal>
          </div>
        </div>
      </div>
    )
  }
}